import { MatInput } from '@angular/material/input';
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from '../../../../../common/dialogs/general-dialog/general-dialog.component';
import { UsuariosService } from '../../../../../../services/usuarios.service';
import { PaquetesService } from '../../../../../../services/paquetes.service';
import { EntregasService } from '../../../../../../services/entregas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectPackagesComponent } from '../../../../../common/dialogs/selectPackages/select-packages.component';
import { GeneralInputComponent } from '../../../../../common/dialogs/general-input/general-input.component';
import { AuthService } from '../../../../../../services/auth.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { DatePipe } from '@angular/common';
import { ConfirmarComponent } from '../../../../../common/dialogs/confirmar/confirmar.component';
import { jsPDF } from 'jspdf';
import 'jspdf-barcode';
import { Sort } from '@angular/material/sort';
import { StateTypeDict } from '../../../../../../core/data/stateTypes';

export interface PackageElement {
  id: number;
  cuenta: string;
  guia: string;
  nombre: string;
  entregado: any;
  entrega_observacion: string;
  tracking: string;
  escaneado_entrega: any;
  estado_actual: number;
  usuarioid: number;
  entrega_observacion_conductor: string;
  entregaid: number;
}

export interface Conductores {
  id: number;
  nombre: string;
}

@Component({
  selector: 'app-entrega',
  templateUrl: './entrega.component.html',
  styleUrls: ['./entrega.component.scss']
})

export class EntregaComponent implements OnInit {
  idEntrega = 0;

  displayedColumns: string[] = ['select', 'cuenta', 'guia', 'tracking', 'cliente'];

  driverOptions: Conductores[];
  filteredDriverOptions: Observable<Conductores[]>;

  dataSource = new MatTableDataSource<PackageElement>();
  selection = new SelectionModel<PackageElement>(true, []);

  conductor: { fecha: string; nombre: string };

  form;

  editMode = false;

  entregados = 0;
  escaneados = 0;
  packageTotal = 0;

  minDate = new Date().toDateString();

  constructor(
    private spinnerService: NgxSpinnerService,
    private usersService: UsuariosService,
    private packagesService: PaquetesService,
    private deliverService: EntregasService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private datePipe: DatePipe,
    private navigation: NavigationService,
    private paqueteService: PaquetesService,
    private router: Router
  ) { }


  ngOnInit() {

    this.packageTotal = 0;
    this.selection.clear();

    this.form = this.fb.group({
      conductor: ['', [Validators.required]],
      fecha: [new Date(), [Validators.required]],
      paquetes: ['']
    });

    // Solicita lista de usuarios conductores
    this.usersService.get_driver_users().subscribe((r: any) => {
      const data: Conductores[] = [];
      r.forEach(element => {
        data.push({ id: element.id, nombre: element.nombre });
      });

      this.driverOptions = data;
      this.filteredDriverOptions = this.form.controls['conductor'].valueChanges.pipe(
        startWith(''),
        map(driver => this._filter(driver, this.driverOptions)),
      );

      this.activatedRoute.queryParams.subscribe(params => {
        if (params.pack) {
          this.editMode = true;
          this.idEntrega = params.pack;
          this.getPackages(this.idEntrega);
        } else {
          this.getSelectedPackages();
        }

        if (params.conductor) {
          const conductor_detalle = this.driverOptions.filter(e => Number(e.id) === Number(params.conductor))[0];
          this.form.get('conductor').setValue({ id: conductor_detalle.id, nombre: conductor_detalle.nombre });
        }

      });
    });

  }

  disabledDates = (d: Date): boolean => {
    const time = d.getTime();
    const day = d.getDay();

    return day !== 0;
  };

  playAudioWrong() {
    const audio = new Audio();
    audio.src = '../../../assets/wrong.wav';
    audio.load();
    audio.play();
  }

  playAudioCorrect() {
    const audio = new Audio();
    audio.src = '../../../assets/correct.wav';
    audio.load();
    audio.play();
  }

  public displayUserName(value) {
    if (!value) {
 return '';
}
    return value.nombre;
  }

  public getSelectedPackages() {
    this.displayedColumns = ['select', 'cuenta', 'guia', 'tracking', 'observaciones'];
    this.entregados = 0;
    this.spinnerService.show();

    const data: PackageElement[] = [];

    this.deliverService.selectedPackages.forEach(element => {
      //console.log(element);
      data.push({
        id: element.id,
        cuenta: element.cuenta,
        guia: element.guia,
        nombre: element.nombre,
        entregado: false,
        entrega_observacion: element.entrega.entrega_observacion,
        tracking: element.tracking,
        escaneado_entrega: false,
        estado_actual: element.estado_actual ? element.estado_actual : element.idestado,
        usuarioid: element.usuarioid,
        entrega_observacion_conductor: element.entrega.entrega_observacion_conductor,
        entregaid: element.entrega.id
      });
    });

    const hash = {};
    this.dataSource.data = data.filter(o => hash[o.id] ? false : hash[o.id] = true);
    this.packageTotal = this.dataSource.data.length;

    this.spinnerService.hide();

    //console.log('Paquetes Seleccionados', this.dataSource.data);
  }

  public getPackages(id) {
    this.displayedColumns = ['select', 'cuenta', 'guia', 'tracking', 'escaneado', 'entregado', 'observaciones'];
    this.entregados = 0;
    this.spinnerService.show();

    this.deliverService.getEntregas(id).subscribe((r: any) => {

      const d = new Date(r.fecha);
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

      this.conductor = {
        nombre: r.conductor_detalle.nombre,
        fecha: r.fecha,
      };

      this.form.get('conductor').setValue({ id: r.conductor_detalle.id, nombre: r.conductor_detalle.nombre });
      this.form.get('fecha').setValue(d);

      const data: PackageElement[] = [];
      r.paquetes.forEach(element => {

        data.push({
          id: element.id,
          cuenta: this.usersService.get_fullaccount(element.usuario),
          guia: element.numero_de_guia,
          nombre: this.usersService.get_fullname(element.usuario),
          entregado: element.entrega.entregado,
          entrega_observacion: element.entrega.entrega_observacion,
          entrega_observacion_conductor: element.entrega.entrega_observacion_conductor,
          tracking: element.tracking,
          escaneado_entrega: element.entrega.escaneado_entrega,
          estado_actual: element.estado_actual.id,
          usuarioid: element.usuario.id,
          entregaid: element.entrega.id
        });

        if (element.entrega.entregado === true) {
          this.entregados += 1;
        }

        if (element.entrega.escaneado_entrega === true) {
          this.escaneados += 1;
        }
      });


      if (this.deliverService.selectedPackages.length > 0) {
        this.deliverService.selectedPackages.forEach(element => {
          element.entrega.entregado = false;
          element.entrega.escaneado_entrega = false;
          element.entrega.entrega_observacion = null;
          element.estado_actual = null;
          data.push(element);
        });
        this.deliverService.selectedPackages = [];
      }

      const hash = {};
      this.dataSource.data = data.filter(o => hash[o.id] ? false : hash[o.id] = true);
      this.packageTotal = this.dataSource.data.length;
      this.spinnerService.hide();
    }
    );
  }

  public getErrorMessage(control) {

    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    return 'Error';
  }

  public setPackages() {
    const items: any = [];
    this.dataSource.data.forEach((element: any) => {
      items.push({
        id: element.id,
        entregado: element.entregado,
        entrega_asignado: true,
        escaneado_entrega: element.escaneado_entrega ? true : false,
        entrega_observacion: element.entrega_observacion,
        estado_actual: element.estado_actual
      });
    });

    this.form.controls['paquetes'].setValue(items);
  }

  public buildData() {
    return {
      conductor: this.form.controls['conductor'].value.id,
      name_conductor: this.form.controls['conductor'].value.nombre,
      fecha: this.datePipe.transform(this.form.controls['fecha'].value, 'yyyy-MM-dd'),
      paquetes: this.form.controls['paquetes'].value,
      entrega_observacion: null,
    };

  }

  public submitData() {
    return new Promise<void>((resolve, reject) => {
      this.setPackages();

      const data = this.buildData();
      //console.log('Submit Data', data, this.idEntrega);
      this.spinnerService.show();

      if (this.editMode === false) {
        this.deliverService.post(data).subscribe(res => {
          this.openDialog('Ruta de entrega creada');
          this.router.navigateByUrl('admin/almacen/entregas/list');
        });

      } else {
        data['delete'] = this.form.controls['paquetes'].value.length === 0 ? true : false;

        this.deliverService.put(this.idEntrega, data).subscribe(() => {
          this.openDialog('Ruta de entrega actualizada');

          resolve();

          if (data['delete'] === true) {
            this.router.navigateByUrl('admin/almacen/entregas/list');
          }
        });
      }

      this.spinnerService.hide();
    });


  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  };

  public masterToggle = (): void => {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  };

  public checkboxLabel = (row?: PackageElement): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  openDialog(message, detail: string = '') {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message, detail: detail },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 4000);
    });

  }

  transitShipment() {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: {
        message: '¿Confirma que desea colocar en tránsito los ' + this.selection.selected.length + ' paquetes de esta entrega? ',
        emailcheckbox: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        //console.log('Marcar en tránsito');
        this.checkPackages(17);
        this.ngOnInit();
      }
    });
  }

  transitLimon() {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: {
        message: '¿Confirma que desea colocar en tránsito los ' + this.selection.selected.length + ' paquetes de esta entrega? ',
        emailcheckbox: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        //console.log('Marcar en tránsito');
        this.checkPackages(13);
        this.ngOnInit();
      }
    });
  }

  checkTransit() {
    const x = this.dataSource.data.filter(el => el.estado_actual === 17).length;
    const y = this.dataSource.data.length;
    const z = this.dataSource.data.filter(el => el.estado_actual === 18).length;

    const flag = (x === y) || (z === y) || x + z === y ? true : false;

    //console.log(x, y, z, x + z, flag);
    return flag;
  }
  getStateValue(state: number): string {
    const stateMap = {
      17: 'En Tránsito al cliente',
    };
    return stateMap[state];
  }

  checkPackages(statusCode) {
    const packageIds = this.selection.selected.map(element => element.id);
    this.paqueteService.changePackagesSteteMassive(packageIds,statusCode).subscribe((data: any) => {

      let deatil = `Entrega en ${StateTypeDict[statusCode]}\n`;

      if (data.successful_updates.length > 0) {
        deatil += `Paquetes actualizados: ${data.successful_updates.length}\n`;
      }
      if (data.same_states.length > 0) {
        deatil += `Paquetes con el mismo estado: ${data.same_states.length}\n`;
      }
      if (data.unallowed_updates.length > 0) {
        deatil += `Paquetes con estado no permitido: ${data.unallowed_updates.length}\n`;
      }
      if (data.error_updates.length > 0) {
        deatil += `Paquetes fallidos: ${data.error_updates.length}`;
      }
      this.openDialog('Información de la entrega', deatil);

    })
  }

  addPackages() {

    const dialogRef = this.dialog.open(SelectPackagesComponent, {
      data: { option: 'entrega', plan: 'A' },
    });

    dialogRef.afterClosed().subscribe(result => {

      //console.log('add packagaes', result);

      if (result !== undefined) {
        result.paquetes.forEach(element => {
          const item = {
            id: element.id,
            cuenta: element.cuenta + '-' + element.nombre,
            guia: element.guia,
            nombre: element.nombre,
            entregado: element.entrega.entregado,
            entrega_observacion: element.entrega.entrega_observacion,
            tracking: element.tracking,
            escaneado_entrega: false,
            estado_actual: element.idestado,
            usuarioid: element.usuarioid,
            entrega_observacion_conductor: element.entrega.entrega_observacion_conductor,
            entregaid: element.entrega.id
          };

          this.dataSource.data.push(item);

          const hash = {};
          this.dataSource.data = this.dataSource.data.filter(o => hash[o.id] ? false : hash[o.id] = true);
          this.packageTotal = this.dataSource.data.length;
          //console.log(this.dataSource.data);

        });
      }

    });
  }

  removePackages() {

    this.selection.selected.forEach(element => {
      const i = this.dataSource.data.map(function(x) {
 return x.id;
}).indexOf(element.id);
      if (i >= 0) {
        this.dataSource.data.splice(i, 1);
      }
      this.selection.toggle(element);
    });

    const hash = {};
    this.dataSource.data = this.dataSource.data.filter(o => hash[o.id] ? false : hash[o.id] = true);
    this.packageTotal = this.dataSource.data.length;

  }

  public changeEntregado(tracking, checked) {
    const objIndex = this.dataSource.data.findIndex((obj => obj.tracking === tracking));
    this.dataSource.data[objIndex].entregado = checked;

    if (checked === true) {
      this.dataSource.data[objIndex].estado_actual = 18;
    } else {
      this.dataSource.data[objIndex].estado_actual = 17;
    }

    this.entregados = 0;

    this.dataSource.data.forEach(element => {
      this.entregados = element.entregado === true ? this.entregados + 1 : this.entregados;
    });
  }

  addObservation(id, value) {
    //console.log('id', id);
    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Observación', value, type: 'general-long' },
      height: '300px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const data = {
          entrega_observacion: result.value,
          logged_user: this.authService.getUser().id
        };

        this.packagesService.putEntrega(id, data).subscribe(data => {
          this.openDialog('Observación actualizada');
          this.ngOnInit();
        });
      }
    });

  }

  // Busqueda Manual
  findByManualInput(guia) {
    if (guia.length > 1) {
      const own = this;
      const filter = this.dataSource.data.filter(function(el) {
        if(String(el.id).toLocaleLowerCase() === guia.toLocaleLowerCase()){
          guia = el.guia
        }
        return ((el.guia.toLocaleLowerCase() === guia.toLocaleLowerCase()));
      });

      //console.log('filter', filter);

      if (filter.length === 0) {
        this.packagesService.findGuia(guia).subscribe((data: any) => {

          if (data.length > 0) {
            //console.log('Paquete encontrado', data[0]);
            const element = data[0];

            if (element.entrega != null) {
              const item = {
                id: element.id,
                cuenta: this.usersService.get_fullaccount(element.usuario),
                guia: element.numero_de_guia,
                nombre: this.usersService.get_fullname(element.usuario),
                entregado: false,
                entrega_observacion: element.entrega.entrega_observacion,
                tracking: element.tracking,
                escaneado_entrega: true,
                estado_actual: element.estado_actual.id,
                usuarioid: element.usuario.id,
                entrega_observacion_conductor: element.entrega.entrega_observacion_conductor,
                entregaid: element.entrega.id
              };

              //console.log(item);

              this.dataSource.data.push(item);
              this.selection.select(item);
              const hash = {};
              this.dataSource.data = this.dataSource.data.filter(o => hash[o.id] ? false : hash[o.id] = true);
              this.packageTotal = this.dataSource.data.length;
              this.playAudioCorrect();
            } else {
              this.openDialog('Paquete se encuentra asignado');
              this.playAudioWrong();
            }

          } else {
            this.openDialog('Paquete no encontrado');
            this.playAudioWrong();
          }
        },
          err => {
            //console.log(err);
            this.openDialog('Paquete no encontrado');
            this.playAudioWrong();
          });

      } else {

        const objIndex = this.dataSource.data.findIndex((obj => (obj.guia.toLocaleLowerCase() === guia.toLocaleLowerCase())));

        //console.log('index', objIndex);
        this.dataSource.data[objIndex]['escaneado_entrega'] = true;

        //console.log(objIndex, guia);

        this.dataSource.data.forEach(element => {
          this.escaneados = element.escaneado_entrega === true ? this.escaneados + 1 : this.escaneados;
        });

        this.openDialog('Paquete Escaneado');
        this.playAudioCorrect();
      }
    }
  }


  adjuntarPaquetes(conductorId) {

    if (this.selection.selected.length > 0) {
      const data = {
        newConductor: conductorId.option.value.id,
        idEntrega: this.idEntrega,
        paquetes: this.selection.selected,
        fechaEntrega: this.form.get('fecha').value
      };

      this.deliverService.transferirPaquetes(data).subscribe(res => {
        this.openDialog('Paquetes transferidos');
        this.ngOnInit();
      });

    }
  }

  back() {
    this.navigation.back();
  }


  generatePDFEntregas() {

    this.submitData().then(() => {

      this.deliverService.getEntregas(this.idEntrega).subscribe((e: any) => {
        //console.log(e);

        e.fecha = new Date(e.fecha);
        e.fecha.setMinutes(e.fecha.getMinutes() + e.fecha.getTimezoneOffset());

        const entrega = e.paquetes;

        const groupByClients = entrega.reduce((group, paquete) => {
            const { usuario } = paquete;
            group[usuario.id] = group[usuario.id] ?? [];
            group[usuario.id].push(paquete);
            return group;
        }, {});

        let montoManifiestoUSD = 0;
        let montoManifiestoCRC = 0;
        let pendienteManifestoUSD = 0;
        let pendienteManifestoCRC = 0;
        let fechaEntrega = '';
        let rutaEntrega = '';

        setTimeout(() => {
          const pdf = new jsPDF('portrait', 'mm'); // A4, portrait, mm size page of PDF

          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();

          Object.keys(groupByClients).forEach(element => {

            let data = groupByClients[element]

            pdf.addImage('assets/logo_1.png', 'PNG', 10, 5, 60, 20);

            pdf.setFont('Times', 'normal');
            pdf.setFontSize(28);
            pdf.text('Manifiesto de Entrega', 10, 45);

            pdf.setFontSize(18);
            pdf.setFont('Helvetica', 'normal');
            pdf.text(this.datePipe.transform(new Date(e.fecha), 'dd-MM-yyyy'), 10, 55);

            const ruta = data[0].entrega.ruta ? data[0].entrega.ruta.nombre : '-';
            fechaEntrega = this.datePipe.transform(new Date(e.fecha), 'dd-MM-yyyy');
            rutaEntrega = ruta;

            pdf.setFontSize(16);

            pdf.setFont('Helvetica', 'normal');
            pdf.text('Conductor: ' + e.conductor_detalle.nombre, width - 100, 10);
            pdf.text('Zona de Entrega: ' + ruta, width - 100, 17, { maxWidth: 100 });

            let montoPendienteUSD = 0;
            let montoPendienteCRC = 0;
            let startIndex = 75;

            pdf.line(10, 65, width - 10, 65, 'F');

            let cliente = '';
            data.forEach(row => {
              cliente = this.usersService.get_fullaccount(row.usuario);

              try {
                if(row.clase !== 'D'){
                  montoPendienteUSD += (row.factura.factura_usd.monto_pendiente);
                  montoPendienteCRC += (row.factura.factura_crc.monto_pendiente);
                }
              } catch { }

              pdf.setFontSize(14);
              pdf.setFont('Helvetica', 'normal');

              // eslint-disable-next-line max-len
              const details1 = row.numero_de_guia + ' / ' + this.usersService.get_fullaccount(row.usuario);
              const details2 = 'Teléfonos: ' + row.usuario.telefono_casa + ' - ' + row.usuario.telefono_movil;
              const details3 = 'Peso: ' + row.peso_total + 'Lbs.';

              const direccion_entrega = row.entrega.direccion_entrega;
              const detalles = row.entrega.detalles_adicionales ? row.entrega.detalles_adicionales : '';

              const direccion = 'Dirección: ' + direccion_entrega + ' ' + detalles;

              pdf.text(details1, 10, startIndex);
              startIndex += 5;

              pdf.barcode(row.numero_de_guia, {
                fontSize: 60,
                textColor: '#000000',
                x: (width / 2) + 30,
                y: startIndex + 7
              });

              pdf.setFontSize(14);
              pdf.setFont('Helvetica', 'normal');

              pdf.text(details2, 10, startIndex);
              startIndex += 5;

              pdf.text(details3, 10, startIndex);
              startIndex += 10;

              pdf.text(direccion, 10, startIndex, { maxWidth: 100 });

              pdf.line((width / 2) + 10, startIndex + 5, (width / 2) + 65, startIndex + 5, 'F');
              pdf.text('Recibido Conforme', (width / 2) + 18, startIndex + 10);

              pdf.line((width / 2) + 70, startIndex + 5, (width / 2) + 95, startIndex + 5, 'F');
              pdf.text('Hora', (width / 2) + 77, startIndex + 10);

              if (row.factura.factura_usd && Object.keys(row.factura.factura_usd).length > 0 && row.clase !== 'D') {
                if (row.factura.factura_usd.total) {
                  pdf.text('Total: USD ' + row.factura.factura_usd.total.toFixed(2) + ' / CRC ' + (row.factura.factura_crc.total).toFixed(2), 10, startIndex + 25);
                }
                if (row.factura.factura_usd.monto_pendiente) {
                  pdf.text('Pendiente: USD ' + row.factura.factura_usd.monto_pendiente + ' / CRC ' + (row.factura.factura_crc.monto_pendiente).toFixed(2), 110, startIndex + 25);
                }
              } else {
                console.log('No factura_usd on ', row);
              }

              let footer = '';
              if ((row.factura.factura_usd.monto_pendiente) <= 0) {
                if (row.metodo_pago != null) {
                  footer = 'Cancelado' + ' ' + row.metodo_pago.nombre;
                  pdf.setFont('Helvetica', 'bold');
                  pdf.text(footer, (width / 2), startIndex + 32);
                }
              }

              startIndex += 35;

              pdf.line(10, startIndex, width - 10, startIndex, 'F');

              startIndex += 15;

              if (startIndex > height - 50) {
                pdf.addPage();
                pdf.line(10, 5, width - 10, 5, 'F');
                startIndex = 15;
              }

              montoManifiestoUSD += row.factura.factura_usd.total;
              montoManifiestoCRC += row.factura.factura_crc.total;
            });

            let footer = cliente + '\n';

            footer += 'Total Paquetes: ' + data.length + '\n';

            if (montoPendienteUSD > 0) {
              footer += 'Saldo Pendiente USD: ' + montoPendienteUSD.toFixed(2) + '\n';
              footer += 'Saldo Pendiente CRC: ' + montoPendienteCRC.toFixed(2);
            } else {
              footer += 'Cancelado';
            }

            pdf.setFontSize(18);
            pdf.setFont('Helvetica', 'bold');
            pdf.text(footer, (width / 2) - 50, height - 30);
            pdf.addPage();

            
            pendienteManifestoUSD += montoPendienteUSD;
            pendienteManifestoCRC += montoPendienteCRC;
            
          });

          pdf.addImage('assets/logo_1.png', 'PNG', 10, 5, 60, 20);

          pdf.setFont('Times', 'normal');
          pdf.setFontSize(28);
          pdf.text('Manifiesto de Entrega', 10, 45);

          pdf.setFontSize(18);
          pdf.setFont('Helvetica', 'normal');
          pdf.text(fechaEntrega, 10, 55);


          pdf.setFontSize(16);
          pdf.setFont('Helvetica', 'normal');
          pdf.text('Conductor: ' + e.conductor_detalle.nombre, width - 100, 10);
          pdf.text('Zona de Entrega: ' + rutaEntrega, width - 100, 17, { maxWidth: 100 });


          let footer = '';
          footer = 'Total Paquetes: ' + e.paquetes.length + '\n';
          footer += 'Total USD: ' + montoManifiestoUSD.toFixed(2) + '\n';
          footer += 'Total CRC: ' + montoManifiestoCRC.toFixed(2) + '\n';
          footer += 'Saldo Pendiente USD: ' + pendienteManifestoUSD.toFixed(2) + '\n';
          footer += 'Saldo Pendiente USD: ' + pendienteManifestoCRC.toFixed(2) + '\n';

          pdf.setFontSize(18);
          pdf.setFont('Helvetica', 'bold');
          pdf.text(footer, width - 150, height - 40);
          pdf.addPage();

          pdf.deletePage(pdf.getNumberOfPages());

          // Generated PDF
          pdf.autoPrint();
          pdf.save('Entrega ' + e.id + '.pdf', { returnPromise: true });

        }, 1000);



      });
    });
  }

  generatePDFEntregasList() {

    this.submitData().then(() => {

      this.deliverService.getEntregas(this.idEntrega).subscribe((e: any) => {
        //console.log(e);

        e.fecha = new Date(e.fecha);
        e.fecha.setMinutes(e.fecha.getMinutes() + e.fecha.getTimezoneOffset());

        const entrega = e.paquetes;

        const groupByClients = entrega.reduce((group, paquete) => {
          const { usuario } = paquete;
          group[usuario.id] = group[usuario.id] ?? [];
          group[usuario.id].push(paquete);
          return group;
        }, {});

        let montoManifiestoUSD = 0;
        let montoManifiestoCRC = 0;
        let pendienteManifestoUSD = 0;
        let pendienteManifestoCRC = 0;
        let fechaEntrega = '';
        let rutaEntrega = '';

        setTimeout(() => {
          const pdf = new jsPDF('portrait', 'mm'); // A4, portrait, mm size page of PDF

          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();


          pdf.addImage('assets/logo_1.png', 'PNG', 10, 5, 60, 20);

          pdf.setFont('Times', 'normal');
          pdf.setFontSize(28);
          pdf.text('Manifiesto de Entrega', 10, 45);

          pdf.setFontSize(18);
          pdf.setFont('Helvetica', 'normal');
          pdf.text(this.datePipe.transform(new Date(e.fecha), 'dd-MM-yyyy'), 10, 55);

          let startIndex = 75;
          Object.keys(groupByClients).forEach((element, index, array) => {

            let data = groupByClients[element]
            
            //console.log(data);


            if (index == 0) {
              const ruta = data[0].entrega.ruta ? data[0].entrega.ruta.nombre : '-';
              fechaEntrega = this.datePipe.transform(new Date(e.fecha), 'dd-MM-yyyy');
              rutaEntrega = ruta;

              pdf.setFontSize(16);

              pdf.setFont('Helvetica', 'normal');
              pdf.text('Conductor: ' + e.conductor_detalle.nombre, width - 100, 10);
              pdf.text('Zona de Entrega: ' + ruta, width - 100, 17, { maxWidth: 100 });

            }

            let montoPendienteUSD = 0;
            let montoPendienteCRC = 0;

            pdf.line(10, 65, width - 10, 65, 'F');

            let cliente = '';
            data.forEach(row => {
              cliente = this.usersService.get_fullaccount(row.usuario);

              try {
                if(row.clase !== 'D'){
                  montoPendienteUSD += (row.factura.factura_usd.monto_pendiente);
                  montoPendienteCRC += (row.factura.factura_crc.monto_pendiente);
                }
              } catch { }

              pdf.setFontSize(14);
              pdf.setFont('Helvetica', 'normal');

              // eslint-disable-next-line max-len
              const details1 = row.numero_de_guia + ' / ' + this.usersService.get_fullaccount(row.usuario);
              const details2 = 'Teléfonos: ' + row.usuario.telefono_casa + ' - ' + row.usuario.telefono_movil;
              const details3 = 'Peso: ' + row.peso_total + 'Lbs.';

              pdf.text(details1, 10, startIndex);
              startIndex += 5;

              pdf.barcode(row.numero_de_guia, {
                fontSize: 60,
                textColor: '#000000',
                x: (width / 2) + 30,
                y: startIndex + 7
              });

              pdf.setFontSize(14);
              pdf.setFont('Helvetica', 'normal');

              pdf.text(details2, 10, startIndex);
              startIndex += 5;

              pdf.text(details3, 10, startIndex);
              startIndex += 10;

              pdf.line(10, startIndex, width - 10, startIndex, 'F');

              startIndex += 15;

              if(row.clase !== 'D'){
                montoManifiestoUSD += row.factura.factura_usd.total;
                montoManifiestoCRC += row.factura.factura_crc.total;
              }

              if (startIndex > height - 50) {
                pdf.addPage();
                startIndex = 15;
              }
            });

            pendienteManifestoUSD += montoPendienteUSD;
            pendienteManifestoCRC += montoPendienteCRC;

          });

          pdf.addPage();
          startIndex = 15;

          pdf.addImage('assets/logo_1.png', 'PNG', 10, 5, 60, 20);

          pdf.setFont('Times', 'normal');
          pdf.setFontSize(28);
          pdf.text('Manifiesto de Entrega', 10, 45);

          pdf.setFontSize(18);
          pdf.setFont('Helvetica', 'normal');
          pdf.text(fechaEntrega, 10, 55);


          pdf.setFontSize(16);
          pdf.setFont('Helvetica', 'normal');
          pdf.text('Conductor: ' + e.conductor_detalle.nombre, width - 100, 10);
          pdf.text('Zona de Entrega: ' + rutaEntrega, width - 100, 17, { maxWidth: 100 });


          let footer = '';
          footer = 'Total Paquetes: ' + e.paquetes.length + '\n';
          footer += 'Total USD: ' + montoManifiestoUSD.toFixed(2) + '\n';
          footer += 'Total CRC: ' + montoManifiestoCRC.toFixed(2) + '\n';
          footer += 'Saldo Pendiente USD: ' + pendienteManifestoUSD.toFixed(2) + '\n';
          footer += 'Saldo Pendiente USD: ' + pendienteManifestoCRC.toFixed(2) + '\n';

          pdf.setFontSize(18);
          pdf.setFont('Helvetica', 'bold');
          pdf.text(footer, width - 150, height - 40);
          pdf.addPage();

          pdf.deletePage(pdf.getNumberOfPages());

          // Generated PDF
          pdf.autoPrint();
          pdf.save('Entrega ' + e.id + '.pdf', { returnPromise: true });

        }, 1000);



      });
    });
  }

  sortData(sort: Sort) {
    //console.log(sort);
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'usuario':
          return compare(a.nombre, b.nombre, isAsc);
        case 'tracking':
          return compare(a.tracking, b.tracking, isAsc);
        case 'escaneado':
          return compare(a.escaneado_entrega, b.escaneado_entrega, isAsc);
        case 'entregado':
          return compare(a.entregado, b.entregado, isAsc);
        default:
          return 0;
      }
    });
  }


  private _filter(value: any, options: any): Conductores[] {
    if (!value) {
 return options;
}
    let filterValue: string;
    value.nombre ? filterValue = value.nombre.toLowerCase() : filterValue = value.toLowerCase();
    return options.filter(user => user.nombre.toLowerCase().indexOf(filterValue) === 0);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
