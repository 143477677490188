import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface DialogData {
  title: string;
  packageIds: number[];
}

@Component({
  selector: 'app-add-list-details',
  templateUrl: './add-list-details.component.html',
  styleUrls: ['./add-list-details.component.scss']
})

export class AddListDetailsComponent implements OnInit {
  formGroup: FormGroup;
  packageIds: number[];
  options: any = [];
  isSelecting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddListDetailsComponent>,
    private paquetesService: PaquetesService,
    private formBuilder: FormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      detail: new FormControl('', [Validators.required]),
      partida: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required])
    });
  }
  selectDetail(item) {
    // Activate to avoid making a new search
    this.isSelecting = true;
    this.formGroup.patchValue({ detail: item });
    this.options = [];
    this.isSelecting = false;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    const params = {...this.formGroup.value};
    this.paquetesService.addLineDetails(this.data.packageIds, params).subscribe(
      data => {
        this.dialogRef.close(data);
      },
      error => {
        this.dialogRef.close(error);
      }
    );
  }

  getItemsSearch(value) {
    if (value.length > 3 && !this.isSelecting) {
      this.paquetesService.getItemsSearch(value).subscribe(data => {
        this.options = data;
      });
    }
  }
}
