/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable brace-style */
/* eslint-disable quote-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { FacturasService } from './../../../../../services/facturas.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { AttachPaymentComponent } from 'src/app/modules/common/dialogs/attach-payment/attach-payment.component';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';
import { CameraModalComponent } from '../../../components/camera-modal/camera-modal.component';
import { PackageLabelComponent } from '../../../components/package-label/package-label.component';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { SelectPackagesComponent } from 'src/app/modules/common/dialogs/selectPackages/select-packages.component';
import { FacturaModalComponent } from '../../../components/factura-modal/factura-modal.component';
import { PagosService } from 'src/app/services/pagos.service';
import { AdjuntarPagoComponent } from 'src/app/modules/common/dialogs/adjuntar-pago/adjuntar-pago.component';
import { ConfirmarComponent } from 'src/app/modules/common/dialogs/confirmar/confirmar.component';
import { GeneralInputComponent } from '../../../../common/dialogs/general-input/general-input.component';
import { ProgramaEntregaComponent } from '../../../../common/dialogs/programar-entrega/programar-entrega.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { FacturaViewerComponent } from '../../../../user/components/factura-viewer/factura-viewer.component';
import { ModoDeEntrega } from 'src/app/models/Usuario';
import { RutasService } from 'src/app/services/rutas.service';
import { TarifasService } from 'src/app/services/tarifas.service';
import { TiposDeCambioService } from 'src/app/services/tipos-de-cambio.service';
import { AppPackageComponent } from '../../../components/app-package/app-package.component';
import { FacturaElectronicaService } from '../../../../../services/factura_electronica.service';
import { PdfService } from 'src/app/services/generatePdf-service';
import { DelmanService } from 'src/app/services/delman.service';
import { StateTypeEnum } from 'src/app/core/data/stateTypes';
import { DataService } from 'src/app/services/data.service';


export interface ClienteElement {
  id: number;
  name: string;
  tarifaA: number;
  tarifaM: number;
}

export interface CurrencyRate {
  id: number;
  fecha: Date;
  valor: number;
  user: string;
}

@Component({
  selector: 'app-paquetes-paquete',
  templateUrl: './paquete.component.html',
  styleUrls: ['./paquete.component.scss']
})

export class PaqueteComponent implements OnInit {

  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  @ViewChild('imageF1D') imageF1D: ElementRef;
  @ViewChild('imageF2D') imageF2D: ElementRef;

  stateTypeEnum = StateTypeEnum;
  deletePackage = false;
  editMode = false;
  actual = 1;
  selectedGender: string;
  email = new FormControl('', [Validators.required, Validators.email]);
  paquetesForm: any;
  paquete: any;
  mediaUrl = environment.mediaUrl;

  cliente: ClienteElement;
  clienteOptions: ClienteElement[];
  filteredOptions: Observable<ClienteElement[]>;

  tarifas: any;

  cantPaquetes = 1;

  dialogModal = false;

  numeroGuia = '';
  loadOne: any = false;
  loadTwo: any = false;
  loadThree: any = false;
  length: number;
  pageSize = 10;
  pageIndex = 1;

  displayedColumns: string[] = [];
  dataSource: any;
  data: any = [];
  displayedColumnsPaquetes: string[] = ['guia', 'tracking', 'descripcion', 'creacion', 'modificado', 'eliminar'];
  dataSourcePaquetes: any;

  // eslint-disable-next-line max-len
  displayedColumnsAdicionales: string[] = ['guia', 'tracking', 'peso', 'alto', 'ancho', 'largo', 'peso_total', 'peso_volumetrico', 'numero_bulto', 'eliminar'];
  adicionales = [];
  dataSourceAdicionales: any;
  editInvoice = false;

  factura: any;
  fileLoad1: any;
  fileLoad2: any;
  fileLoad3: any;
  fileLoad4: any;

  selectedAction = 1;
  estadosPaquetes: any;
  estado_nuevo;

  comprobante: any = null;
  viewComprobante: any;

  displayedColumnsHistorialPaquete = ['estado', 'hora', 'usuario', 'anotacion', 'eliminar'];
  historialPaquetes;

  common_stores = ['Amazon', 'Apple', 'Best Buy', 'Carters', 'Ebay', 'Shein', 'Shipping Dept', 'Walmart'];
  filteredOptionsStores: Observable<any>;

  discountTypes = [
    { value: 'Normal', name: 'Normal' },
    { value: 'Porcentaje', name: 'Porcentual' },
  ];

  descuentoForm;

  facturasConsolidadas = [];

  selectedTab = 0;

  historialZonas;
  historialDirecciones;
  historialEntregas;
  historialPlanes;
  historialTipos;
  historialGuias;
  historialPesos;

  provincias;
  cantones;
  distritos;

  formEntrega = this.fb.group({
    delivery_id: [''],
    modo_entrega: [''],
    direccion_entrega: [''],
    detalles_adicionales: [''],
    direccion_entrega_apps: [''],
    ruta: [''],
    sucursal: [''],
    encomienda: [''],
    entrega: [''],
    entrega_observacion: [''],
    entrega_observacion_conductor: [''],
    provincia: [''],
    canton: [''],
    distrito: [''],
    tipo_zona_entrega: [''],
    fecha_entrega: [''],
    hora_entrega: [''],
    zona_centro_distribucion: ['']
  });

  deliveryModes: any[] = [
    { name: 'En domicilio', value: ModoDeEntrega.enDomicilio },
    { name: 'En sucursal', value: ModoDeEntrega.enSucursal, },
    { name: 'Fuera del Área Metropolitana', value: ModoDeEntrega.areaMetropolitana, },
  ];

  rutas;

  tabEntregas = false;

  empresasTransporte;

  pesoRealKg = '';
  pesoTotalKg = '';

  originPage = '';

  tipoCambio;

  fob = 0;

  nrocomprobante1D = '';
  montoComprobante1DUSD = '';
  montoComprobante1DCRC = '';

  editF1D = true;
  viewFile1D = false;
  fileLoad1D: any;

  editF2D = true;
  viewFile2D = false;
  fileLoad2D: any;

  visible_delman = true;

  timeout: any = null;

  otros_cargos_1;
  otros_cargos_1_descripcion;

  guias = [];
  selectedIndex = 0;

  bultos = [];

  embarquePaquetes;
  showPortal = false;


  currencyRates: CurrencyRate[];
  currentRate;
  editarTipoCambio = false;
  loadingTaxes = false;

  payment_detail: string;

  constructor(
    private fb: FormBuilder,
    private paquetesService: PaquetesService,
    private users: UsuariosService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public usuariosService: UsuariosService,
    public facturasService: FacturasService,
    public authService: AuthService,
    private datePipe: DatePipe,
    private packageLabel: PackageLabelComponent,
    private pagosService: PagosService,
    private navigation: NavigationService,
    private rutasService: RutasService,
    private tarifasService: TarifasService,
    private tipoCambioService: TiposDeCambioService,
    private cdr: ChangeDetectorRef,
    private tiposDeCambioService: TiposDeCambioService,
    private facturaElectronicaService: FacturaElectronicaService,
    private delmanService: DelmanService,
    private pdfService: PdfService,
    private dataService: DataService
  ) {
    this.paquete = {
      tipo_de_paquete: null,
      tracking: null,
      clase: 'C',
      codigo_promocional: null,
      descripcion: null,
      remitente: null,
      referencia: null,
      precio: null,
      usuario: '',
      destinatario: null,
      exonerado: false,
      estado_actual: 2,
      medida: null,
      plan: 'A',
      tarifa: 1,
      observacion: null,
      largo: null,
      alto: null,
      ancho: null,
      peso: null,
      peso_volumetrico: null,
      peso_total: null,
      factura_1: null,
      factura_2: null,
      factura_3: null,
      imagen: null,
      logged_user: null,
      paquete_contenedor: [],
      embarque_detalle: [],
      entrega: null,
      tipo_descuento: 'Normal',
      monto_descuento: 0,
      descripcion_descuento: '',
      numero_de_guia: '',
      neted_at: null,
      neted_by: null,
    };
    this.inicializarForm();
  }

  verifyDeletePackage() {
    if (this.paquete.estado_actual.id == StateTypeEnum.Eliminado) {
      this.paquetesForm.disable();
      this.deletePackage = true;
    }
  }

  async ngOnInit() {
    this.formEntrega.controls['tipo_zona_entrega'].valueChanges.subscribe(value => {
      if (value === 'r') {
        this.formEntrega.controls['fecha_entrega'].setValue('');
      }
    });

    this.dataSource = [];
    this.dataSourcePaquetes = [];
    this.dataSourceAdicionales = [];
    this.facturasConsolidadas = [];
    this.getEstadosPaquetes();

    this.editMode = false;

    await this.getTarifas().then(() => {
      this.inicializarForm();
    });

    this.getTipoCambio();

    this.activatedRoute.queryParams.subscribe(params => {
      setTimeout(() => {
        const selectedTab = this.staticTabs.tabs[this.selectedTab]
        if (selectedTab) {
          this.staticTabs.tabs[this.selectedTab].active = true;
        }
      }, 1000);

      if (this.editInvoice) {
        setTimeout(() => {
          this.staticTabs.tabs[1].active = true;
        }, 1000);
      }

      if (params.factura) {
        setTimeout(() => {
          this.staticTabs.tabs[1].active = true;
        }, 1000);
      }

      if (params.origin) {
        this.originPage = params.origin;
      }

      if (params.pack) {
        this.editMode = true;
        this.paquetesService.getById(params.pack).subscribe((res: any) => {
          res.clase == null ? res.clase = this.paquete.clase : '';
          this.paquete = res;
          this.verifyDeletePackage();

          if (this.paquete.neted_by != null) {
            this.usuariosService.get_user_by_id(this.paquete.neted_by).subscribe(data => {
              this.paquete.neted_by = this.usuariosService.get_fullname(data);
            });
          }


          this.getHistoryPackagesImages();
          this.paquete.estado_actual = this.paquete.estado_actual.id;
          this.numeroGuia = this.paquete.numero_de_guia;
          this.cantPaquetes = this.paquete.paquete_contenedor.length > 0 ? this.paquete.paquete_contenedor.length : 1;
          this.factura = this.paquete.factura;

          if (this.factura != null) {
            this.otros_cargos_1 = this.factura.otros_cargos_1;
            this.otros_cargos_1_descripcion = this.factura.otros_cargos_1_descripcion;
          }
          this.tarifas = this.tarifas.filter(f => f.codigo == this.paquete.plan);

          if (this.paquete.entrega) {
            this.formEntrega.controls.delivery_id.setValue(this.paquete.entrega.delivery_id);
            this.formEntrega.controls.modo_entrega.setValue(this.paquete.entrega.modo_entrega);
            this.formEntrega.controls.direccion_entrega.setValue(this.paquete.entrega.direccion_entrega);
            this.formEntrega.controls.detalles_adicionales.setValue(this.paquete.entrega.detalles_adicionales);
            this.formEntrega.controls.entrega_observacion.setValue(this.paquete.entrega_observacion);
            this.formEntrega.controls.sucursal.setValue(this.paquete.entrega.sucursal);
            this.formEntrega.controls.encomienda.setValue(this.paquete.entrega.encomienda);
            this.formEntrega.controls.provincia.setValue(this.paquete.entrega.provincia ? this.paquete.entrega.provincia.id : null);
            this.formEntrega.controls.canton.setValue(this.paquete.entrega.canton ? this.paquete.entrega.canton.id : null);
            this.formEntrega.controls.distrito.setValue(this.paquete.entrega.distrito ? this.paquete.entrega.distrito.id : null);
            this.formEntrega.controls.tipo_zona_entrega.setValue(this.paquete.entrega.tipo_zona_entrega);
            this.formEntrega.controls.fecha_entrega.setValue(this.paquete.entrega.fecha_entrega);
            this.formEntrega.controls.direccion_entrega_apps.setValue(this.paquete.entrega.direccion_entrega_apps);
            this.formEntrega.controls.entrega.setValue(this.paquete.entrega.entrega ? this.paquete.entrega.entrega.id : null);
            this.formEntrega.controls.entrega_observacion.setValue(this.paquete.entrega.entrega_observacion);
            this.formEntrega.controls.entrega_observacion_conductor.setValue(this.paquete.entrega.entrega_observacion_conductor);
            this.formEntrega.controls.hora_entrega.setValue(this.paquete.entrega.hora_entrega);
            this.formEntrega.controls.zona_centro_distribucion.setValue(this.paquete.entrega.zona_centro_distribucion);
            this.getProvincias();
            if (this.formEntrega.controls.provincia.value == null) {
              this.formEntrega.controls.canton.setValue(null);
            }
            this.getCantones(this.formEntrega.controls.provincia.value);
            this.getDistritos(this.formEntrega.controls.provincia.value, this.formEntrega.controls.canton.value);

            this.rutasService.get().subscribe(value => {
              this.rutas = value;
              this.formEntrega.controls.ruta.setValue(this.paquete.entrega.ruta ? this.paquete.entrega.ruta.id : null);
            });

            this.getEmpresas();
            if (this.authService.isOperacionesMiami) {
              this.formEntrega.disable();
            }
          }

          this.pagosService.getComprobante(this.paquete.id).subscribe((res: any) => {
            this.comprobante = res[0];
            const comprobanteVal = this.comprobante != undefined && this.comprobante != null ? this.comprobante.comprobante : null;
            this.viewComprobante = comprobanteVal != null ? comprobanteVal.includes('.pdf') || comprobanteVal.includes('.docx') || comprobanteVal.includes('.doc') : false;
          });

          const contenedor = this.paquete.paquete_contenedor.length > 0 ? this.paquete.paquete_contenedor : null;
          this.dataSourcePaquetes = contenedor != null ? contenedor[0].consolidado ? contenedor : [] : [];

          this.adicionales = contenedor != null ? contenedor[0].adicional ? contenedor : [] : [];
          this.dataSourceAdicionales = this.paquete.orden ? [] : this.adicionales;


          if (this.dataSourceAdicionales.length > 0) {
            this.dataSourcePaquetes = this.adicionales;
            this.displayedColumnsPaquetes = ['guia_bulto', 'tracking', 'peso', 'alto', 'ancho', 'largo', 'peso_total', 'peso_volumetrico', 'numero_bulto', 'eliminar_adicional'];
          }

          const bultos = JSON.parse(this.paquete.bultos);

          if (bultos != null) {
            if (bultos.length > 0) {
              this.bultos = bultos;
              this.dataSourceAdicionales = this.bultos;
              this.displayedColumnsAdicionales = ['tracking', 'peso', 'alto', 'ancho', 'largo', 'peso_total', 'peso_volumetrico', 'eliminar'];
            }
          }

          if (!this.authService.isCliente && this.paquete.usuario != null && this.paquete.usuario.anotacion != null) {
            const dialogRef = this.dialog.open(GeneralDialogComponent, {
              data: { name: this.paquete.usuario.anotacion },
              panelClass: 'general'
            });

            dialogRef.afterOpened().subscribe(_ => {
              setTimeout(() => {
                dialogRef.close();
              }, 3000);
            });

          }

          this.inicializarForm();
          if (this.paquete.usuario != null) {
            this.paquetesForm.controls.usuario.setValue({ id: this.paquete.usuario.id, name: this.usuariosService.get_fullname(this.paquete.usuario) });
            this.paquetesForm.value.usuario = this.paquete.usuario;
          }

          if (this.paquete.factura_1 != null) {
            if (!this.paquete.factura_1.includes('.pdf') && !this.paquete.factura_1.includes('.docx') && !this.paquete.factura_1.includes('.doc')) {
              this.paquetesForm.controls.factura_1.disable();
              this.loadOne = false;
            } else {
              this.loadOne = true;
              this.paquetesForm.controls.factura_1.disable();
            }
          }
          if (this.paquete.factura_2 != null) {
            if (!this.paquete.factura_2.includes('.pdf') && !this.paquete.factura_2.includes('.docx') && !this.paquete.factura_2.includes('.doc')) {
              this.paquetesForm.controls.factura_2.disable();
              this.loadTwo = false;
            } else {
              this.paquetesForm.controls.factura_2.disable();
              this.loadTwo = true;
            }
          }
          if (this.paquete.factura_3 != null) {
            if (!this.paquete.factura_3.includes('.pdf') && !this.paquete.factura_3.includes('.docx') && !this.paquete.factura_3.includes('.doc')) {
              this.paquetesForm.controls.factura_3.disable();
              this.loadThree = false;
            } else {
              this.paquetesForm.controls.factura_3.disable();
              this.loadThree = true;
            }

          }
          if (this.paquete.imagen != null) {
            this.paquetesForm.controls.imagen.disable();
          }

          this.UpdateKg(this.paquete.peso);
          this.UpdateKgTotal(this.paquete.peso_total);
          this.calcularPesoCobrable();

          this.selectedAction = this.paquete.estado_actual;
          this.estado_nuevo = this.paquete.estado_actual;

          if (this.paquete.paquete_contenedor.length > 0) {
            this.paquete.paquete_contenedor.forEach(f => {
              if (f.factura_1 != null) {
                if (!f.factura_1.includes('.pdf') && !f.factura_1.includes('.docx') && !f.factura_1.includes('.doc')) {
                  this.facturasConsolidadas.push({ factura: f.factura_1, viewFile: false, id: f.id, key: 'factura_1' });
                } else {
                  this.facturasConsolidadas.push({ factura: f.factura_1, viewFile: true, id: f.id, key: 'factura_1' });
                }
              }

              if (f.factura_2 != null) {
                if (!f.factura_2.includes('.pdf') && !f.factura_2.includes('.docx') && !f.factura_2.includes('.doc')) {
                  this.facturasConsolidadas.push({ factura: f.factura_2, viewFile: false, id: f.id, key: 'factura_2' });
                } else {
                  this.facturasConsolidadas.push({ factura: f.factura_2, viewFile: true, id: f.id, key: 'factura_2' });
                }
              }

              if (f.factura_3 != null) {
                if (!f.factura_3.includes('.pdf') && !f.factura_3.includes('.docx') && !f.factura_3.includes('.doc')) {
                  this.facturasConsolidadas.push({ factura: + f.factura_3, viewFile: false, id: f.id, key: 'factura_3' });
                } else {
                  this.facturasConsolidadas.push({ factura: + f.factura_3, viewFile: true, id: f.id, key: 'factura_3' });
                }
              }

            });
          }

          this.paquetesService.guiasUsuarios(this.paquete.usuario.id).subscribe((res: any) => {
            this.guias = res;
            this.selectedIndex = this.guias.findIndex(e => e.id == this.paquete.id);
          });

        });

      } else {
        this.editMode = false;
        this.paquetesForm.reset();
        this.dataSourceAdicionales = [];
        this.inicializarForm();
      }

      this.tiposDeCambioService.get().subscribe(
        (res: any) => {
          const currencyRatesList: CurrencyRate[] = [];
          res.forEach(element => {
            currencyRatesList.push({
              id: element.id,
              fecha: new Date(element.created_at),
              valor: element.value,
              user: this.usuariosService.get_fullname(element.created_by)
            });
          });
          this.currencyRates = currencyRatesList;
        }
      );
    });
  }

  validateEditAddress(): boolean {
    let editAdrress = this.paquete.estado_actual >= StateTypeEnum.EnTransitoAlCliente || (this.paquete.factura && this.paquete.factura.generado_gti && this.paquete.factura.nota_debito === null);
    return editAdrress;
  }

  gotoFile1() {
    window.open(this.mediaUrl + this.paquete.factura_1, '_blank');
    event.preventDefault();
  }
  gotoFile2() {
    window.open(this.mediaUrl + this.paquete.factura_2, '_blank');
    event.preventDefault();
  }
  gotoFile3() {
    window.open(this.mediaUrl + this.paquete.factura_3, '_blank');
    event.preventDefault();
  }
  gotoFile4() {
    window.open(this.mediaUrl + this.paquete.imagen, '_blank');
    event.preventDefault();
  }

  gotoFileF(url) {
    window.open(this.mediaUrl + url, '_blank');
    event.preventDefault();
  }

  public getTarifas() {
    return new Promise(async (resolve) => {
      this.paquetesService.getTarifas().subscribe((data: any) => {
        this.tarifas = data;
        resolve(true);
      });
    });
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return 'Campo necesario';
    }
    if (control.hasError('minlength')) {
      return 'Demasiado Corto';
    }
    if (control.hasError('maxlength')) {
      return 'Demasiado Largo';
    }
    if (control.hasError('min')) {
      return 'Límite mínimo';
    }
    if (control.hasError('email')) {
      return 'No es un correo valido';
    }
    if (control.hasError('invalidCode')) {
      return control.errors.message;
    }
    return 'Error';
  }

  cambiarValor(valor: number) {
    this.actual = valor;
  }

  calcularMedidas() {
    let pesoVolumetrico = 0;
    const largo = this.paquetesForm.controls.largo.value;
    const alto = this.paquetesForm.controls.alto.value;
    const ancho = this.paquetesForm.controls.ancho.value;
    if (largo && alto && ancho) {
      this.paquetesForm.controls.medida.setValue(`${largo} - ${alto} - ${ancho}`);
      pesoVolumetrico = (largo * alto * ancho) / 166;
    }
    else {
      this.paquetesForm.controls.medida.setValue('');
      pesoVolumetrico = 0;
    }
    this.paquetesForm.controls.peso_volumetrico.setValue(Math.ceil(pesoVolumetrico));
    this.paquetesForm.controls.peso_total_volumetrico.setValue(Math.ceil(pesoVolumetrico));
  }

  calcularPesoCobrable() {
    const peso = parseFloat(this.paquetesForm.controls.peso.value);
    const peso_volumen = parseFloat(this.paquetesForm.controls.peso_volumetrico.value);
    const own = this;
    try {
      let tarifa;
      try {
        tarifa = this.tarifas.filter(p => p.id == own.paquetesForm.value.tarifa)[0].codigo;
      } catch {
        tarifa = 'A';
      }
      if (tarifa == 'A') {
        this.paquetesForm.controls.peso_total.setValue(peso);
      } else {
        this.paquetesForm.controls.peso_total.setValue(peso > peso_volumen ? peso : peso_volumen);
      }
      this.paquetesForm.controls.peso_total_volumetrico.setValue(peso_volumen);
      if (this.adicionales.length > 0) {
        const PesoVolumetricoAdicional = this.adicionales.reduce((accumulator, object) => accumulator + object.peso_volumetrico, 0);
        this.paquetesForm.controls.peso_total.setValue(peso);
        this.paquetesForm.controls.peso_total_volumetrico.setValue(peso_volumen + PesoVolumetricoAdicional);
      }
      if (this.bultos.length > 0) {
        this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso'].value);
        this.UpdateKgTotal(this.paquetesForm.controls['peso_total'].value);
      }
      this.UpdateKgTotal(this.paquetesForm.value.peso_total);
    } catch (error) {
      console.log(error);
      this.paquetesForm.controls.peso_total.setValue(peso);
      this.UpdateKgTotal(this.paquetesForm.value.peso_total);
    }
  }

  inicializarForm() {
    if (this.paquete.estado_actual == 2 && this.paquete.usuario == null && (this.authService.isAdmin || this.authService.isServicioCliente)) {
      console.log('Remove');
      this.paquetesForm = this.fb.group({
        tipo_de_paquete: [this.paquete.tipo_de_paquete, [Validators.maxLength(250)]],
        tracking: [this.paquete.tracking, [Validators.maxLength(250)]],
        clase: [this.paquete.clase, [Validators.maxLength(250)]],
        codigo_promocional: [this.paquete.codigo_promocional, [this.isValidCode()]],
        descripcion: [this.paquete.descripcion, [Validators.maxLength(250)]],
        remitente: [this.paquete.remitente, [Validators.maxLength(250)]],
        referencia: [this.paquete.referencia, [Validators.maxLength(250)]],
        precio: [this.paquete.precio, [Validators.maxLength(250)]],
        usuario: ['', [Validators.required]],
        destinatario: [this.paquete.destinatario, [Validators.maxLength(250)]],
        exonerado: [this.paquete.exonerado],
        estado_actual: [this.paquete.estado_actual],
        medida: [this.paquete.medida, [Validators.maxLength(250)]],
        plan: [this.paquete.plan ? this.paquete.plan : 'A', [Validators.maxLength(250)]],
        tarifa: [this.paquete.tarifa.id ? this.paquete.tarifa.id : 1, [Validators.maxLength(250)]],
        observacion: [this.paquete.observacion],
        largo: [this.paquete.largo, []],
        alto: [this.paquete.alto, []],
        ancho: [this.paquete.ancho, []],
        peso: [this.paquete.peso, [Validators.maxLength(250)]],
        peso_volumetrico: [this.paquete.peso_volumetrico, [Validators.maxLength(250)]],
        peso_total: [this.paquete.peso_total, [Validators.maxLength(250)]],
        peso_total_volumetrico: [this.paquete.peso_volumetrico],
        factura_1: [this.paquete.factura_1],
        factura_2: [this.paquete.factura_2],
        factura_3: [this.paquete.factura_3],
        imagen: [this.paquete.imagen],
        logged_user: [],
        tipo_descuento: this.paquete.tipo_descuento,
        monto_descuento: this.paquete.monto_descuento,
        descripcion_descuento: this.paquete.descripcion_descuento,
        numero_de_guia: this.paquete.numero_de_guia
      });
    } else {
      this.paquetesForm = this.fb.group({
        tipo_de_paquete: [this.paquete.tipo_de_paquete, [Validators.required, Validators.maxLength(250)]],
        tracking: [this.paquete.tracking, [Validators.required, Validators.maxLength(250)]],
        clase: [this.paquete.clase, [Validators.required, Validators.maxLength(250)]],
        codigo_promocional: [this.paquete.codigo_promocional, [Validators.maxLength(250), this.isValidCode()]],
        descripcion: [this.paquete.descripcion, [Validators.required, Validators.maxLength(250)]],
        remitente: [this.paquete.remitente, [Validators.required, Validators.maxLength(250)]],
        referencia: [this.paquete.referencia, [Validators.maxLength(250)]],
        precio: [this.paquete.precio, [Validators.maxLength(250)]],
        usuario: ['', [Validators.required]],
        destinatario: [this.paquete.destinatario, [Validators.maxLength(250)]],
        exonerado: [this.paquete.exonerado],
        estado_actual: [this.paquete.estado_actual],
        medida: [this.paquete.medida, [Validators.maxLength(250)]],
        plan: [this.paquete.plan ? this.paquete.plan : 'A', [Validators.required, Validators.maxLength(250)]],
        tarifa: [this.paquete.tarifa.id ? this.paquete.tarifa.id : 1, [Validators.required, Validators.maxLength(250)]],
        observacion: [this.paquete.observacion],
        largo: [this.paquete.largo],
        alto: [this.paquete.alto],
        ancho: [this.paquete.ancho],
        peso: [this.paquete.peso, [Validators.required, Validators.maxLength(250)]],
        peso_volumetrico: [this.paquete.peso_volumetrico, [Validators.required, Validators.maxLength(250)]],
        peso_total: [this.paquete.peso_total, [Validators.required, Validators.maxLength(250)]],
        peso_total_volumetrico: [this.paquete.peso_volumetrico],
        factura_1: [this.paquete.factura_1],
        factura_2: [this.paquete.factura_2],
        factura_3: [this.paquete.factura_3],
        imagen: [this.paquete.imagen],
        logged_user: [],
        tipo_descuento: this.paquete.tipo_descuento,
        monto_descuento: this.paquete.monto_descuento,
        descripcion_descuento: this.paquete.descripcion_descuento,
        numero_de_guia: this.paquete.numero_de_guia
      });
    }

    this.descuentoForm = this.fb.group({
      tipo_descuento: [this.paquete.tipo_descuento, [Validators.required]],
      monto_descuento: [this.paquete.monto_descuento, [Validators.required]],
      descripcion_descuento: [this.paquete.descripcion_descuento, [Validators.required]],
      logged_user: [],
    });

    if (this.dialogModal === true) {
      this.paquetesForm.get('medida').clearValidators();
      this.paquetesForm.get('largo').clearValidators();
      this.paquetesForm.get('alto').clearValidators();
      this.paquetesForm.get('ancho').clearValidators();
      this.paquetesForm.get('peso').clearValidators();
      this.paquetesForm.get('peso_volumetrico').clearValidators();
      this.paquetesForm.get('peso_total').clearValidators();
    }
    this.paquetesForm.value.estado_actual = 1;
    this.filteredOptionsStores = this.paquetesForm.controls.remitente.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filterStore(value)),
    );
    if (this.paquete.embarque != null) {
      this.visible_delman = this.paquete.embarque_detalle.visible_delman;
    }
  }

  crearPaquete() {
    this.replaceBlankWithNull();
    if (this.paquetesForm.valid) {
      this.spinnerService.show();
      this.paquetesForm.controls.usuario.enable();
      this.setUser();
      this.paquetesForm.patchValue({ estado_actual: 4 });
      this.paquetesForm.controls.logged_user.setValue(this.authService.getUser().id);
      if (this.paquetesForm.value.factura_1 == '' || this.paquetesForm.value.factura_1 == null) {
        delete this.paquetesForm.value.factura_1;
      }
      if (this.paquetesForm.value.factura_2 == '' || this.paquetesForm.value.factura_2 == null) {
        delete this.paquetesForm.value.factura_2;
      }
      if (this.paquetesForm.value.factura_3 == '' || this.paquetesForm.value.factura_3 == null) {
        delete this.paquetesForm.value.factura_3;
      }
      if (this.adicionales.length > 0) {
        this.paquetesForm.value.adicional = true;
      }
      if (this.paquetesForm.value.codigo_promocional) {
        this.paquetesForm.value.codigo_promocional = this.paquetesForm.value.codigo_promocional.toUpperCase();
      }
      this.paquetesService.post(this.paquetesForm.value).subscribe((data: any) => {
        this.resetForm();
        this.spinnerService.hide();
        this.openDialog(`Paquete creado con éxito número de guía: ${data.numero_de_guia}`);
        this.router.navigateByUrl('/admin/paquetes/buscar');

      }, err => {
        this.spinnerService.hide();
        console.error(err);

        if (err.error.plan) {
          this.openDialog(err.error.message);
        }
      });
    }
  }

  toggleNeted() {
    if (this.paquete.neted_at == null) {
      this.paquetesService.netPackages([{ paquete: this.paquete.id, factura: this.paquete.factura.id }]).subscribe((res: any) => {
        if (res.success.length > 0) {
          this.openDialog('Paquete neteado');
        } else {
          this.openDialog('Error al netear el paquete')
        }
        this.ngOnInit();
      });
    } else {
      this.paquetesService.unnetPackages(this.paquete.id).subscribe((res: any) => {
        if (res.success.length > 0) {
          this.openDialog('Paquete desneteado');
        } else {
          this.openDialog('Error al desnetear el paquete')
        }
        this.ngOnInit();
      });
    }
  }

  togglePago() {
    this.paquetesService.togglePaymentStatus(this.paquete.id).subscribe((res: any) => {
      if (res.success.length > 0) {
        this.openDialog('Estado de pago actualizado');
      } else {
        this.openDialog('Error al actualizar el estado de pago')
      }
      this.ngOnInit();
    });
  }

  actualizarPaquete() {
    if (this.tabEntregas === false) {
      this.replaceBlankWithNull();

      // Adjustment required for cases where the `peso_volumetrico` is not set
      if (!this.paquetesForm.controls.peso_volumetrico.value) {
        this.calcularMedidas();
      }

      if (this.paquetesForm.valid) {
        this.spinnerService.show();
        this.paquetesForm.controls.usuario.enable();
        this.setUser();

        this.paquetesForm.controls.logged_user.setValue(this.authService.getUser().id);

        this.paquetesForm.patchValue({ estado_actual: this.estado_nuevo });

        if (this.paquetesForm.value.factura_1 == '' || this.paquetesForm.value.factura_1 == null) {
          delete this.paquetesForm.value.factura_1;
        }

        if (this.paquetesForm.value.factura_2 == '' || this.paquetesForm.value.factura_2 == null) {
          delete this.paquetesForm.value.factura_2;
        }

        if (this.paquetesForm.value.factura_3 == '' || this.paquetesForm.value.factura_3 == null) {
          delete this.paquetesForm.value.factura_3;
        }

        if (this.adicionales.length > 0) {
          this.paquetesForm.value.adicional = true;
        }

        if (this.paquetesForm.value.codigo_promocional) {
          this.paquetesForm.value.codigo_promocional = this.paquetesForm.value.codigo_promocional.toUpperCase();
        }

        if (this.bultos.length > 0) {
          this.paquetesForm.value.bultos = JSON.stringify(this.bultos);
        }

        if (isNaN(this.paquetesForm.value.peso_total)) {
          this.paquetesForm.controls.peso_total.setValue(0);
        }

        if (isNaN(this.paquetesForm.value.peso_total_volumetrico)) {
          this.paquetesForm.controls.peso_total_volumetrico.setValue(0);
        }

        this.paquetesService.put(this.paquete.id, this.paquetesForm.value).subscribe((data: any) => {
          this.spinnerService.hide();
          if (this.adicionales.length > 0) {
            this.adicionales.forEach((value, index, array) => {
              const paquete = {
                id: value.id,
                estado_actual: 5,
                paquete_contenedor: data.numero_de_guia,
                bulto: value.bulto,
                adicional: true,
                logged_user: this.authService.getUser().id,
                factura: 0,
              };

              this.paquetesService.put(value.id, paquete).subscribe((res: any) => {
                if (index == array.length - 1) {
                  this.openDialog('Paquete Actualizado');
                  this.ngOnInit();
                  this.crearFactura();
                }
              });
            });

          } else {
            this.openDialog('Paquete Actualizado');
            if (this.paquetesForm.controls.peso_total.value > 0) {
              this.crearFactura();
            } else {
              this.ngOnInit();
            }

          }
        }, err => {
          this.spinnerService.hide();
          if (err.error.plan) {
            this.openDialog(err.error.message);
          }
        });
      } else {
        const missingVals = Object.keys(this.paquetesForm.controls).filter(key => {
          const control = this.paquetesForm.controls[key];
          return control.invalid;
        });
        this.openDialog('Datos incompletos', `Por favor complete todos los campos requeridos. (${missingVals})`);
      }

    } else {
      const data = this.formEntrega.value;
      this.paquetesService.putEntrega(this.paquete.entrega.id, data).subscribe((res: any) => {
        this.openDialog('Datos de Entrega actualizados');
        this.ngOnInit();
        this.crearFactura();
      });
    }
  }

  borrarPaquete() {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: '¿Confirma que desea eliminar este paquete?' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paquetesService.deletePaquete(this.paquete.id).subscribe(result => {
          this.openDialog('Paquete Eliminado');
          this.router.navigateByUrl('admin/paquetes/buscar');
        },
          err => {
            console.log('HTTP Error', err);
            this.openDialog('No se puede eliminar este paquete');
          });
      }
    });
  }

  actualizarDescuento() {
    if (this.descuentoForm.valid) {
      this.descuentoForm.controls.logged_user.setValue(this.authService.getUser().id);
      this.paquetesService.put(this.paquete.id, this.descuentoForm.value).subscribe((data: any) => {
        this.spinnerService.hide();
        this.openDialog('Descuento Actualizado');
        this.paquete.tipo_descuento = this.descuentoForm.value.tipo_descuento;
        this.paquete.monto_descuento = this.descuentoForm.value.monto_descuento;
        this.paquete.descripcion_descuento = this.descuentoForm.value.descripcion_descuento;
        this.paquetesForm.patchValue({
          tipo_descuento: this.paquete.tipo_descuento,
          monto_descuento: this.paquete.monto_descuento,
          descripcion_descuento: this.paquete.descripcion_descuento
        });
      });
    }
  }

  actualizarCargos() {
    const data = {
      otros_cargos_1: Number(this.otros_cargos_1).toFixed(2),
      otros_cargos_1_descripcion: this.otros_cargos_1_descripcion,
      paquete: this.paquete.id,
      logged_user: this.authService.getUser().id,
    };
    this.facturasService.crearFactura(data).subscribe((data: any) => {
      this.openDialog('Otros Cargos Actualizados');
    }, (error: any) => {
      this.openDialog('Error al Generar Factura: ' + error.error);
    });
  }

  public setUser() {
    if (this.paquetesForm.value.usuario.id) {
      this.paquetesForm.controls.usuario.setValue(this.paquetesForm.value.usuario.id);
    }
  }

  processFile(event, id = '') {
    if (event.length > 0) {
      const file = event[0];
      const size = file.size / 1024;
      if (size <= 20480) {
        if (file.name.includes('.docx') || file.name.includes('.pdf') || file.name.includes('.doc') || file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png')) {
          switch (id) {
            case '1':
              this.fileLoad1 = file;
              this.paquetesForm.controls.factura_1.setValue(file);
              break;
            case '2':
              this.fileLoad2 = file;
              this.paquetesForm.controls.factura_2.setValue(file);
              break;
            case '3':
              this.fileLoad3 = file;
              this.paquetesForm.controls.factura_3.setValue(file);
              break;
            case '4':
              this.fileLoad4 = file;
              this.paquetesForm.controls.imagen.setValue(file);
              break;
            default:
              break;
          }
        } else {
          this.openDialog('Formato de archivo invalido');

        }
      } else {
        this.openDialog('Archivo supera los 20 megabytes');
      }
    }
  }

  openDialog(message, detail: string = '') {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message, detail },
      panelClass: 'general'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });

  }

  buscarListaClientes(event = { keyCode: 13 }, cuenta = null) {
    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.usuariosService.search(cuenta).subscribe((res: any) => {
          const datos: ClienteElement[] = [];
          res.forEach(element => {
            // eslint-disable-next-line max-len
            datos.push({ id: element.id, name: $this.usuariosService.get_account_number(element) + '-' + $this.usuariosService.get_fullname(element), tarifaA: element.tarifaA, tarifaM: element.tarifaM });
          });
          $this.clienteOptions = datos;

          $this.filteredOptions = $this.paquetesForm.controls.usuario.valueChanges.pipe(
            startWith(''),
            map(user => $this._filter(user, cuenta, $this.clienteOptions)),
          );
        });
      }
    }, 1000);
  }

  displayFn(value) {
    if (!value) { return ''; }
    return value.name;
  }

  isValidCode(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value) {
        if (control.value.length == 0 || control.value == this.paquete.codigo_promocional) {
          return null;
        }
        const own = this;
        const tarifa = this.tarifas.filter(el => el.id === own.paquetesForm.value.tarifa)[0].codigo;

        // eslint-disable-next-line max-len
        this.paquetesService.checkCodigoPromocional(this.paquetesForm.value.usuario.id, control.value.toUpperCase(), tarifa, this.paquete.id).subscribe((data: any) => {
          if (!data.valid) {
            control.setErrors({ 'invalidCode': true, 'message': data.message });
          } else {
            return null;
          }
        }, (error) => {
          control.setErrors({ invalidCode: true });
        });
      } else {
        return null;
      }
    };
  }

  openCamera(event, file) {
    const dialogRef = this.dialog.open(CameraModalComponent, {
      data: { title: 'Capturar imagen' },
      height: '400px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {

        const image = this.b64toFile(result.image.imageAsDataUrl, file);
        const formFile = new File([image], file + '.png', { type: 'image/png', lastModified: new Date().getTime() });

        this.paquetesForm.get(file).setValue(formFile);

      }
    });

  }

  b64toFile(dataURI, file): File {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { 'type': mimeString });
    blob['lastModifiedDate'] = (new Date()).toISOString();
    blob['name'] = file;

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob['name'] += '.jpg';
        break;
      case 'image/png':
        blob['name'] += '.png';
        break;
    }
    // cast to a File
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <File>blob;
  }

  printLabel() {
    const packages = [];
    let index = 1;
    const total = this.paquete.paquete_contenedor.length > 0 ? this.paquete.paquete_contenedor.filter(item => item.adicional === true).length > 0 ? this.paquete.paquete_contenedor.filter(item => item.adicional === true).length : 0 : 0;

    this.usuariosService.get_user_by_id(this.paquetesForm.value.usuario.id).subscribe(data => {
      this.paquete.remitente = this.paquetesForm.value.remitente;
      this.paquete.destinatario = this.paquetesForm.value.destinatario;
      this.paquete.precio = this.paquetesForm.value.precio;
      this.paquete.descripcion = this.paquetesForm.value.descripcion;
      this.paquete.ancho = this.paquetesForm.value.ancho;
      this.paquete.alto = this.paquetesForm.value.alto;
      this.paquete.largo = this.paquetesForm.value.largo;
      this.paquete.peso = this.paquetesForm.value.peso;
      this.paquete.medida = this.paquetesForm.value.medida;
      this.paquete.peso_volumetrico = this.paquetesForm.value.peso_volumetrico;
      this.paquete.peso_total = this.paquetesForm.value.peso_total;
      this.paquete.index = index;
      this.paquete.total = total;
      this.paquete.usuario = data;
      if (this.adicionales.length == 0 && this.bultos.length == 0) {
        packages.push(this.paquete);
      }
      if (this.bultos.length > 0) {
        this.bultos.forEach(element => {
          index = index + 1;
          element.index = index;
          element.numero_de_guia = this.paquete.numero_de_guia;
          element.total = this.bultos.length;
          element.usuario = data;
          element.entrega = { ruta: this.paquete.entrega.ruta };
          packages.push(element);
        });
      }
      this.packageLabel.generatePackageLabel(packages);
    });
  }

  onSelect(data: TabDirective): void {
    if (data.heading == 'Historial') {
      this.getHistoryPackagesImages();
    }
    if (data.heading == 'Facturación') {
      this.selectedTab = 1;
    }
    if (data.heading == 'Entrega') {
      this.tabEntregas = true;
    } else {
      this.tabEntregas = false;
    }
  }

  openModalFactura(id) {
    const data = id;
    const dialogRef = this.dialog.open(FacturaModalComponent, {
      width: '100%',
      data,
      panelClass: 'factura'
    });
    dialogRef.afterClosed().subscribe(value => {
      this.editInvoice = true;
      this.ngOnInit();
    });
  }

  public openFacturaViewer(factura) {
    const dialogRef = this.dialog.open(FacturaViewerComponent, {
      data: { factura, comprobante: false },
      width: '800px',
      minHeight: '600px',
      panelClass: 'factura'
    });
  }

  async getHistoryPackagesImages() {
    this.displayedColumns = ['guia', 'imagen', 'fecha', 'usuario'];
    const aux = await this.paquetesService.getHistorialImagenesById(this.paquete.id).toPromise() || [];
    this.data = [];
    if (aux) {
      aux.forEach((element, index) => {
        const item = {
          img_cambiada: element.img_cambiada,
          image: '/media/' + element.image,
          guia: element.guia[0].numero_de_guia,
          fecha: element.update_at,
          usuario: this.usuariosService.get_fullname(element.usermodify),
          zona: '-',
          direccion: '-',
          estado: '-',
          tipo: 'imagen',
        };
        this.data.push(item);
      });
    }
    this.getHistoryPackagesEstado();
  }

  async getHistoryPackagesEstado() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'fecha', 'detalles', 'usuario'];
    const aux = await this.paquetesService.getHistorialEstadosById(this.paquete.id).toPromise();
    this.historialPaquetes = [];
    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        estado: element.estado.estado,
        guia: element.guia,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        detalles: element.anotacion,
        anotacion: element.anotacion,
        img_cambiada: '-',
        zona: '-',
        direccion: '-',
        optional: element.optional,
        tipo: 'estado'
      };

      this.data.push(item);
      this.historialPaquetes.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.historialPaquetes.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.historialPaquetes = this.historialPaquetes.filter(e => e.optional == false);
    this.getHistoryZonas();
  }

  async getHistoryZonas() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'zona', 'fecha', 'detalles', 'usuario'];
    const aux = await this.paquetesService.getPaqueteHistorialZona(this.paquete.id).toPromise();
    this.historialZonas = [];
    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        guia: element.guia,
        zona: element.zona,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        img_cambiada: '-',
        estado: '-',
        direccion: '-',
        tipo: 'zona'
      };
      this.data.push(item);
      this.historialZonas.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.historialZonas.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.getHistoryDirecciones();
  }

  async getHistoryDirecciones() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'zona', 'fecha', 'detalles', 'usuario'];
    const aux = await this.paquetesService.getPaqueteHistorialDirecciones(this.paquete.id).toPromise();
    this.historialDirecciones = [];
    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        guia: element.guia,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        detalles: element.direccion + ' ' + element.direccion2,
        img_cambiada: '-',
        zona: '-',
        optional: element.optional,
        tipo: 'direccion'
      };

      this.data.push(item);
      this.historialDirecciones.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.historialDirecciones.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.getHistoryEntregas();
  }

  async getHistoryEntregas() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'zona', 'detalles', 'fecha', 'usuario'];
    const aux = await this.paquetesService.getPaqueteHistorialEntregas(this.paquete.id).toPromise();
    this.historialEntregas = [];
    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        guia: element.guia,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        detalles: element.conductor,
        img_cambiada: '-',
        estado: '-',
        zona: '-',
        tipo: 'entrega'
      };
      this.data.push(item);
      this.historialEntregas.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.getHistoryPlan();
  }

  async getHistoryPlan() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'zona', 'detalles', 'fecha', 'usuario'];
    const aux = await this.paquetesService.getPaqueteHistorialPlanes(this.paquete.id).toPromise();
    this.historialPlanes = [];
    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        guia: element.guia,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        detalles: element.plan,
        img_cambiada: '-',
        estado: '-',
        zona: '-',
        tipo: 'plan'
      };
      this.data.push(item);
      this.historialPlanes.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.getHistoryTipo();
  }

  async getHistoryTipo() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'zona', 'detalles', 'fecha', 'usuario'];
    const aux = await this.paquetesService.getPaqueteHistorialTipo(this.paquete.id).toPromise();
    this.historialTipos = [];
    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        guia: element.guia,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        detalles: element.tipo,
        img_cambiada: '-',
        estado: '-',
        zona: '-',
        tipo: 'tipo'
      };
      this.data.push(item);
      this.historialTipos.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.getHistoryGuia();
  }

  async getHistoryGuia() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'zona', 'detalles', 'fecha', 'usuario'];
    const aux = await this.paquetesService.getPaqueteHistorialGuia(this.paquete.id).toPromise();

    this.historialGuias = [];

    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        guia: element.guia,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        detalles: 'Guia Anterior: ' + element.old_guia + ' Guía Nueva: ' + element.new_guia,
        img_cambiada: '-',
        estado: '-',
        zona: '-',
        tipo: 'guia'
      };
      this.data.push(item);
      this.historialGuias.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.getHistoryPeso();
  }

  async getHistoryPeso() {
    this.displayedColumns = ['guia', 'tipo', 'imagen', 'estado', 'zona', 'detalles', 'fecha', 'usuario'];
    const aux = await this.paquetesService.getPaqueteHistorialPeso(this.paquete.id).toPromise();
    this.historialPesos = [];
    aux.forEach((element, index) => {
      const item = {
        id: element.id,
        guia: element.guia,
        fecha: element.update_at,
        usuario: this.usuariosService.get_fullname(element.usermodify),
        detalles: 'Peso Anterior: ' + element.old_peso + ' Peso Nuevo: ' + element.new_peso,
        img_cambiada: '-',
        estado: '-',
        zona: '-',
        tipo: 'peso'
      };
      this.data.push(item);
      this.historialPesos.push(item);
    });
    this.data.sort((a, b) => (a.fecha < b.fecha) ? 1 : ((b.fecha < a.fecha) ? -1 : 0));
    this.dataSource = this.data;
  }

  marcarConsolidado() {
    this.paquetesService.marcarConsolidado(this.paquete.id).subscribe((data: any) => {
      this.openDialog('Consolidación Completa');
      this.paquete.consolidado = true;
    });

  }

  addPackagesAdditional() {
    const dialogRef = this.dialog.open(SelectPackagesComponent, {
      data: { option: 'adicionar', plan: 1, user: this.paquete.usuario.id, paqueteid: this.paquete.id },
      width: '95vw',
      maxWidth: '95vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      result.paquetes = result.paquetes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      if (result !== undefined) {
        let bulto = 2;
        result.paquetes.forEach(element => {
          const item = {
            id: element.id,
            tracking: element.tracking,
            peso: element.peso,
            alto: element.alto,
            ancho: element.ancho,
            largo: element.largo,
            peso_total: element.peso_total,
            numero_de_guia: element.guia,
            peso_volumetrico: element.peso_volumetrico,
            bulto,
          };
          this.adicionales.push(item);
          bulto = bulto + 1;
          this.paquetesForm.controls['peso'].setValue(this.paquetesForm.controls['peso'].value + element.peso);
          this.paquetesForm.controls['peso_volumetrico'].setValue(this.paquetesForm.controls['peso_volumetrico'].value + element.peso_volumetrico);
          this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso_total'].value + element.peso_total);
        });
        this.cantPaquetes = this.adicionales.length + 1;
        this.dataSourceAdicionales = this.adicionales;
      }
    });
  }

  removePackageAdditional(id) {
    if (this.displayedColumnsAdicionales.includes('guia')) {
      const i = this.adicionales.map(function (x) { return x.id; }).indexOf(id);

      if (i >= 0) {
        this.paquetesForm.controls['peso'].setValue(this.paquetesForm.controls['peso'].value - this.adicionales[i].peso);
        this.paquetesForm.controls['peso_volumetrico'].setValue(this.paquetesForm.controls['peso_volumetrico'].value - this.adicionales[i].peso_volumetrico);
        this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso_total'].value - this.adicionales[i].peso_total);

        this.adicionales.splice(i, 1);
      }

      let bulto = 1;
      this.adicionales.forEach(element => {
        element.bulto = bulto;
        bulto = bulto + 1;
      });

      const paquete = {
        id,
        estado_actual: 4,
        paquete_contenedor: 'False',
        bulto: null,
        adicional: false,
        logged_user: this.authService.getUser().id,
      };

      this.paquetesService.put(id, paquete).subscribe((res: any) => {
        this.dataSourceAdicionales = this.adicionales;
        this.paquetesService.put(this.paquete.id, this.paquetesForm.value).subscribe((data: any) => { });

        if (this.adicionales.length === 0) {
          const paquete = {
            id: this.paquete.id,
            estado_actual: this.paquete.estado_actual,
            paquete_contenedor: 'False',
            bulto: null,
            adicional: false,
            logged_user: this.authService.getUser().id,
          };
          this.paquetesService.put(paquete.id, paquete).subscribe((data: any) => { this.ngOnInit(); });

        } else {
          this.ngOnInit();
        }

      });

      this.cantPaquetes = this.adicionales.length + 1;

    } else {
      const i = this.bultos.map(function (x) { return x.id; }).indexOf(id);
      if (i >= 0) {
        this.bultos.splice(i, 1);
        const PesoBultos = this.bultos.reduce((accumulator, object) => accumulator + object.peso_total, 0);
        this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso'].value + PesoBultos);
        this.UpdateKgTotal(this.paquetesForm.controls['peso_total'].value);
        this.dataSourceAdicionales = [...this.bultos];
      }
    }
  }

  paginate(event: any) {
    this.pageIndex = event;
  }

  checkPackageType(type) {
    if (type == 'SobreBolsa') {
      this.paquetesForm.controls['precio'].setValue(10);
      this.paquetesForm.controls['ancho'].setValue(1);
      this.paquetesForm.controls['alto'].setValue(1);
      this.paquetesForm.controls['largo'].setValue(1);
      this.paquetesForm.controls['medida'].setValue('1 - 1 - 1');
      this.paquetesForm.controls['peso'].setValue(1);
      this.paquetesForm.controls['peso_volumetrico'].setValue(1);
      this.paquetesForm.controls['peso_total'].setValue(1);
    }
  }

  getEstadosPaquetes() {
    const rol = this.authService.activeRol;
    this.paquetesService.getEstadosPaquetes().subscribe((data: any) => { this.estadosPaquetes = data; });
  }

  gotoComprobante(file) {
    window.open(this.mediaUrl + file, '_blank');
    event.preventDefault();
  }

  aprobarPago() {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      // eslint-disable-next-line max-len
      data: { message: '¿Confirma que desea aprobar este pago?' },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result) {
        const value = {
          aprobado: true,
          referencia: this.comprobante.referencia,
          aprobado_by: this.authService.getUser().id,
          usuario: this.paquete.usuario.id
        };

        this.pagosService.putComprobante(value, this.comprobante.id).subscribe((comprobante: any) => {
          this.procesarPago(8, this.comprobante.referencia, this.factura.factura_usd.total.toFixed(2), this.factura.factura_crc.total.toFixed(2), this.paquete.usuario.id, this.authService.getUser().id,
          this.pagosService.transferPayment({ guide: this.paquete.numero_de_guia })
        );
        });
      }
    });
  }

  rechazarPago() {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      // eslint-disable-next-line max-len
      data: { message: '¿Confirma que desea rechazar este pago?' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = {
          estado_pago: 0,
          metodo_pago: null,
          logged_user: this.authService.getUser().id,
        };
        const dataEntrega = {
          fecha_entrega: null
        };
        this.pagosService.deleteComprobante(this.comprobante.id).subscribe(() => {
          this.paquetesService.put(this.paquete.id, data).subscribe(() => { });
          this.paquetesService.putEntrega(this.paquete.entrega.id, dataEntrega).subscribe(() => { });
          this.openDialog('Pago Rechazado');
          this.ngOnInit();
        });
      }
    });
  }

  procesarPago(metodo_pago, referencia, monto, monto_crc, usuario, created_by, fnCallBack=null) {
    this.spinnerService.show();
    const data = {
      metodo_pago,
      monto,
      monto_crc,
      referencia,
      usuario,
      created_by,
      detalle_pago: this.payment_detail,
    };

    this.pagosService.procesarPago(data).subscribe((pago: any) => {
      if (pago) {
        const id = this.paquete.id;
        let fechaProgramada = new Date();
        fechaProgramada.setDate(fechaProgramada.getDate());
        fechaProgramada = this.dataService.validateDeliveryDate(fechaProgramada);
        const dataPackages = {
          orden: this.paquete.orden,
          fecha_entrega: this.datePipe.transform(fechaProgramada, 'yyyy-MM-dd'),
          metodo_pago,
          metodo_pago2: metodo_pago,
          estado_pago: 1,
          logged_user: this.authService.getUser().id
        };
        const dataEntrega = {
          fecha_entrega: this.datePipe.transform(fechaProgramada, 'yyyy-MM-dd'),
          logged_user: this.authService.getUser().id,
        };

        // eslint-disable-next-line max-len
        this.facturasService.putFacturas(this.factura.id, { pago: pago.id, usuario, completada: true, bloqueada: true }).subscribe((data: any) => {
          this.paquetesService.put(id, dataPackages).subscribe((data: any) => {
            this.paquetesService.putEntrega(this.paquete.entrega.id, dataEntrega).subscribe(() => {
              this.spinnerService.hide();
              if (fnCallBack) {
                fnCallBack.subscribe(() => {
                  this.ngOnInit();
                });
              }
              else {
                this.ngOnInit();
              }
            });
          }, err => {
            console.error(err);
          });

        });

      }

    });
  }

  programarEntrega() {
    const paquetes = [];
    paquetes.push(this.paquete);
    const dialogRef = this.dialog.open(ProgramaEntregaComponent, {
      data: {
        title: 'Adjuntar Pago',
        paquetes,
        monto_usd: this.factura.factura_usd.monto_pendiente,
        monto_crc: this.factura.factura_crc.monto_pendiente,
        cargo_encomienda: this.factura.cargo_encomienda,
        tipo_cambio: this.factura.tipo_de_cambio.value,
        aprobado: true,
        origin: 'admin'
      },
      height: '750px',
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.metodo_pago == 3 || result.metodo_pago == 7 || result.metodo_pago == 9) {
        this.payment_detail = result.detail;
        this.procesarPago(result.metodo_pago, result.referencia, this.factura.factura_usd.total.toFixed(2), this.factura.factura_crc.total.toFixed(2), this.paquete.usuario.id, this.authService.getUser().id);

      }
      if (result.status == true) {
        this.pagosService.getComprobante(this.paquete.id).subscribe((res: any) => {
          this.comprobante = res[0];
          const value = {
            aprobado: true,
            referencia: this.comprobante.referencia,
            aprobado_by: this.authService.getUser().id,
            usuario: this.paquete.usuario.id
          };

          if (this.comprobante != null) {
            this.pagosService.putComprobante(value, this.comprobante.id).subscribe((comprobante: any) => {
              this.procesarPago(result.metodo, this.comprobante.referencia, this.factura.factura_usd.total.toFixed(2), this.factura.factura_crc.total.toFixed(2), this.paquete.usuario.id, this.authService.getUser().id);
              this.ngOnInit();
            });
          }
        });
      }

      if ((result.status == false && result.change == true)) {
        this.authService.getUserById(paquetes[0].usuario.id).subscribe(data => {
          const metodoEntrega = data.body.modo_de_entrega;
          const direccionEntrega = data.body.direccion_entrega;
          const detallesAdicionales = data.body.detalles_adicionales;

          const provincia = data.body.provincia.id;
          const canton = data.body.canton.id;
          const distrito = data.body.distrito.id;
          const sucursal = data.body.sucursal;
          const ruta = data.body.ruta ? data.body.ruta.id : null;
          const direccionEntregaApps = data.body.direccion_entrega_apps;
          const tipoZonaEntrega = data.body.tipo_zona_entrega;
          const dataEntrega = {
            delivery_id: null,
            fecha_entrega: null,
            direccion_entrega: direccionEntrega,
            detalles_adicionales: detallesAdicionales,
            direccion_entrega_apps: direccionEntregaApps,
            tipo_zona_entrega: tipoZonaEntrega,
            logged_user: this.authService.getUser().id,
            modo_entrega: metodoEntrega,
            sucursal,
            ruta,
            provincia,
            canton,
            distrito,
            entrega_observacion: ''
          };

          paquetes.forEach((paquete, index, array) => {
            let entrega = 0;
            if (typeof (paquete.entrega) == 'object') {
              entrega = paquete.entrega.id;
            } else {
              entrega = paquete.entrega;
            }

            this.paquetesService.putEntrega(entrega, dataEntrega).subscribe(() => {
              this.crearFactura2(paquete).then((res: any) => {
                this.ngOnInit();
              });
            });

          });
        });
      }

    });
  }

  crearFactura2(paquete) {
    return new Promise((resolve, reject) => {
      const data = {
        cargo_encomienda: 0,
        completada: true,
        logged_user: this.authService.getUser().id,
      };
      this.facturasService.putFacturas(paquete.factura.id, data).subscribe((res: any) => {
        const dataFactura = {
          paquete: paquete.id,
          logged_user: this.authService.getUser().id,
        };
        this.facturasService.crearFactura(dataFactura).subscribe((data: any) => { resolve(data); }, (error: any) => { });
      });
    });
  }

  deleteEstadoHistorial(id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      // eslint-disable-next-line max-len
      data: { message: '¿Confirma que desea eliminar esta actualización?' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paquetesService.deletePaqueteHistorial(id).subscribe((data: any) => {
          this.getHistoryPackagesEstado();
          this.openDialog('Actualización Eliminada');
          this.ngOnInit();
        });
      }
    });
  }

  editEstadoHistorial(id, estado?, anotacion = '') {
    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Anotación', anotacion, type: 'general' },
      height: '250px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const data = {
          anotacion: result.value,
        };

        this.paquetesService.putPaqueteHistorial(id, data).subscribe(data => {
          this.openDialog('Anotación actualizada');
          this.getHistoryPackagesEstado();
        });
      }
    });
  }

  addEstadoHistorial(estado) {
    if (estado > this.paquete.estado_actual) {
      const dialogRef = this.dialog.open(ConfirmarComponent, {
        // eslint-disable-next-line max-len
        data: { message: '¿Confirma que desea añadir esta actualización?' },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const data = {
            paquete: this.paquete.id,
            estado,
            usermodify: this.authService.getUser().id
          };

          this.paquetesService.postPaqueteHistorial(this.paquete.id, data).subscribe((data: any) => {
            this.getHistoryPackagesEstado();
            this.openDialog('Actualización Añadida');
            this.ngOnInit();
          });
        }
      });
    } else {
      this.openDialog('No se puede cambiar el estado a un estado menor al actual');
    }
  }

  crearFactura(programar = false) {
    const data = {
      paquete: this.paquete.id,
      completada: true,
      logged_user: this.authService.getUser().id,
      codigo_promocional: '',
      programar,
      changeAccount: true,
    };
    if (this.factura) {
      data['lastInvoiceId'] = this.factura.id;
      data['gestion_compra_corporativa'] = this.factura.cargo_gestion_compra_corporativa;
      data['compra_corporativa'] = this.factura.cargo_compra_corporativa;
      data['cargo_impuestos'] = this.factura.cargo_impuestos;
      data['programar'] = true;
    }
    if (this.paquetesForm.value.codigo_promocional) {
      data.codigo_promocional = this.paquetesForm.value.codigo_promocional;
    }
    this.facturasService.crearFactura(data).subscribe((data: any) => {
      this.ngOnInit();
    }, (error: any) => {
      this.openDialog('Error al Generar Factura: ' + error.error);
    });
  }

  crearFactura3() {
    const data = {
      paquete: this.paquete.id,
      completada: true,
      logged_user: this.authService.getUser().id,
      codigo_promocional: '',
      programar: true
    };
    if (this.paquetesForm.value.codigo_promocional) {
      data.codigo_promocional = this.paquetesForm.value.codigo_promocional;
    }
    this.facturasService.crearFactura(data).subscribe((data: any) => {
      this.ngOnInit();
    }, (error: any) => {
      this.openDialog('Error al Generar Factura: ' + error.error);
    });
  }

  consolidarFactura(id) {
    this.facturaElectronicaService.emitInvoice(id, 1).subscribe((data: any) => {
      if (data['message']) {
        this.openDialog(data['message']);
        return;
      }
      if (data['Respuestas'][0]['Error'] == 'Exitoso') {
        this.ngOnInit();
        this.openDialog('Factura Emitida');
      } else {
        this.openDialog(data['Respuestas'][0]['Error']) + ' ' + data['Respuestas'][0]['Detalle'];
      }
    });
  }

  consolidarTicket(id) {
    this.facturaElectronicaService.emitInvoice(id, 4).subscribe((data: any) => {
      if (data['message']) {
        this.openDialog(data['message']);
        return;
      }
      if (data['Respuestas'][0]['Error'] == 'Exitoso') {
        this.ngOnInit();
        this.openDialog('Factura Emitida');
      } else {
        this.openDialog(data['Respuestas'][0]['Error']) + ' ' + data['Respuestas'][0]['Detalle'];
      }
    });
  }

  consultarFactura(id) {
    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Correo', value: this.paquete.usuario.email, type: 'general' },
      height: '250px',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const correo = result.value;
        this.facturaElectronicaService.resendInvoice(id, correo).subscribe((data: any) => {
          this.openDialog('Factura Enviada');
        });
      }
    });
  }

  anularFactura(id, tipo, flag = false) {
    return new Promise(async (resolve) => {
      const dialogRef = this.dialog.open(ConfirmarComponent, {
        // eslint-disable-next-line max-len
        data: { message: '¿Confirma que desea anular esta factura?' },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.facturasService.anularFactura(id, tipo).subscribe((data: any) => {
            this.openDialog('Factura Anulada');
            if (flag == false) {
              this.ngOnInit();
            } else {
              resolve(true);
            }
          });
        }
      });
    });
  }

  emitirFactura(id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      // eslint-disable-next-line max-len
      data: { message: '¿Confirma que desea modificar esta factura?' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const nota_credito = {
          factura: this.factura.id,
          descripcion: 'Cambio a Factura Electrónica Guia: ' + this.paquete.numero_de_guia,
          monto: this.factura.total,
          monto_crc: this.factura.factura_crc.total,
          tipo: 3,
          created_by: this.authService.getUser().id,
          parcial: 0,
          aplicada: false,
          user: this.factura.usuario.id
        };
        this.facturasService.postNotaCredito(nota_credito).subscribe((res: any) => {
          this.facturaElectronicaService.emitCreditNote(res.id, null).subscribe((data: any) => {
            const value = {
              generado_gti: false
            };
            this.facturasService.putFacturas(id, value).subscribe(() => {
              this.consolidarFactura(id);
            });
          }, (error) => {
            if (error.error['data']['Respuestas'][0]['Error'] == 'Exitoso') {
              const value = {
                generado_gti: false
              };
              this.facturasService.putFacturas(id, value).subscribe(() => {
                this.consolidarFactura(id);
              });
            } else {
              this.openDialog(error.error['data']['Respuestas'][0]['Error']);
            }
          });
        });
      }
    });
  }

  downloadFactura(id) {
    this.facturaElectronicaService.downloadInvoice(id).subscribe((data: any) => {
      if (+data.size === 0) {
        alert('Ha ocurrido un error generando el PDF');
        return;
      }
      const blob = new Blob([data], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    });
  }

  displayStores(value) {
    if (!value) {
      return '';
    }
    return value;
  }

  deleteFactura(key, id = this.paquete.id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: '¿Confirma que desea eliminar esta factura?' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (key) {
          case 'factura_1':
            this.paquetesForm.controls.factura_1.setValue(null);
            this.paquete.factura_1 = null;
            break;
          case 'factura_2':
            this.paquetesForm.controls.factura_2.setValue(null);
            this.paquete.factura_2 = null;
            break;
          case 'factura_3':
            this.paquetesForm.controls.factura_3.setValue(null);
            this.paquete.factura_3 = null;
            break;
          case 'imagen':
            this.paquetesForm.controls.imagen.setValue(null);
            this.paquete.imagen = null;
            break;
          default:
            break;
        }
        this.paquetesService.deletePaqueteFactura(id, key).subscribe((data: any) => {
          this.openDialog('Imagen de Factura Eliminada');
          if (id != this.paquete.id) {
            this.ngOnInit();
          }
        }),
          (err) => {
            console.error(err);
          };
      }
    });
  }

  goToConsolidates() {
    setTimeout(() => {
      this.staticTabs.tabs[2].active = true;
    }, 1000);
  }

  back() {
    if (this.originPage != '') {
      this.router.navigate(
        ['/admin/perfil'],
        { queryParams: { cuenta: this.paquete.usuario.id, origin: this.originPage } }
      );
    } else {
      this.navigation.back();
    }
  }
  generateInvoice(attribute: string) {
    if (this.factura != null) {
      if (this.factura.generado_gti == true) {
        const nota_credito = {
          factura: this.factura.id,
          descripcion: `${attribute} Mal Guia:` + this.paquete.numero_de_guia,
          monto: this.factura.total,
          monto_crc: this.factura.factura_crc.total,
          tipo: 3,
          created_by: this.authService.getUser().id,
          parcial: 0,
          aplicada: false,
          user: this.factura.usuario.id
        };
        if (this.factura.nota_debito != null) {
          if (this.factura.nota_debito.anulada == false) {
            this.deleteNotaDebito(this.factura.nota_debito).then(() => {
              this.facturasService.postNotaCredito(nota_credito).subscribe((res: any) => {
                const nd = {
                  nota_credito: res.id
                };
                this.facturasService.putFacturas(this.factura.id, nd).subscribe(() => {
                  this.facturaElectronicaService.emitCreditNote(res.id, 0).subscribe((data: any) => {
                    this.paquetesService.put(this.paquete.id, { factura: 0, logged_user: this.authService.getUser().id }).subscribe(() => {
                      this.paquetesService.deleteEntrega(this.paquete.entrega.id).subscribe((data: any) => {
                        this.openDialog(`${attribute} Modificado`);
                        this.actualizarPaquete();
                      });
                    });
                  });
                });
              });
            });

          } else {
            this.facturasService.postNotaCredito(nota_credito).subscribe((res: any) => {
              const nd = {
                nota_credito: res.id
              };
              this.facturasService.putFacturas(this.factura.id, nd).subscribe(() => {
                this.facturaElectronicaService.emitCreditNote(res.id, 0).subscribe((data: any) => {
                  this.paquetesService.put(this.paquete.id, { factura: 0, logged_user: this.authService.getUser().id }).subscribe(() => {
                    this.paquetesService.deleteEntrega(this.paquete.entrega.id).subscribe((data: any) => {
                      this.openDialog(`${attribute} Modificado`);
                      this.actualizarPaquete();
                    });
                  });
                });
              });
            });
          }

        } else {
          this.facturasService.postNotaCredito(nota_credito).subscribe((res: any) => {
            const nd = {
              nota_credito: res.id
            };
            this.facturasService.putFacturas(this.factura.id, nd).subscribe(() => {
              this.facturaElectronicaService.emitCreditNote(res.id, 0).subscribe((data: any) => {
                this.paquetesService.put(this.paquete.id, { factura: 0, logged_user: this.authService.getUser().id }).subscribe(() => {
                  if (this.paquete.entrega) {
                    this.paquetesService.deleteEntrega(this.paquete.entrega.id);
                  }
                  this.openDialog(`${attribute} Modificado`);
                  this.actualizarPaquete();
                });
              });
            });
          });
        }

      } else {
        this.facturasService.putFacturas(this.factura.id, { anulado: true }).subscribe(() => {
          this.paquetesService.put(this.paquete.id, { factura: 0, logged_user: this.authService.getUser().id }).subscribe(() => {
            if (this.paquete.entrega) {
              this.paquetesService.deleteEntrega(this.paquete.entrega.id);
            }
            this.openDialog(`${attribute} Modificado`);
            this.actualizarPaquete();
          });
        });
      }

    } else {
      if (this.paquete.entrega) {
        this.paquetesService.deleteEntrega(this.paquete.entrega.id);
      }
      this.openDialog(`${attribute} Modificado`);
      this.actualizarPaquete();
    }
  }

  changeWeigthPackage() {
    if (this.paquete.peso != this.paquetesForm.value.peso && this.paquete.peso != null && this.paquetesForm.value.peso != null && this.editMode == true) {
      const dialogRef = this.dialog.open(ConfirmarComponent, {
        // eslint-disable-next-line max-len
        data: { message: '¿Confirma que desea modificar el peso? Se anularan las facturas, notas de crédito, notas de débito que estén vinculadas a este paquete y se generara una nueva factura.' },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinnerService.show();
          this.paquete.peso = this.paquetesForm.value.peso;
          this.generateInvoice('Peso');
        } else {
          this.paquetesForm.patchValue({
            usuario: { id: this.paquete.usuario.id, name: this.usuariosService.get_account_number(this.paquete.usuario) + '-' + this.usuariosService.get_fullname(this.paquete.usuario), tarifaA: this.paquete.usuario.tarifaA, tarifaM: this.paquete.usuario.tarifaM }
          });
        }
      });
    }
  }

  changeAccount() {
    if (this.paquete.usuario != this.paquetesForm.value.usuario && this.paquete.usuario != null && this.paquetesForm.value.usuario != null && this.editMode == true) {
      const dialogRef = this.dialog.open(ConfirmarComponent, {
        // eslint-disable-next-line max-len
        data: { message: '¿Confirma que desea modificar la cuenta? Se anularan las facturas, notas de crédito, notas de débito que estén vinculadas a este paquete y se generara una nueva factura.' },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinnerService.show();
          this.paquete.usuario = this.paquetesForm.value.usuario;
          this.changePlan(this.paquetesForm.value.plan);
          this.generateInvoice('Cuenta');
        } else {
          this.paquetesForm.patchValue({
            usuario: { id: this.paquete.usuario.id, name: this.usuariosService.get_account_number(this.paquete.usuario) + '-' + this.usuariosService.get_fullname(this.paquete.usuario), tarifaA: this.paquete.usuario.tarifaA, tarifaM: this.paquete.usuario.tarifaM }
          });
        }
      });
    }
  }

  changeSelect(event) {
    if (event == 'SobreBolsa') {
      this.paquetesForm.controls['precio'].setValue(10);
      this.paquetesForm.controls['ancho'].setValue(1);
      this.paquetesForm.controls['alto'].setValue(1);
      this.paquetesForm.controls['largo'].setValue(1);
      this.paquetesForm.controls['peso'].setValue('');
      this.paquetesForm.patchValue({ descripcion: 'BOLSA' });

    } else {
      if (this.paquetesForm.value.descripcion.startsWith('BOLSA')) {
        this.paquetesForm.patchValue({ descripcion: '' });
        this.paquetesForm.controls['alto'].setValue('');
        this.paquetesForm.controls['largo'].setValue('');
        this.paquetesForm.controls['ancho'].setValue('');
        this.paquetesForm.controls['peso'].setValue('');
      }
    }
    this.calcularMedidas();
    this.calcularPesoCobrable();
  }

  getEmpresas() {
    this.tarifasService.getEmpresas().subscribe((data: any) => {
      this.empresasTransporte = data;
    });
  }

  changePlan(plan) {
    if (this.paquete.usuario) {
      this.getTarifas().then(() => {
        this.tarifas = this.tarifas.filter(f => f.codigo === plan);
        if (plan === 'A') {
          this.paquetesForm.controls.tarifa.setValue(this.paquete.usuario?.tarifaA);
        }
        if (plan === 'M') {
          this.paquetesForm.controls.tarifa.setValue(this.paquete.usuario?.tarifaM);
        }
        if (plan === 'C') {
          this.paquetesForm.controls.tarifa.setValue(this.paquete.usuario?.tarifaA);
        }
      });

    } else {
      this.openDialog('No se puede cambiar el plan sin antes escoger una cuenta');
    }
  }

  deleteConsolidado(id) {
    if (this.paquete.estado_actual < 5 || (this.paquete.orden)) {
      const dialogRef = this.dialog.open(ConfirmarComponent, {
        data: { message: '¿Confirma que desea eliminar este paquete del consolidado?.' },
      });

      dialogRef.afterClosed().subscribe(result => {
        const estado_actual = this.paquete.orden ? 12 : 4;
        if (result) {
          const paquete = {
            id,
            estado_actual,
            paquete_contenedor: 'False',
            consolidado: false,
            logged_user: this.authService.getUser().id,
            bulto: null
          };

          this.paquetesService.put(id, paquete).subscribe((data: any) => {
            this.openDialog('Paquete Eliminado');
            this.ngOnInit();
          }),
            (err) => {
              console.error(err);
            };
        }
      });
    } else {
      this.openDialog('No se puede eliminar este paquete del consolidado');
    }
  }

  UpdateKg(value: number) {
    const kg = (value * 0.453592);
    this.pesoRealKg = isNaN(kg) ? '0' : kg.toFixed(2);;
  }

  UpdateKgTotal(value: number) {
    const kg = (value * 0.453592);
    this.pesoTotalKg = isNaN(kg) ? '0' : kg.toFixed(2);
  }

  getTipoCambio() {
    this.tipoCambioService.ultimo_cambio().subscribe((data: any) => {
      this.tipoCambio = data;
    });
  }

  checkGuia(guia) {
    this.paquetesService.findTrackingOrGuia(guia).subscribe((data: any) => {
      if (data.length > 0) {
        this.openDialog('Número de Guía Existente');
        this.paquetesForm.controls.numero_de_guia.setValue(this.paquete.numero_de_guia);
      };
    });
  }

  agregarEmbarque() {
    const dialogRef = this.dialog.open(GeneralInputComponent, {
      data: { title: 'Seleccione Embarque', value: 0, type: 'embarques', full: true },
      height: '250px',
      width: '750px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const item = {
          paquetes: [{ id: this.paquete.id }],
          idEmbarque: result.value
        };
        this.paquetesService.addToShipment(item).subscribe(
          (data: any) => {
            this.openDialog('Paquete agregado a embarque');
            this.ngOnInit();
          },
          (err: any) => {
            this.openDialog('Error al agregar a embarque: ' + err.error);
          });
      }
    });
  }

  marcarFacturado() {
    if (this.nrocomprobante1D.length > 0 && this.montoComprobante1DUSD.length > 0 && this.imageF1D.nativeElement.src) {
      const comprobanteForm = this.fb.group({
        paquete: [this.paquete.id],
        factura: [this.paquete.factura.id],
        referencia: [this.nrocomprobante1D],
        monto: [this.montoComprobante1DUSD],
        monto_crc: [this.montoComprobante1DCRC],
        comprobante1: [this.fileLoad1D],
        comprobante2: [this.fileLoad2D],
        aprobado: [true],
        aprobado_by: [this.authService.getUser().id],
        created_by: [this.authService.getUser().id]
      });
      this.pagosService.postFacturaD(comprobanteForm.value).subscribe((res: any) => {
        const paquete = {
          estado_factura: 1,
          estado_pago: 0,
          referencia_pago: this.nrocomprobante1D,
          logged_user: this.authService.getUser().id,
        };
        this.paquetesService.put(this.paquete.id, paquete).subscribe((res: any) => {
          this.paquete.estado_factura = 1;
          this.paquete.estado_pago = 0;
          this.paquete.referencia_pago = this.nrocomprobante1D;
          this.openDialog('Paquete Facturado');
        });
      });

    } else {
      this.openDialog('Debe completar todos los datos');
    }
  }

  setFOB(fob) {
    this.fob = fob;
    this.paquetesForm.controls.precio.setValue(this.fob);
  }

  processFile1D(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileLoad1D = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const size = file.size / 1024;
        if (size <= 20480) {
          if (file.name.includes('.docx') || file.name.includes('.pdf') || file.name.includes('.doc')) {
            this.viewFile1D = true;
          } else {
            if (file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png')) {
              this.viewFile1D = false;
              setTimeout(() => {
                this.imageF1D.nativeElement.src = reader.result;
              }, 500);
              this.editF1D = true;
            } else {
              this.openDialog('Formato de archivo inválido');
              this.fileLoad1D = null;
            }
          }
        } else {
          this.openDialog('Archivo supera los 20 megabytes');
          this.fileLoad1D = null;
        }
      };
    }
  }

  processFile2D(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileLoad2D = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const size = file.size / 1024;
        if (size <= 20480) {
          if (file.name.includes('.docx') || file.name.includes('.pdf') || file.name.includes('.doc')) {
            this.viewFile2D = true;
          } else {
            if (file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png')) {
              this.viewFile2D = false;
              setTimeout(() => {
                this.imageF2D.nativeElement.src = reader.result;
              }, 500);
              this.editF2D = true;
            } else {
              this.openDialog('Formato de archivo inválido');
              this.fileLoad2D = null;
            }
          }
        } else {
          this.openDialog('Archivo supera los 20 megabytes');
          this.fileLoad2D = null;
        }
      };
    }
  }

  convertCurrency(from) {
    if (from === 'USD') {
      this.montoComprobante1DCRC = (Number(this.montoComprobante1DUSD) * this.factura.tipo_de_cambio.value).toFixed(2);
    }
    if (from === 'CRC') {
      this.montoComprobante1DUSD = (Number(this.montoComprobante1DCRC) / this.factura.tipo_de_cambio.value).toFixed(2);
    }
  }

  public deleteNotaDebito(notaDebito) {
    return new Promise(async (resolve) => {
      const notaCredito = {
        user: this.factura.usuario.id,
        factura: this.factura.id,
        descripcion: 'Anula Nota de Débito ' + notaDebito.id,
        created_by: this.authService.getUser().id,
        monto: notaDebito.monto,
        monto_crc: notaDebito.monto_crc,
        aplicada: false,
        parcial: 0
      };
      this.facturasService.postNotaCredito(notaCredito).subscribe((res: any) => {
        const data = {
          nota_credito: res.id
        };
        this.facturasService.putFacturas(this.factura.id, data).subscribe(() => {
          this.facturaElectronicaService.emitCreditNote(res.id, res.parcial + 1).subscribe((data: any) => {
            resolve(true);
          });
        });
      });
    });
  }

  adjuntarPagoNota(notaDebito) {
    const dialogRef = this.dialog.open(AdjuntarPagoComponent, {
      data: { title: 'Adjuntar Pago', origin: 'admin', monto_usd: notaDebito.monto, monto_crc: notaDebito.monto_crc, tipo_cambio: 0 },
      height: '350px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const data = {
          metodo_pago: result.metodo_pago,
          monto: notaDebito.monto + notaDebito.iva_usd,
          monto_crc: parseFloat(notaDebito.monto_crc + notaDebito.iva_crc).toFixed(2),
          usuario: notaDebito.user.id,
          created_by: this.authService.getUser().id,
          referencia: result.referencia
        };
        this.pagosService.procesarPago(data).subscribe((pago: any) => {
          if (pago) {
            const data1 = {
              factura: notaDebito.factura.id,
              created_by: this.authService.getUser().id,
              pago: pago.id,
              user: notaDebito.user.id,
            };
            this.facturasService.putNotaDebito(notaDebito.id, data1).subscribe((res1: any) => {
              this.openDialog('Pago Registrado');
              this.ngOnInit();
              notaDebito = res1;
            });
          }
        });
      }
    });
  }
  asignarSucursal(event) {
    if (event.value === 'Guadalupe') {
      const latitude = 9.951436;
      const longitude = -84.055484;
      const address = '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica';
      this.formEntrega.patchValue({
        provincia: 1,
        canton: 8,
        distrito: 54,
        direccion: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        direccion_entrega: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        direccion_entrega_apps: JSON.stringify({ lat: latitude, lng: longitude, address }),
        sucursal: 'Guadalupe',
        ruta: 72
      });
    }

    if (event.value === 'Limón') {
      const latitude = 9.992673;
      const longitude = -83.026042;
      const address = 'XXVF+2HW, C. 1, Limón, Costa Rica';

      this.formEntrega.patchValue({
        provincia: 7,
        canton: 76,
        distrito: 448,
        direccion: 'XXVF+2HW, C. 1, Limón, Costa Rica',
        direccion_entrega: 'XXVF+2HW, C. 1, Limón, Costa Rica',
        detalles_adicionales: 'Frente al Parque Vargas continuo a la entrada de Camiones Trali. Planta baja, Edificio Cobre y Acero.',
        direccion_entrega_apps: JSON.stringify({ lat: latitude, lng: longitude, address }),
        sucursal: 'Limón',
        ruta: 48
      });
    }
  }

  getProvincias() {
    this.usuariosService.getProvincias().subscribe(data => {
      this.provincias = data;
    });
  }

  getCantones(idCanton) {
    this.usuariosService.getCantones(idCanton).subscribe(data => {
      this.cantones = data;
    });
  }

  getDistritos(idProvincia, idCanton) {
    this.usuariosService.getDistritos(idProvincia, idCanton).subscribe(data => {
      this.distritos = data;
    });
  }

  cambiarTipoZona(id) {
    const selected = this.distritos.filter(e => e.id == id);
    this.formEntrega.controls.tipo_zona_entrega.setValue(selected[0].tipo);
  }

  attachPayment(payment) {
    const dialogRef = this.dialog.open(AttachPaymentComponent, {
      data: {
        paymentId: payment.id,
        title: 'Vincular Pago',
        monto_crc: payment.monto_crc.toFixed(2),
        packageGuide: this.paquete.numero_de_guia,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // codRespuesta es 0 si se vincula correctamente
        if (+result.codRespuesta == 0) {
          this.openDialog('Pago Vinculado correctamente');
          this.ngOnInit();
        } else {
          this.openDialog('Error al vincular el pago');
        }
      }
    });
  }

  deletePago(id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      // eslint-disable-next-line max-len
      data: { message: 'Esta operación es definitiva y no puede reversarse. ¿Confirma que desea eliminar este pago?.' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pagosService.deletePago(id).subscribe(() => {
          this.openDialog('Pago Eliminado');
          this.ngOnInit();
        });
      }
    });
  }

  previousPackages() {
    this.selectedIndex -= 1;
    if (this.selectedIndex <= 0) {
      this.selectedIndex = 0;
    }
    this.router.navigateByUrl('admin/paquetes/paquete?pack=' + this.guias[this.selectedIndex].id);

  }

  nextPackages() {
    this.selectedIndex += 1;
    if (this.selectedIndex >= this.length) {
      this.selectedIndex = this.length - 1;
    }
    this.router.navigateByUrl('admin/paquetes/paquete?pack=' + this.guias[this.selectedIndex].id);
  }

  insertPackagesAdditional() {
    const dialogRef = this.dialog.open(AppPackageComponent, {
      data: {
        paquete: this.paquete.id,
        guia: this.paquete.numero_de_guia,
        tracking: null,
        peso: null,
        alto: null,
        ancho: null,
        largo: null,
        peso_total: null,
        numero_de_guia: null,
        peso_volumetrico: null,
        bulto: null,
        tarifa: this.paquete.tarifa.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const item = {
          id: this.bultos.length + 2,
          guia: this.paquete.numero_de_guia + '-' + (this.bultos.length + 2),
          tracking: result.tracking,
          peso: result.peso,
          alto: result.alto,
          ancho: result.ancho,
          largo: result.largo,
          peso_total: result.peso_total,
          peso_volumetrico: result.peso_volumetrico,
          adicional: true,
          bulto: this.bultos.length + 2,
          escaneado_embarque: false,
        };
        this.bultos.push(item);
        const PesoBultos = this.bultos.reduce((accumulator, object) => accumulator + object.peso_total, 0);
        this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso'].value + PesoBultos);
        this.dataSourceAdicionales = [...this.bultos];
        this.displayedColumnsAdicionales = ['tracking', 'peso', 'alto', 'ancho', 'largo', 'peso_total', 'peso_volumetrico', 'eliminar'];

      } else {
        this.dataSourceAdicionales = [...this.bultos];
      }
    });
  }

  updatePackagesAdditional(element) {
    const dialogRef = this.dialog.open(AppPackageComponent, {
      data: {
        paquete: this.paquete.id,
        guia: this.paquete.numero_de_guia,
        id: element.id,
        tracking: element.tracking,
        peso: element.peso,
        alto: element.alto,
        ancho: element.ancho,
        largo: element.largo,
        peso_total: element.peso_total,
        peso_volumetrico: element.peso_volumetrico,
        bulto: element.bulto,
        tarifa: this.paquete.tarifa.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const i = this.bultos.map(function (x) { return x.id; }).indexOf(element.id);
        if (i >= 0) {
          this.bultos[i] = result;
          this.dataSourceAdicionales = [...this.bultos];
          const PesoBultos = this.bultos.reduce((accumulator, object) => accumulator + object.peso_total, 0);
          this.paquetesForm.controls['peso_total'].setValue(this.paquetesForm.controls['peso'].value + PesoBultos);
          this.UpdateKgTotal(this.paquetesForm.controls['peso_total'].value);
        }
      } else {
        this.dataSourceAdicionales = [...this.bultos];
      }

    });
  }

  adjuntarPagoParcial() {
    const dialogRef = this.dialog.open(AdjuntarPagoComponent, {
      data: { title: 'Adjuntar Pago', origin: 'admin', monto_usd: this.factura.factura_usd.monto_pendiente, monto_crc: this.factura.factura_crc.monto_pendiente, tipo_cambio: this.factura.tipo_de_cambio.value },
      height: '450px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result != null) {
        const monto = result.monto_usd;
        const monto_crc = result.monto_crc;
        await this.procesarPago(result.metodo_pago, result.referencia, monto, monto_crc, this.paquete.usuario.id, this.authService.getUser().id);
        this.openDialog('Pago Adjuntado');
      }
    });
  }

  generatePDFGuias() {
    const paquetes = [];
    let total = this.paquete.paquete_contenedor.length > 0 ? this.paquete.paquete_contenedor.filter(item => item.adicional === true).length > 0 ? this.paquete.paquete_contenedor.filter(item => item.adicional === true).length : 1 : 1;
    if (total == 1) {
      total = this.bultos.length > 0 ? this.bultos.length : 1;
    }
    const value = {
      remitente: this.paquete.remitente,
      created_at: this.paquete.created_at,
      destinatario: this.usuariosService.get_fullname(this.paquete.usuario),
      numero_de_guia: this.paquete.numero_de_guia,
      descripcion: this.paquete.descripcion,
      pesolb: this.paquete.peso_total.toFixed(2),
      pesokg: (this.paquete.peso_total * 0.453592).toFixed(2),
      cantidad: this.paquete.cantidad,
      precio: this.paquete.precio,
      updated_at: this.paquete.updated_at,
      cuenta: this.paquete.usuario.cuenta,
      id: this.paquete.id,
      direccion: this.paquete.usuario.direccion,
      telefono: this.paquete.usuario.telefono_movil,
      bultos: total
    };
    paquetes.push(value);
    this.embarquePaquetes = paquetes;
    this.showPortal = true;
  }

  closeViewer(event) {
    this.showPortal = false;
  }

  editChangeCurrency() {
    this.facturasService.putFacturas(this.factura.id, { tipo_de_cambio: this.currentRate }).subscribe((data: any) => {
      console.log(data);
      this.editarTipoCambio = false;
      this.ngOnInit();
    });
  }

  syncTaxes() {
    if (this.loadingTaxes) {
      return;
    }
    this.loadingTaxes = true;
    this.delmanService.putPackageTaxes(this.paquete.numero_de_guia).subscribe((data: any) => {
      this.openDialog(`Impuestos del paquete ${this.paquete.numero_de_guia} actualizados exitosamente`);
      this.loadingTaxes = false;
      this.ngOnInit(); // Reload once the taxes are mapped and the invoice is regenerated
    }, err => {
      console.error(err);
      this.loadingTaxes = false;
      this.openDialog(err.error ? err.error.error : 'Error al actualizar impuestos');
    });
  }

  invalidSync(): boolean {
    if (!this.paquete) { return false; };
    if (this.paquete.charge_exempt) { return true };
    if (+this.paquete.estado_actual < 9) { return true; };
    return +this.paquete.estado_actual === 18;
  }

  printPdfPackage(): void {
    this.pdfService.generateInvoicePdf([this.paquete], `F${this.paquete.id}`);
  }

  exemptCharge() {
    this.paquetesService.exemptCharges([this.paquete.id]).subscribe(() => {
      this.openDialog('Cargo de Impuestos exento exitosamente');
      this.ngOnInit();
    }, err => {
      console.error(err);
    });
  }

  isOrder() {
    const order = this.paquete.paquete_principal.length > 0 ? this.paquete.paquete_principal[0] : null;
    return order ? order.numero_de_guia.startsWith('O-') : false;
  }

  private replaceBlankWithNull() {
    this.paquetesForm.value.tracking == '' ? this.paquetesForm.controls.tracking.setValue(null) : '';
    this.paquetesForm.value.codigo_promocional == '' ? this.paquetesForm.controls.codigo_promocional.setValue(null) : '';
    this.paquetesForm.value.descripcion == '' ? this.paquetesForm.controls.descripcion.setValue(null) : '';
    this.paquetesForm.value.remitente == '' ? this.paquetesForm.controls.remitente.setValue(null) : '';
    this.paquetesForm.value.referencia == '' ? this.paquetesForm.controls.referencia.setValue(null) : '';
    this.paquetesForm.value.precio == '' ? this.paquetesForm.controls.precio.setValue(null) : '';
    this.paquetesForm.value.destinatario == '' ? this.paquetesForm.controls.destinatario.setValue(null) : '';
    this.paquetesForm.value.medida == '' ? this.paquetesForm.controls.medida.setValue(null) : '';
    this.paquetesForm.value.observacion == '' ? this.paquetesForm.controls.observacion.setValue(null) : '';
    this.paquetesForm.value.largo == '' ? this.paquetesForm.controls.largo.setValue(null) : '';
    this.paquetesForm.value.alto == '' ? this.paquetesForm.controls.alto.setValue(null) : '';
    this.paquetesForm.value.ancho == '' ? this.paquetesForm.controls.ancho.setValue(null) : '';
    this.paquetesForm.value.peso == '' ? this.paquetesForm.controls.peso.setValue(null) : '';
    this.paquetesForm.value.peso_volumetrico == '' ? this.paquetesForm.controls.peso_volumetrico.setValue(null) : '';
    this.paquetesForm.value.peso_total == '' ? this.paquetesForm.controls.peso_total.setValue(null) : '';
    this.paquetesForm.value.factura_1 == '' ? this.paquetesForm.controls.factura_1.setValue(null) : '';
    this.paquetesForm.value.factura_2 == '' ? this.paquetesForm.controls.factura_2.setValue(null) : '';
    this.paquetesForm.value.factura_3 == '' ? this.paquetesForm.controls.factura_3.setValue(null) : '';
    this.paquetesForm.value.imagen == '' ? this.paquetesForm.controls.imagen.setValue(null) : '';
  }

  private resetForm() {
    this.paquetesForm.reset();
  }

  private _filter(value: any, filter, options: any): ClienteElement[] {
    if (!filter) {
      return options;
    }
    let filterValue: string;
    value.name ? filterValue = value.name.toLowerCase() : filterValue = filter;
    return options.filter(user => user.name.toLowerCase().includes(filterValue.toLocaleLowerCase()));
  }

  private _filterStore(value: string) {
    const filterValue = value.toLowerCase();
    return this.common_stores.filter(option => option.toLowerCase().includes(filterValue));
  }


}
