/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModoDeEntrega } from 'src/app/models/Usuario';
import { AuthService } from 'src/app/services/auth.service';
import { MustMatch } from '../../../../shared/validators/must-match.validator';
import { environment } from './../../../../../environments/environment';

import { AutorizacionComponent } from '../../dialogs/autorizacion/autorizacion.component';

import { RutasService } from 'src/app/services/rutas.service';
import { ConfirmarComponent } from '../../dialogs/confirmar/confirmar.component';
import { EditAuthorizationsComponent } from '../../dialogs/edit-authorizations/edit-authorizations.component';

import { MapsAPILoader } from '@agm/core';
import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Sort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CardComponent } from 'src/app/modules/user/components/card/card.component';
import { EntregasService } from 'src/app/services/entregas.service';
import { FacturasService } from 'src/app/services/facturas.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PagosService } from 'src/app/services/pagos.service';
import { PaquetesService } from 'src/app/services/paquetes.service';
import { TarifasService } from 'src/app/services/tarifas.service';
import { TranslateService } from 'src/app/services/translate.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { AdjuntarPagoComponent } from '../../dialogs/adjuntar-pago/adjuntar-pago.component';
import { GeneralDialogComponent } from '../../dialogs/general-dialog/general-dialog.component';
import { GeneralInputComponent } from '../../dialogs/general-input/general-input.component';
import { NumericDirective } from '../../../../shared/pipes/numeric.pipe';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit, OnDestroy {
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  @ViewChild('tipoClienteInput') tipoClienteInput: ElementRef<HTMLInputElement>;

  mediaUrl = environment.mediaUrl;
  length: number;
  pageSize = 10;
  packagePageSize = 10;
  disabledTime = '';
  pageIndex = 1;
  dismis;
  editarInfo = false;
  editarInfoContacto = false;
  editarInfoEntrega = false;
  editarInfoAdministrativo = false;
  editarInfoFactura = false;
  statusPaymentFilter = '0';
  packagesList: any[] = [];
  dvResult: any;


  displayedColumns2: string[] = ['nombre', 'cedula', 'email', 'telefono', 'tipo_autorizacion'];

  tiposDeAutorizacion = ['Completa', 'Pago y Retiro', 'Retiro'];
  por_pagar: any = 0;
  por_pagar_colones: any = 0;
  authorizations = [];

  cards = [];
  formUser = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    email_2: ['', [Validators.email]],
    password: [''],
    confirmPassword: [''],
    tipo_de_cedula: [''],
    nombre: ['', [Validators.required]],
    primer_apellido: ['', [Validators.required]],
    segundo_apellido: [''],
    numero_de_cedula: [''],
    fecha_de_nacimiento: ['', []],
    telefono_movil: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
    telefono_casa: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
    telefono_oficina: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
    direccion: [''],
    genero: [''],
    compro_en_internet: [''],
    metodo_de_captacion: [''],
    razon_social: [''],
    cedula_juridica: [''],
    representante_legal: [''],
    referido_id: [''],
    cuenta: ['', Validators.required]
  }, {
    validator: MustMatch('password', 'confirmPassword')
  });

  existing_email: boolean;
  formAutorizaciones = this.fb.group({
    nombre: ['', [Validators.required, Validators.max(30)]],
    cedula: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    telefono: ['', [Validators.required]],
    relacion: ['', [Validators.required, Validators.max(30), Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ]+$')]],
    tipo_autorizacion: ['', [Validators.required, Validators.max(30), Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ]+$')]],
    email: ['', [Validators.required, Validators.email]],
    activo: ['', [Validators.required]],
    enviar_correo: ['', [Validators.required]],
  });

  formContacto = this.fb.group({
    telefono_movil: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
    telefono_casa: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
    telefono_oficina: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
    direccion: ['', [Validators.required]],
    canton: ['', [Validators.required]],
    distrito: ['', [Validators.required]],
    provincia: ['', [Validators.required]],
  });

  formEntrega = this.fb.group({
    modo_de_entrega: ['', [Validators.required]],
    direccion_entrega: [''],
    detalles_adicionales: [''],
    direccion_entrega_apps: [''],
    ruta: [''],
    sucursal: [''],
    encomienda: [''],
    metodo_pago: [''],
    direccion: [''],
    canton: [''],
    distrito: [''],
    provincia: [''],
    autoriza_entrega: ['']
  });
  formEntregaChanged = false;

  notifications: any;
  formNotificaciones = this.fb.group({
    arribo_M: [],
    n_transito: [],
    arribo_CR: [],
    n_entrega: [],
    n_email: [],
    n_telefono: [],
    n_whatsapp: [],
    n_push: [],
  });

  formAdministrativo = this.fb.group({
    rol: [],
    tipo_cliente: [],
    is_active: [],
    anotacion: [],
    anotacion_owner: [],
    anotacion_fecha: [],
    tarifaA: [],
    tarifaM: [],
    pago_tarjeta: [],
    vendedor: [],
    enable: [],
    alianza: [],
    preferir_factura: [],
    exento_iva: [],
    charge_exempt: [],
  });

  formFactura = this.fb.group({
    nombre: [''],
    primer_apellido: [''],
    segundo_apellido: [''],
    numero_identificacion: ['', [Validators.minLength(9), Validators.maxLength(13)]],
    razon_social: [''],
    tipo_factura: [''],
    direccion: [''],
    canton: [''],
    distrito: [''],
    provincia: [''],
    telefono: ['', Validators.pattern('^[0-9]*$')],
    correo: [''],
  });

  tiposTarjetas = ['Visa', 'Mastercard'];

  deliveryModes: any[] = [
    { name: 'En domicilio', value: ModoDeEntrega.enDomicilio },
    { name: 'En sucursal', value: ModoDeEntrega.enSucursal, },
    { name: 'Fuera del Área Metropolitana', value: ModoDeEntrega.areaMetropolitana, },
  ];

  roles: any[] = [
    { name: 'Administrador', value: 'ADMIN' },
    { name: 'Cliente', value: 'CLIENT' },
    { name: 'Transportista', value: 'TRANSP' },
    { name: 'Almacen Miami', value: 'ALMAC' },
    { name: 'Servicio al Cliente', value: 'SERVCLI' },
    { name: 'Pedimentación', value: 'PEDIM' },
    { name: 'Operaciones Miami', value: 'OPMIA' },
    { name: 'Operaciones Costa Rica', value: 'OPCR' },
    { name: 'Vendedor', value: 'VEND' },
    { name: 'Contabilidad', value: 'CONTAB' },
    { name: 'Operaciones Costa Rica 2', value: 'OPCR2' },
  ];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  allTipoClientes: any[] = [];
  tipoClientes: any[] = [];
  tipoCtrl = new FormControl();
  filteredTipos: Observable<[]>;

  estadosCliente: any[] = [
    { name: 'Activo', value: true },
    { name: 'Inactivo', value: false }
  ];

  generos = ['M', 'F'];
  user;

  // Rutas
  rutas = [];
  allRutas = [];

  id: number;

  latitude: number;
  longitude: number;
  zoom = 8;
  address: string;

  minDate = new Date();

  provincias;
  cantones;
  distritos;
  filter: any;
  metodosPagos;
  metodosPagoPreviaEntrega;
  metodosPagoContraEntrega;

  selectedTabIndex = 0;

  displayedColumnsPrealerts: string[] = [];
  dataSourcePrealerts = [];

  displayedColumnsPackages: string[] = [];
  dataSourcePackages = [];

  displayedColumnsBills: string[] = [];
  dataSourceBills = [];

  displayedColumnsDelivers: string[] = [];
  dataSourceDelivers = [];

  displayedColumnsNotasCredito: string[] = [];
  dataSourceNotasCredito = [];

  displayedColumnsNotasDebito: string[] = [];
  dataSourceNotasDebito = [];

  displayedColumnsCards: string[] = ['nombre_tarjeta', 'tipo_tarjeta', 'numero_tarjeta', 'estado', 'opciones'];
  dataSourceCards = [];

  displayedColumnsReferidos: string[] = [];
  dataSourceReferidos = [];

  dataSourceOrders = [];

  anotacionChange = false;

  tarifasAereas;
  tarifasMaritimas;

  prealertados = 0;
  paquetesClientes = 0;
  facturasPendientes = 0;
  pagosRealizados = 0;
  entregasProgramadas = 0;
  notasCredito = 0;
  notasDebito = 0;

  selectedPackages = new SelectionModel<any>(true, []);
  paquetesPagos = [];
  paquetesEntregas = [];

  email_1: any;
  email_2: any;

  filterSelected = 1;

  twofaActive = false;

  empresasTransporte;

  vendedores;

  qrInfo: any;
  secretKey: any;

  packagesToPay = [];

  totalSeleccionadoUSD = 0;
  totalSeleccionadoCRC = 0;

  alianzas;

  someSubscription: any;

  changePassword = false;

  selectedIds = new SelectionModel<any>(true, []);

  private geoCoder;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    public authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private rutasService: RutasService,
    private mapsAPILoader: MapsAPILoader,
    public usuariosService: UsuariosService,
    public datePipe: DatePipe,
    private spinnerService: NgxSpinnerService,
    private tarifasService: TarifasService,
    private paquetesService: PaquetesService,
    private entregasService: EntregasService,
    private facturasService: FacturasService,
    private pagosService: PagosService,
    private paqueteService: PaquetesService,
    private navigation: NavigationService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.someSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Here is the dashing line comes in the picture.
        // You need to tell the router that, you didn't visit or load the page previously, so mark the navigated flag to false as below.
        this.router.navigated = false;
      }
    });

    this.route.queryParamMap.subscribe((params) => {
      this.id = params['params']['cuenta'] !== undefined ? params['params']['cuenta'] : JSON.parse(localStorage.getItem('user')).id;

      if (params['params']['origin']) {
        switch (params['params']['origin']) {
          case 'entregaInfo':
            this.selectedTabIndex = 2;
            break;
          case 'prealertas':
            this.selectedTabIndex = 2;
            break;
          case 'paquetes':
            this.selectedTabIndex = 3;
            break;
          case 'entregas':
            this.selectedTabIndex = 4;
            break;
          case 'notacredito':
            this.selectedTabIndex = 5;
            break;
          case 'notadebito':
            this.selectedTabIndex = 6;
            break;
          default:
            this.selectedTabIndex = 1;
            break;
        }
        setTimeout(() => {
          this.staticTabs.tabs[this.selectedTabIndex].active = true;
        }, 1000);

      }

      if (params['params']['pass']) {
        setTimeout(() => {
          this.staticTabs.tabs[this.selectedTabIndex].active = true;
        }, 100);

        this.editarInfo = true;
        this.changePassword = true;
      }

      if (authService.isVendedor) {
        this.displayedColumns2 = ['nombre', 'cedula', 'email', 'telefono', 'tipo_autorizacion'];
      }

    });

  }

  ngOnDestroy() {
    if (this.someSubscription) {
      this.someSubscription.unsubscribe();
    }
  }

  back() {
    this.navigation.back();
  }
  checkEmail(email, field) {

    if (this.formUser.controls[field].value) {

      if (this.formUser.controls['email'].value === this.formUser.controls['email_2'].value) {
        this.formUser.controls[field].setErrors({ equal: true });

      } else {
        this.usuariosService.checkUserEmail(email).subscribe((data) => {
          this.existing_email = data['exists'];

          if (this.existing_email) {
            this.formUser.controls[field].setErrors({ exists: this.existing_email });
          } else {
            this.formUser.controls[field].setErrors(null);
          }
        });
      }
    }
  }

  getPaquetes() {
    this.spinnerService.show();
    this.paquetesService.getByUserStatus(this.authService.getUser().id, this.pageIndex, this.filterSelected).subscribe((res: any) => {
      const paquetes_lista: any[] = [];
      const resData = res.results || [];
      resData.forEach((element, index, array) => {
        let estado: any;
        if (element.estado_actual.visualizacion) {
          estado = element.estado_actual;
        } else {
          for (let index = element.estado_historial.length - 1; index >= 0; index--) {
            if (element.estado_historial[index].estado.visualizacion == true) {
              estado = element.estado_historial[index].estado;
              break;
            }
          }
        }
        if (element.direccion_entrega != null) {
          paquetes_lista.push({
            id: element.id,
            direccion_entrega: element.direccion_entrega

          });
        }

        if (index == array.length - 1) {
          this.spinnerService.hide();
        }
      });


    }, error => {
      this.spinnerService.hide();
    });
  }

  ngOnInit() {
    this.spinnerService.show();

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });

    //console.log('Cliente', this.authService.isCliente);

    this.rutasService.get().subscribe((value: any[]) => {
      this.rutas = [...value];
      this.allRutas = [...value];
    });

    if (!this.authService.isCliente) {


      this.getVendedores();

      this.getEmpresas();

      this.getPaquetes();

      this.getAlianzas();
    }

    this.minDate.setFullYear(new Date(Date.now()).getFullYear() - 18);

    this.getProvincias();

    this.getMetodosPago();

    this.getTarifas();

    this.authService.getUserById(this.id).subscribe(data => {

      this.user = data.body;
      const districtType = this.user.distrito ? this.user.distrito.tipo : null;
      this.setupRoutes(districtType);

      this.disabledChangeRoute();
      this.formUser.controls.email.setValue(this.user.email);
      this.formUser.controls.email_2.setValue(this.user.email_2);
      this.formUser.controls.tipo_de_cedula.setValue(this.user.tipo_de_cedula);
      this.formUser.controls.nombre.setValue(this.user.nombre);
      this.formUser.controls.primer_apellido.setValue(this.user.primer_apellido);
      this.formUser.controls.segundo_apellido.setValue(this.user.segundo_apellido);
      this.formUser.controls.numero_de_cedula.setValue(this.user.numero_de_cedula);
      this.formUser.controls.fecha_de_nacimiento.setValue(this.user.fecha_de_nacimiento);
      this.formUser.controls.genero.setValue(this.user.genero);
      this.formUser.controls.telefono_casa.setValue(this.user.telefono_casa);
      this.formUser.controls.telefono_oficina.setValue(this.user.telefono_oficina);
      this.formUser.controls.telefono_movil.setValue(this.user.telefono_movil);
      this.formUser.controls.cuenta.setValue(this.user.cuenta);

      this.email_1 = this.formUser.value.email1;
      this.email_2 = this.formUser.value.email_2;

      this.twofaActive = this.user.twofaActive;

      if (!this.user.secret_key) {
        this.get2FA();
      }
      this.changeMode(this.user.modo_de_entrega);

      if (this.user.is_staff === false) {

        this.prealertados = this.user.paquetes_prealerta;
        this.paquetesClientes = this.user.paquetes_cliente;
        this.facturasPendientes = this.user.facturas_pendientes;
        this.pagosRealizados = this.user.facturas_pagadas;
        this.entregasProgramadas = this.user.entregas_programadas;
        this.notasCredito = this.user.notas_credito;
        this.notasDebito = this.user.notas_debito;

        this.formUser.controls.compro_en_internet.setValue(this.user.compro_en_internet);
        this.formUser.controls.metodo_de_captacion.setValue(this.user.metodo_de_captacion);

        this.formUser.controls.razon_social.setValue(this.user.razon_social);
        this.formUser.controls.cedula_juridica.setValue(this.user.cedula_juridica);
        this.formUser.controls.representante_legal.setValue(this.user.representante_legal);
        this.formUser.controls.referido_id.setValue(this.user.referido_id);


        if (this.user.tipo_de_cedula == 'JUR') {
          //console.log(this.user.tipo_de_cedula);
          this.formUser.controls.nombre.setValue(this.user.razon_social);
          this.formUser.controls.primer_apellido.setValue(this.user.razon_social);
          this.formUser.controls.numero_de_cedula.setValue(this.user.cedula_juridica);
        }

        this.formContacto.controls.telefono_casa.setValue(this.user.telefono_casa);
        this.formContacto.controls.telefono_oficina.setValue(this.user.telefono_oficina);
        this.formContacto.controls.telefono_movil.setValue(this.user.telefono_movil);

        this.formEntrega.controls.direccion.setValue(this.user.direccion);

        if (this.user.provincia !== null) {
          this.formEntrega.controls.provincia.setValue(this.user.provincia.id);
        } else {
          this.formEntrega.controls.provincia.setValue(null);
        }

        if (this.user.canton !== null) {
          this.formEntrega.controls.canton.setValue(this.user.canton.id);
        } else {
          this.formEntrega.controls.canton.setValue(null);
        }

        if (this.user.distrito !== null) {
          this.formEntrega.controls.distrito.setValue(this.user.distrito.id);
        } else {
          this.formEntrega.controls.distrito.setValue(null);
        }

        this.formEntrega.controls.modo_de_entrega.setValue(this.user.modo_de_entrega);
        this.formEntrega.controls.direccion_entrega.setValue(this.user.direccion_entrega);
        this.formEntrega.controls.detalles_adicionales.setValue(this.user.detalles_adicionales);
        this.formEntrega.controls.autoriza_entrega.setValue(this.user.autoriza_entrega);

        // eslint-disable-next-line max-len
        if (this.user.direccion_entrega_apps !== null && this.user.direccion_entrega_apps.length !== 0) {

          const mapsApps = JSON.parse(this.user.direccion_entrega_apps);
          this.latitude = mapsApps.lat;
          this.longitude = mapsApps.lng;
          this.address = mapsApps.address;
          this.formEntrega.controls.direccion_entrega_apps.setValue(mapsApps);
        } else {
          this.setCurrentLocation();
        }

        this.formEntrega.controls.ruta.setValue(this.user.ruta != null ? this.user.ruta.id : null);
        this.formEntrega.controls.sucursal.setValue(this.user.sucursal);
        this.formEntrega.controls.encomienda.setValue(this.user.encomienda);

        this.formAdministrativo.controls.rol.setValue(this.user.rol);
        this.formAdministrativo.controls.tipo_cliente.setValue(this.user.tipo_cliente.id);
        this.formAdministrativo.controls.is_active.setValue(this.user.is_active);
        this.formAdministrativo.controls.anotacion.setValue(this.user.anotacion);
        this.formAdministrativo.controls.anotacion_owner.setValue(this.user.anotacion_owner?.id);
        this.formAdministrativo.controls.anotacion_fecha.setValue(this.user.anotacion_fecha);
        this.formAdministrativo.controls.tarifaA.setValue(this.user.tarifaA.id);
        this.formAdministrativo.controls.tarifaM.setValue(this.user.tarifaM.id);
        this.formAdministrativo.controls.pago_tarjeta.setValue(this.user.pago_tarjeta);
        this.formAdministrativo.controls.vendedor.setValue(this.user.vendedor?.id);
        this.formAdministrativo.controls.enable.setValue(this.user.enable);
        this.formAdministrativo.controls.alianza.setValue(this.user.alianza ? this.user.alianza.id : null);
        this.formAdministrativo.controls.preferir_factura.setValue(this.user.preferir_factura);
        this.formAdministrativo.controls.exento_iva.setValue(this.user.exento_iva);
        this.formAdministrativo.controls.charge_exempt.setValue(this.user.charge_exempt);

        this.formEntrega.controls.metodo_pago.setValue(this.user.metodo_pago != null ? this.user.metodo_pago.id : null);

        if (this.user.datosfactura.tipo_factura != null) {
          this.formFactura.controls.tipo_factura.setValue(this.user.datosfactura.tipo_factura);
        } else {
          this.formFactura.controls.tipo_factura.setValue(this.user.tipo_de_cedula);
        }

        if (this.user.datosfactura.nombre != null) {
          this.formFactura.controls.nombre.setValue(this.user.datosfactura.nombre);
        } else {
          this.formFactura.controls.nombre.setValue(this.user.nombre);
        }

        if (this.user.datosfactura.primer_apellido != null) {
          this.formFactura.controls.primer_apellido.setValue(this.user.datosfactura.primer_apellido);
        } else {
          this.formFactura.controls.primer_apellido.setValue(this.user.primer_apellido);
        }

        if (this.user.datosfactura.segundo_apellido != null) {
          this.formFactura.controls.segundo_apellido.setValue(this.user.datosfactura.segundo_apellido);
        } else {
          this.formFactura.controls.segundo_apellido.setValue(this.user.segundo_apellido);
        }

        if (this.user.datosfactura.razon_social != null) {
          this.formFactura.controls.razon_social.setValue(this.user.datosfactura.razon_social);
        } else {
          this.formFactura.controls.razon_social.setValue(this.user.razon_social);
        }

        if (this.user.datosfactura.numero_identificacion != null) {
          this.formFactura.controls.numero_identificacion.setValue(this.user.datosfactura.numero_identificacion);
        } else {
          if (this.user.tipo_de_cedula === 'FIS') {
            this.formFactura.controls.numero_identificacion.setValue(this.user.numero_de_cedula);
          } else {
            this.formFactura.controls.numero_identificacion.setValue(this.user.cedula_juridica);
          }
        }

        if (this.user.datosfactura.telefono != null) {
          this.formFactura.controls.telefono.setValue(this.user.datosfactura.telefono);
        } else {
          if (this.user.tipo_de_cedula === 'FIS') {
            this.formFactura.controls.telefono.setValue(this.user.telefono_casa);
          } else {
            this.formFactura.controls.telefono.setValue(this.user.telefono_oficina);
          }
        }

        if (this.user.datosfactura.correo != null) {
          this.formFactura.controls.correo.setValue(this.user.datosfactura.correo);
        } else {
          this.formFactura.controls.correo.setValue(this.user.email);
        }

        if (this.user.datosfactura.direccion != null) {
          this.formFactura.controls.direccion.setValue(this.user.datosfactura.direccion);
        } else {
          this.formFactura.controls.direccion.setValue(this.user.direccion);
        }

        if (this.user.datosfactura.provincia != null) {

          this.formFactura.controls.provincia.setValue(this.user.datosfactura.provincia.id);
        } else {
          this.formFactura.controls.provincia.setValue(this.user.provincia);
        }

        if (this.user.datosfactura.canton != null) {

          this.formFactura.controls.canton.setValue(this.user.datosfactura.canton.id);
        } else {
          this.formFactura.controls.canton.setValue(this.user.canton);
        }

        if (this.user.datosfactura.distrito != null) {

          this.formFactura.controls.distrito.setValue(this.user.datosfactura.distrito.id);
        } else {
          this.formFactura.controls.distrito.setValue(this.user.distrito);
        }

        this.notifications = this.user.notificaciones;

        if (this.notifications !== undefined && this.notifications != null) {
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.arribo_M.setValue((this.notifications != null ? this.notifications.arribo_M : false));
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.n_transito.setValue((this.notifications != null ? this.notifications.n_transito : false));
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.arribo_CR.setValue((this.notifications != null ? this.notifications.arribo_CR : false));
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.n_entrega.setValue((this.notifications != null ? this.notifications.n_entrega : false));
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.n_email.setValue((this.notifications != null ? this.notifications.n_email : false));
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.n_telefono.setValue((this.notifications != null ? this.notifications.n_telefono : false));
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.n_whatsapp.setValue((this.notifications != null ? this.notifications.n_whatsapp : false));
          // eslint-disable-next-line max-len
          this.formNotificaciones.controls.n_push.setValue((this.notifications != null ? this.notifications.n_push : false));
        }

        this.authorizations = this.user.autorizaciones;
        this.dataSourceCards = this.user.tarjetas;

        if (!this.authService.isAdmin && this.dataSourceCards) {
          this.dataSourceCards = this.dataSourceCards.filter(el => el.Active === true);
        }

        if (this.authService.isAdmin || this.authService.isServicioCliente || this.authService.isVendedor) {
          this.getTipoCliente().then(() => {
            let hasEncomienda = false;
            this.user.tipo_cliente.forEach(element => {
              const clientType = this.allTipoClientes.filter(tipo => tipo.id === element)[0]
              if (clientType.nombre === 'Encomienda') {
                hasEncomienda = true;
              }
              this.tipoClientes.push(clientType);
            });
            // Filter
            if (hasEncomienda) {
              this.rutas = this.rutas.filter(ruta => ruta.encomienda === true);
            }
          });
        }

        this.tipoClientes = [];

        this.checkAnotacion();

      } else {
        this.formAdministrativo.controls.rol.setValue(this.user.rol);
        this.formAdministrativo.controls.is_active.setValue(this.user.is_active);
        this.formAdministrativo.controls.enable.setValue(this.user.enable);
      }
      this.spinnerService.hide();

      this.authService.setRol();
      this.cdr.detectChanges();
    });


  }

  changeFormUser(form) {
    //console.log(form);

    if (this.changePassword) {
      form.temp_pass = false;
    } else {
      if (!this.formUser.valid) {
        return;
      }
    }

    this.editarInfo = false;
    this.changePassword = false;

    if (form.segundo_apellido === '') {
      delete form.segundo_apellido;
    }


    if (this.user.tipo_de_cedula == 'JUR') {
      delete form.nombre;
      delete form.primer_apellido;
      delete form.numero_de_cedula;
    }

    form.fecha_de_nacimiento = this.datePipe.transform(form.fecha_de_nacimiento, 'yyyy-MM-dd');

    Object.keys(form).forEach(key => {
      if (form[key] === '' || form[key] === null) {
        delete form[key];
      }
    });

    this.authService.updateUser(form, this.id).subscribe(data => {
      if (data.ok) {
        if (this.authService.isCliente) {
          localStorage.setItem('user', JSON.stringify(data.body));
        }
        this.openDialog(this.translate.translate('Usuario Actualizado'));
        this.ngOnInit();
        document.getElementById('perfilTab-link').style.borderStyle = 'none';
      } else {
        this.openDialog(this.translate.translate('Error al actualizar usuario'));
      }
    });
  }

  changeFormContacto(form) {

    if (!this.formContacto.valid) {
      return;
    }
    this.editarInfoContacto = false;
    form.password = '';
    form.email = this.user['email'];
    form.fecha_de_nacimiento = this.user['fecha_de_nacimiento'];
    form.nombre = this.user['nombre'];
    form.notificaciones = this.user['notificaciones'];
    form.numero_de_cedula = this.user['numero_de_cedula'];
    form.primer_apellido = this.user['primer_apellido'];
    this.authService.updateUser(form, this.id).subscribe(data => this.ngOnInit());

  }

  changeFormNotificaciones(form) {
    for (const i in form) {

      if (form[i] === true) {
        form[i] = 'true';
      } else {
        form[i] = 'false';
      }
    }

    if (this.notifications == null || this.notifications.length === 0) {
      form.id_user = this.id;
      this.authService.createNotifications(form).subscribe(data => {

        this.ngOnInit();
      });
    } else {
      this.authService.setNotifications(this.id, form).subscribe(data => {
        this.ngOnInit();
      });
    }
  }

  changeFormAdministrativo(form) {
    form.user_id = this.id;

    if (this.anotacionChange) {
      if (form.anotacion.length === 0) {
        form.anotacion = null;
        form.anotacion_owner = null;
        form.anotacion_fecha = null;
      } else {
        form.anotacion_owner = this.authService.getUser().id;
        form.anotacion_fecha = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
      }

    }

    const tipos = [];
    form.tipo_cliente = [];

    this.tipoClientes.forEach(element => {
      tipos.push(element.id);
    });

    if (tipos.length > 0) {
      form.tipo_cliente = tipos;
    } else {
      delete form.tipo_cliente;
    }

    this.authService.updateUser(form, this.id).subscribe(data => this.ngOnInit());
  }

  changeFormFactura(form) {
    form.usuario = this.id;
    if (form.nombre == '' || form.nombre == null) {
      delete form.nombre;
    }

    if (form.primer_apellido == '' || form.primer_apellido === null) {
      delete form.primer_apellido;
    }

    if (form.segundo_apellido == '' || form.segundo_apellido === null) {
      delete form.segundo_apellido;
    }

    if (form.razon_social == '' || form.razon_social === null) {
      delete form.razon_social;
    }

    this.authService.updateDatosFactura(form, this.id).subscribe(data => {
      this.ngOnInit();
    });
  }

  openDialog(message, duration = 3000) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, duration);
    });


    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  changeFormEntrega() {
    //console.log(this.formEntrega.value);

    if (!this.formEntrega.valid) {
      return;
    }

    const form = this.formEntrega.value;

    form.user_id = this.id;

    const distritoId = form.distrito;
    form.direccion_entrega_apps = JSON.stringify({ lat: this.latitude ? this.latitude : 0, lng: this.longitude ? this.longitude : 0, address: this.address ? this.address : '' });
    form.direccion = form.direccion_entrega + ' ' + form.detalles_adicionales;

    if (this.formEntrega.value.modo_de_entrega != 'ES') {
      const distrito = this.distritos.filter((item) => {
        return item.id === distritoId;
      });

      form.tipo_zona_entrega = distrito[0] ? distrito[0].tipo : null;
    }

    const ruta = this.rutas.filter(e => e.id === form.ruta)[0];

    //console.log('Ruta', ruta);

    try {
      if (ruta.encomienda === true) {
        form.encomienda = ruta.id;
      }
    } catch { }

    //console.log('FORM', form);

    if (this.authService.isCliente) {
      form.last_route_change = new Date();
    }

    this.authService.updateUser(form, this.id).subscribe(data => {
      //console.log(data);

      if (data.ok) {
        this.ngOnInit();
        if (form.modo_de_entrega !== 'ES') {
          this.onSelect('Entrega');
        }
        this.editarInfoEntrega = false;
        this.openDialog(this.translate.translate('Datos de entrega Actualizados'));
        this.formEntregaChanged = false;

        const user = JSON.parse(localStorage.getItem('user'));
        user.distrito = distritoId;
        localStorage.setItem('user', JSON.stringify(user));

        document.getElementById('perfilEntrega-link').style.borderStyle = 'none';
      } else {
        this.openDialog(this.translate.translate('Error al actualizar los datos de entrega'));
      }
    });

  }

  onProvinceChange() {
    this.formEntrega.controls['canton'].setValue(null);
    this.formEntrega.controls['distrito'].setValue(null);
    this.formEntrega.controls['ruta'].setValue(null);
    this.formEntrega.controls['direccion_entrega'].setValue(null);
    this.formEntrega.controls['detalles_adicionales'].setValue(null);
  }

  onCantonChange() {
    this.formEntrega.controls['distrito'].setValue(null);
    this.formEntrega.controls['ruta'].setValue(null);
  }

  onDistrictChange() {
    const value = this.formEntrega.controls['distrito'].value;
    const districtObj = this.distritos.filter((item) => {
      return item.id === value;
    });;
    this.formEntrega.controls['ruta'].setValue(null);
    const districtType = districtObj[0] ? districtObj[0].tipo : null;
    this.setupRoutes(districtType);
  }

  onAddressChange() {
    if (this.formEntregaChanged === false) {
      this.formEntregaChanged = true;
      const mode = this.formEntrega.controls['modo_de_entrega'].value;
      this.formEntrega.reset();
      this.formEntrega.controls['modo_de_entrega'].setValue(mode);
    }
  }

  changeMode(mode) {
    if (mode === 'ES') {
      this.formEntrega.reset();
      this.removeValidators(this.formEntrega);
      this.formEntrega.controls['modo_de_entrega'].setValue('ES');
      this.formEntrega.controls['sucursal'].setValidators([Validators.required]);
      this.formEntrega.updateValueAndValidity();
    }

    if (mode === 'ED') {
      this.removeValidators(this.formEntrega);
      this.formEntrega.controls['modo_de_entrega'].setValue('ED');
      this.formEntrega.controls['direccion_entrega'].setValidators([Validators.required]);
      this.formEntrega.controls['detalles_adicionales'].setValidators([Validators.required]);
      this.formEntrega.controls['metodo_pago'].setValidators([Validators.required]);
      this.formEntrega.controls['canton'].setValidators([Validators.required]);
      this.formEntrega.controls['distrito'].setValidators([Validators.required]);
      this.formEntrega.controls['provincia'].setValidators([Validators.required]);
      this.formEntrega.updateValueAndValidity();
    }

    if (mode === 'AM') {
      this.removeValidators(this.formEntrega);
      this.formEntrega.controls['modo_de_entrega'].setValue('AM');
      this.formEntrega.controls['ruta'].setValue(67);
      this.formEntrega.controls['direccion_entrega'].setValidators([Validators.required]);
      this.formEntrega.controls['detalles_adicionales'].setValidators([Validators.required]);
      this.formEntrega.controls['encomienda'].setValidators([Validators.required]);
      this.formEntrega.controls['metodo_pago'].setValidators([Validators.required]);
      this.formEntrega.controls['canton'].setValidators([Validators.required]);
      this.formEntrega.controls['distrito'].setValidators([Validators.required]);
      this.formEntrega.controls['provincia'].setValidators([Validators.required]);
      this.formEntrega.updateValueAndValidity();
    }
  }

  crearAutorizacion(): void {
    const dialogRef = this.dialog.open(AutorizacionComponent, {
      width: '500px',
      data: { usuario: this.user.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  deleteAutorizacion(id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: this.translate.translate('¿Desea eliminar este elemento?') },
      height: '200px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.authService.deleteAuthorization(id).subscribe(data => {
          this.ngOnInit();
          this.openSnackBar('Se ha eliminado un elemento.', 'Cerrar');
        });
      }
    });
  }

  deleteCard(id) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: this.translate.translate('¿Desea eliminar este elemento?') },
      height: '200px',
      width: '500px',
    });
    //console.log('Tarjeta Borrar', id);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.usuariosService.deleteCards(id).subscribe(data => {
          this.ngOnInit();
          this.openSnackBar(this.translate.translate('Se ha eliminado un elemento.'), this.translate.translate('Cerrar'));
        });
      }
    });
  }

  editAutorizacion(data) {
    const dialogRef = this.dialog.open(EditAuthorizationsComponent, {
      width: '500px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }


  editCardAlternative(data) {

    const dialogRef = this.dialog.open(CardComponent, {
      data,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {

      this.ngOnInit();

    });

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  public getErrorMessage(control) {
    if (control.hasError('required')) {
      return this.translate.translate('Campo necesario');
    }
    if (control.hasError('pattern')) {
      return this.translate.translate('Formato ínvalido');
    }
    if (control.hasError('minlength')) {
      return this.translate.translate('Demasiado Corto');
    }
    if (control.hasError('maxlength')) {
      return this.translate.translate('Demasiado Largo');
    }
    if (control.hasError('min')) {
      return this.translate.translate('Límite mínimo');
    }
    if (control.hasError('exists')) {
      return this.translate.translate('Correo en uso');
    }
    if (control.hasError('equal')) {
      return this.translate.translate('Correos deben ser distintos');
    }
    if (control.hasError('mustMatch')) {
      return this.translate.translate('Contraseñas deben ser iguales');
    }
    return '';
  }

  getModoEntrega(value) {
    const item = this.deliveryModes.filter(function (item) {
      return item.value == value;
    });
    if (item[0] != undefined && item[0] != null) {
      return item[0]['name'];
    }
    return '';
  }

  getRuta(value) {
    if (!this.rutas) {
      return
    }

    const item = this.rutas.filter(function (item) {
      return item.id === value;
    });
    if (!item) {
      return '';
    }
    if (item[0] !== undefined && item[0] != null) {
      return item[0]['nombre'];
    }
    return '';
  }

  getMetodoPago(value) {
    const item = this.metodosPagos?.filter(function (item) {
      return item.id == value;
    });

    if (!item) {
      return '';
    }
    if (item[0] != undefined && item[0] != null) {
      return item[0]['nombre'];
    }
    return '';
  }

  getProvincia(value) {

    try {
      const item = this.provincias.filter(function (item) {
        return item.id === value;
      });
      if (item[0] !== undefined && item[0] != null) {
        return item[0]['provincia'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getCanton(value) {
    if (typeof value === 'object') {
      var value_canton = value.id;
    } else {
      var value_canton = value;
    }

    try {
      const item = this.cantones.filter(function (item) {
        return item.id === value_canton;
      });

      if (item[0] !== undefined && item[0] != null) {

        return item[0]['canton'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getDistrito(value) {
    if (typeof value === 'object') {
      var value_distrito = value.id;
    } else {
      var value_distrito = value;
    }

    try {
      const item = this.distritos.filter(function (item) {
        return item.id === value_distrito;
      });
      if (item[0] !== undefined && item[0] != null) {
        return item[0]['distrito'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getEmpresa(value) {
    if (typeof value === 'object') {
      var value_empresa = value.id;
    } else {
      var value_empresa = value;
    }

    try {
      const item = this.rutas.filter(function (item) {
        return item.id === value_empresa;
      });
      if (item[0] !== undefined && item[0] != null) {
        return item[0]['nombre'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getRolName(value) {
    const item = this.roles.filter(function (item) {
      return item.value === value;
    });
    if (item[0] !== undefined && item[0] != null) {
      return item[0]['name'];
    }
    return '';
  }

  getTipoName(value) {
    const item = this.tipoClientes.filter(function (item) {
      return item.id === value;
    });
    if (item[0] !== undefined && item[0] != null) {
      return item[0]['nombre'];
    }
    return '';
  }

  getTipoTarifaA(value) {
    const item = this.tarifasAereas.filter(function (item) {
      return item.id === value;
    });
    if (item[0] !== undefined && item[0] != null) {
      return item[0]['nombre'];
    }
    return '-';
  }

  getTipoTarifaM(value) {
    const item = this.tarifasMaritimas.filter(function (item) {
      return item.id === value;
    });
    if (item[0] !== undefined && item[0] != null) {
      return item[0]['nombre'];
    }
    return '-';
  }

  getVendedor(value) {
    if (this.vendedores) {
      const item = this.vendedores.filter(function (item) {
        return item.id === value;
      });
      if (item[0] !== undefined && item[0] != null) {
        return this.usuariosService.get_fullname(item[0]);
      }
    }
    return '-';
  }

  markerDragEnd($event) {

    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  markerClick($event) {

    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.formEntrega.controls.direccion_entrega.setValue(this.address);
        } else {
          alert('No hay resultados');
        }
      } else {
        //console.log('Geocoder Fail: ' + status);
      }
    });
  }

  getProvincias() {
    this.usuariosService.getProvincias().subscribe(data => {
      this.provincias = data;
    });
  }

  getCantones(idCanton) {
    this.usuariosService.getCantones(idCanton).subscribe(data => {
      this.cantones = data;
      //console.log('Cantones', this.cantones);
    });

  }

  getDistritos(idProvincia, idCanton) {
    this.usuariosService.getDistritos(idProvincia, idCanton).subscribe(data => {
      this.distritos = data;
      //console.log('Distritos', this.distritos);
    });

  }

  getDireccionContacto() {
    //console.log('Getting direccion contacto');
    this.getCantones(this.formEntrega.controls.provincia.value);
    this.getDistritos(this.formEntrega.controls.provincia.value, this.formEntrega.controls.canton.value);
  }

  getDireccionFactura() {
    this.getCantones(this.formFactura.controls.provincia.value);
    this.getDistritos(this.formFactura.controls.provincia.value, this.formFactura.controls.canton.value);
  }

  getMetodosPago() {
    this.usuariosService.getMetodosPago().subscribe(data => {
      this.metodosPagos = data;

      this.metodosPagoPreviaEntrega = this.metodosPagos.filter(function (item) {
        return item.previaentrega === true;
      });

      this.metodosPagoContraEntrega = this.metodosPagos.filter(function (item) {
        return item.contraentrega === true;
      });
    });
  }

  getTarifas() {
    this.tarifasService.getByCodigo('A').subscribe(data => {
      this.tarifasAereas = data;
    });

    this.tarifasService.getByCodigo('M').subscribe(data => {
      this.tarifasMaritimas = data;
    });
  }

  setupRoutes(dtype) {
    this.rutas = [... this.allRutas];
    if (dtype === 'r') {
      this.rutas = this.rutas.filter(ruta => ruta.nombre === 'ENCOMIENDA' || ruta.nombre === 'ENCOMIENDA CORREOS DE COSTA RICA');
      const correos = this.rutas.filter(ruta => ruta.nombre === 'ENCOMIENDA CORREOS DE COSTA RICA');
      this.formEntrega.controls.ruta.setValue(correos ? correos[0].id : this.rutas[0].id);
    } else if (dtype === 'a') {
      this.rutas = this.rutas.filter(ruta => ruta.nombre === 'CARGO EXTRA');
      this.formEntrega.controls.ruta.setValue(this.rutas[0].id);
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    //console.log('tab change', this.selectedTabIndex);

    if (this.user.is_staff === false) {

      this.checkAnotacion();

      if (this.selectedTabIndex === 1) {
        this.getDireccionContacto();
      }

      if (this.selectedTabIndex === 5) {
        this.getDireccionFactura();
      }

    }

  }

  public loadPrealerts() {
    this.spinnerService.show();
    this.paquetesService.getByStatusUsuario(this.user.id, 1, this.pageIndex).subscribe(
      (res: any) => {
        this.length = res.count;
        this.dataSourcePrealerts = res.results;
        this.displayedColumnsPrealerts = ['tracking', 'guia', 'descripcion', 'estado', 'modificado'];

        this.spinnerService.hide();
      });
  }

  public loadPackages() {
    this.spinnerService.show();
    this.dataSourcePackages = [];

    this.totalSeleccionadoUSD = 0;
    this.totalSeleccionadoCRC = 0;

    this.paquetesService.getTotalToPayByUser(this.user.id).subscribe(
      (res: any) => {
        this.por_pagar = res.totalUSD;
        this.por_pagar_colones = res.totalCRC;
      }
    );
    if (this.statusPaymentFilter === '0') {
      this.packagePageSize = 100;
      this.pageIndex = 0;
    } else {
      this.packagePageSize = 10;
    }
    this.paquetesService.getByUser(this.user.id, this.pageIndex, this.statusPaymentFilter).subscribe(
      (res: any) => {
        //console.log('res', res.results);
        this.length = res.count;
        this.dataSourcePackages = res.results;
        this.dataSourceOrders = res.order_list;
        this.spinnerService.hide();

        this.displayedColumnsPackages = ['seleccionar', 'guia', 'order', 'tracking', 'descripcion', 'estado', 'tipo_de_paquete', 'peso_real', 'monto_por_cancelar', 'monto_por_cancelar_crc', 'creado', 'zona'];
        this.selectedTotal();
      }, error => {
        this.spinnerService.hide();
      });
  }

  public loadBills() {

    this.facturasService.getFacturasByUserPorPagar(this.user.id).subscribe(
      (res: any) => {

        this.dataSourceBills.sort((a, b) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });

        this.displayedColumnsBills = ['seleccionar', 'guia', 'descripcion', 'dolares', 'colones', 'estado', 'fecha'];
      });

  }

  public loadDelivers() {
    this.paquetesService.getScheduledPackagesUser(this.user.id, this.pageIndex).subscribe(
      (res: any) => {

        this.entregasProgramadas = res.count;
        this.length = res.count;
        this.dataSourceDelivers = res.results;

        this.displayedColumnsDelivers = ['guia', 'descripcion', 'estado', 'programada', 'asignado', 'fecha_confirmada', 'direccion_entrega', 'hora_entrega'];
      }
    );

  }

  public loadNotasCredito() {
    this.facturasService.getNotasCreditoUsuario(this.user.id, this.pageIndex).subscribe((res: any) => {

      this.notasCredito = res.count;
      this.length = res.count;
      this.dataSourceNotasCredito = res.results;
      //console.log('notas', this.dataSourceNotasCredito);

      this.displayedColumnsNotasCredito = ['id', 'fecha', 'guia', 'factura', 'cuenta', 'descripcion', 'monto', 'creador'];
    });
  }


  public loadNotasDebito() {
    this.facturasService.getNotasDebitoUsuario(this.user.id, this.pageIndex).subscribe((res: any) => {

      this.notasDebito = res.count;
      this.length = res.count;
      this.dataSourceNotasDebito = res.results;
      //console.log('notasDebito', this.dataSourceNotasDebito);

      this.displayedColumnsNotasDebito = ['id', 'fecha', 'guia', 'factura', 'cuenta', 'descripcion', 'monto', 'pendiente', 'creador', 'opciones'];
    });
  }

  public loadReferidos() {
    this.usuariosService.get_user_referrals(this.user.id).subscribe((res: any) => {
      this.dataSourceReferidos = res;
      this.displayedColumnsReferidos = ['cuenta', 'reclamado', 'fecha'];
    });
  }

  onSelect(data): void {
    const user = this.authService.getUser();
    this.length = 0;

    this.checkAnotacion();

    if ((this.authService.isCliente && (data !== 'Entrega' && data !== 'Perfil')) && user.consent == false) {
      this.router.navigateByUrl('/dashboard/prealerta');
    } else {
      if (data === 'Contacto' || data === 'Entrega') {
        this.getDireccionContacto();

      }

      if (data === 'Factura') {
        this.getDireccionFactura();
      }

      if (data === 'Paquetes') {
        this.pageIndex = 1;
        this.loadPackages();
      }

      if (data === 'Entregas') {
        this.pageIndex = 1;
        this.loadDelivers();

      }

      if (data === 'NotasCredito') {
        this.pageIndex = 1;
        this.loadNotasCredito();
      }

      if (data === 'NotasDebito') {
        this.pageIndex = 1;
        this.loadNotasDebito();
      }

      if (data === 'Prealertas') {
        this.pageIndex = 1;
        this.loadPrealerts();
      }

      if (data === 'Referidos') {
        this.pageIndex = 1;
        this.loadReferidos();
      }
    }



  }

  paginate(event: any, tab) {

    this.por_pagar = 0;
    this.por_pagar_colones = 0;

    this.totalSeleccionadoUSD = 0;
    this.totalSeleccionadoCRC = 0;

    this.pageIndex = event;

    if (tab === 'prealertas') {
      this.loadPrealerts();
    }

    if (tab === 'paquetes') {
      this.loadPackages();
    }

    if (this.selectedTabIndex === 4) {
      // this.loadBills();
    }

    if (tab === 'delivers') {
      this.loadDelivers();
    }


    if (tab === 'notasCredito') {
      this.loadNotasCredito();
    }

    if (tab === 'notasDebito') {
      this.loadNotasDebito();
    }
  }

  public isAllSelected = (): boolean => {
    const numSelected = this.selectedPackages.selected.length;
    const numRows = this.dataSourceBills.length;
    return numSelected === numRows;
  };

  clearAll(): void {
    // Deseleccionar todos los elementos
    this.dataSourcePackages.forEach((row: any) => {
      this.selectedIds.deselect(row.id);
      this.selectedPackages.deselect(row);
    });
    this.packagesToPay = [];
    this.paquetesEntregas = [];
  }

  masterToggle(event): void {
    this.clearAll();
    if (event.checked) {
      // Seleccionar todos los elementos
      this.dataSourcePackages.forEach((row: any) => {
        this.selectedIds.select(row.id);
        this.selectedPackages.select(row);
        if (row.clase !== 'D' && row.factura != null && row.factura.monto_pendiente > 0 &&
          (row.factura?.cargo_impuestos > 0 || row.estado_actual.id >= 10)) {
          if (row.paquete_contenedor !== 'False' && row.paquete_contenedor.startsWith('O-')) {
            const order = this.dataSourceOrders.find(order => order.numero_de_guia === row.paquete_contenedor);
            this.dataSourcePackages.forEach(element => {
              if (element.paquete_contenedor === row.paquete_contenedor) {
                if (order.numero_de_guia === row.paquete_contenedor) {
                  this.selectedPackages.select(order);
                  if (!this.packagesToPay.includes(order)) {
                    this.packagesToPay.push(order);
                  }
                }
                this.selectedIds.select(element.id);
              }
            });

          }
          else {
            this.packagesToPay.push(row);
          }
        }
        if ((row.estado_actual.id >= 12 && row.estado_actual.id <= 15 || row.estado_actual.id == 17 || row.estado_actual.id == 21) &&
          row.entrega.entregado === false) {
          this.paquetesEntregas.push(row);
        }
      });
    }
    console.log('Selected Packages', this.packagesToPay);
    this.selectedTotal();
  }

  public checkboxLabel = (row?: any): string => {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectedPackages.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  };

  selectedTotal() {
    this.totalSeleccionadoUSD = 0;
    this.totalSeleccionadoCRC = 0;
    this.packagesToPay.forEach(element => {
      if (element.clase != 'D') {
        this.totalSeleccionadoUSD = this.totalSeleccionadoUSD + element.factura.monto_pendiente;
        this.totalSeleccionadoCRC = this.totalSeleccionadoCRC + (element.factura.factura_crc.monto_pendiente);
      }
    });

    //console.log(this.selectedPackages.selected);
  }

  changeCheck(event, row) {
    //console.log('check change', row, event);

    let entrega = false;
    let pago = false;

    if (row.clase !== 'D' && row.factura != null && row.factura.monto_pendiente > 0 && (row.factura?.cargo_impuestos > 0 || row.estado_actual.id >= 10)) {
      pago = true;
    }

    if ((row.estado_actual.id >= 12 && row.estado_actual.id <= 15 || row.estado_actual.id == 17 || row.estado_actual.id == 21) && row.entrega.entregado === false) {
      entrega = true;
    }

    if (event.checked) {

      if (row.paquete_contenedor !== 'False' && row.paquete_contenedor.startsWith('O-')) {
        const order = this.dataSourceOrders.find(order => order.numero_de_guia === row.paquete_contenedor);
        this.dataSourcePackages.forEach(element => {
          if (element.paquete_contenedor === row.paquete_contenedor) {
            if (order.numero_de_guia === row.paquete_contenedor) {
              if (!this.packagesToPay.includes(order)) {
                this.packagesToPay.push(order);
                if (entrega) {
                  this.paquetesEntregas.push(order);
                }
              }
            }
            this.selectedIds.select(element.id);
          }
        });
      } else {
        this.selectedPackages.select(row);
        this.selectedIds.select(row.id);
        if (entrega) {
          this.paquetesEntregas.push(row);
        }
        this.packagesToPay.push(row);
      }
    }

    if (!event.checked) {
      if (row.paquete_contenedor !== 'False' && row.paquete_contenedor.startsWith('O-')) {
        this.packagesToPay = this.packagesToPay.filter(e => e.numero_de_guia !== row.paquete_contenedor);
      } else {
        this.selectedIds.deselect(row.id);
        this.selectedPackages.deselect(row);
        this.packagesToPay = this.packagesToPay.filter(e => e.id !== row.id);
      }
      this.dataSourcePackages.forEach(element => {
        if (row.paquete_contenedor.startsWith('O-') && element.paquete_contenedor === row.paquete_contenedor) {
          this.selectedPackages.deselect(element);
          this.selectedIds.deselect(element.id);
        }
      });
    }

    if (this.selectedIds.isSelected(row.id)) {
      this.selectedPackages.select(row);

      if (pago) {
        this.paquetesPagos.push(row);
      }

    } else {
      const filter = this.selectedPackages.selected.find(e => e.id == row.id);

      this.selectedPackages.deselect(filter);

      if (entrega) {
        const index = this.paquetesEntregas.indexOf(e => e.id == row.id);
        this.paquetesEntregas.splice(index, 1);
      }

      if (pago) {
        const index = this.paquetesPagos.indexOf(e => e.id == row.id);
        this.paquetesPagos.splice(index, 1);
      }

      // console.log(filter);
    }

    // console.log(this.selectedIds.selected);
    // console.log(this.selectedPackages.selected);
    // console.log(this.paquetesPagos);
    // console.log(this.paquetesEntregas);

    this.selectedTotal();
  }

  adjuntarPago() {

    const dialogRef = this.dialog.open(AdjuntarPagoComponent, {
      data: { title: 'Adjuntar Pago', origin: 'admin', monto_usd: this.totalSeleccionadoUSD, monto_crc: this.totalSeleccionadoCRC, tipo_cambio: 0 },
      height: '750px',
      width: '750px ',
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {
        if (result.metodo_pago === 2) {
          this.paymentPackages(result);
        } else {
          this.packagesToPay.forEach(async (element, index, array) => {
            const monto = element.factura.monto_pendiente;
            const monto_crc = (element.factura.factura_crc.monto_pendiente);
            const paquete = element;
            await this.procesarPago(result.metodo_pago, result.referencia, monto.toFixed(2), monto_crc.toFixed(2), this.user.id, this.authService.getUser().id, result.detail, paquete);
            if (element.numero_de_guia.startsWith('O-')) {
              this.pagosService.transferPayment({ guide: element.numero_de_guia, type: result.metodo_pago }).subscribe((data) => {
                this.openDialog('Pago Adjuntado');
              });
            }

            if (index == array.length - 1) {
              this.selectedPackages.clear();
              this.selectedIds.clear();
              this.packagesToPay = [];
              this.paquetesEntregas = [];
              this.pageIndex = 1;
              this.loadPackages();
              this.loadBills();
              // this.openDialog('Pago Adjuntado');
            }

          });

        }
      }
    });

  }

  paymentPackages(result): void {
    const metodoPago = result.metodo_pago;
    /* Pago SINPE/Transferencia */
    if (metodoPago === 2) {
      const dataComprobante = {
        deliveryDate: this.datePipe.transform(Date.now() + 1, 'yyyy-MM-dd'),
        selectedPackages: this.packagesToPay.map((paquete: any) => paquete.id),
        selectedPayments: result.selectedPayments,//this.selectedPackages.map((element: any) => element.codReferencia),
        paymentRef: result.referencia,
      };
      this.pagosService.sinpePayment(dataComprobante).subscribe((data) => {
        this.loadPackages();
        this.loadBills();
        this.spinnerService.hide();
        this.openDialog('Pago Adjuntado');
      },
        (error) => {
          this.spinnerService.hide();
          this.openDialog('Error al adjuntar el pago');
          console.error('Error:', error);
        });
    }
  }

  adjuntarPagoNota(notaDebito) {
    //console.log(notaDebito);

    const dialogRef = this.dialog.open(AdjuntarPagoComponent, {
      data: { title: 'Adjuntar Pago', origin: 'admin', monto_usd: notaDebito.monto, monto_crc: (notaDebito.monto * notaDebito.factura.tipo_de_cambio.value), tipo_cambio: 0 },
      height: '350px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const data = {
          metodo_pago: result.metodo_pago,
          monto: notaDebito.monto,
          monto_crc: (notaDebito.monto * notaDebito.factura.tipo_de_cambio.value),
          usuario: notaDebito.user.id,
          created_by: this.authService.getUser().id,
          referencia: result.referencia,
        };

        this.pagosService.procesarPago(data).subscribe((pago: any) => {
          //console.log('Response pagos', pago);

          if (pago) {
            const data1 = {
              factura: notaDebito.factura.id,
              created_by: this.authService.getUser().id,
              pago: pago.id,
              user: notaDebito.user.id,
            };

            this.facturasService.putNotaDebito(notaDebito.id, data1).subscribe((res1: any) => {
              //console.log(res1);
              this.openDialog('Pago Registrado');
              this.getPaquetes();
              notaDebito = res1;
            });
          }
        });

      }
    });

  }

  procesarPago(metodo_pago, referencia, monto, monto_crc, usuario, created_by, detalle_pago, paquete) {
    return new Promise<void>((resolve, reject) => {

      //console.log(metodo_pago, referencia, monto, monto_crc, usuario, created_by, paquete);

      this.spinnerService.show();

      const data = {
        metodo_pago,
        monto,
        monto_crc,
        referencia,
        usuario,
        created_by,
        detalle_pago
      };

      this.pagosService.procesarPago(data).subscribe((pago: any) => {

        if (pago) {
          //console.log('Pago', pago);
          //console.log('Pago ID', pago.id);

          const id = paquete.id;

          const fechaProgramada = this.getNextDeliveryDate();

          // eslint-disable-next-line max-len
          this.facturasService.putFacturas(paquete.factura.id, { pago: pago.id, usuario, completada: true, bloqueada: true }).subscribe(() => {

            const packa = {
              fecha_entrega: this.datePipe.transform(fechaProgramada, 'yyyy-MM-dd'),
              metodo_pago,
              metodo_pago2: metodo_pago,
              estado_pago: 1,
              logged_user: this.authService.getUser().id
            };

            const dataEntrega = {
              fecha_entrega: this.datePipe.transform(fechaProgramada, 'yyyy-MM-dd'),
              logged_user: this.authService.getUser().id,
            };

            this.paquetesService.put(id, packa).subscribe(() => {
              this.paquetesService.putEntrega(paquete.entrega.id, dataEntrega).subscribe(() => { });
              this.spinnerService.hide();
              resolve();
            }, err => {
              console.error(err);
              reject();
            });

          });

        }
      });
    });

  }

  marcarEntregado() {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: '¿Confirma paquetes como entregados?' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paquetesEntregas.forEach(element => {
          const paquete = {
            entregado: true,
            estado_actual: 18,
            logged_user: this.authService.getUser().id
          };

          this.paquetesService.put(element.id, paquete).subscribe(() => {

          });
        });

        this.openDialog('Paquete Entregados');

      }
    });
  }

  getNextDeliveryDate() {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 5 = friday, 6 = Saturday
    const hour = now.getHours();

    let nextDeliveryDate = new Date(now);
    //If friday before 6pm, next delivery is on Monday else is on Tuesday
    if (dayOfWeek === 5) {
      // Set to next Monday
      if (hour < 18) {
        nextDeliveryDate.setDate(now.getDate() + (1 + (7 - dayOfWeek)) % 7);
        // Set to next Tuesday
      } else {
        nextDeliveryDate.setDate(now.getDate() + (2 + (7 - dayOfWeek)) % 7);
      }
      // If Saturday or Sunday, set delivery to Tuesday
    } else if (dayOfWeek === 6 || dayOfWeek === 0) {
      nextDeliveryDate.setDate(now.getDate() + (2 + (7 - dayOfWeek)) % 7);
    }
    // Otherwise, next delivery is the next day (except for Friday)
    else {
      nextDeliveryDate.setDate(now.getDate() + 1);
    }

    return nextDeliveryDate;
  }

  filterByPaymentStatus(status) {
    this.statusPaymentFilter = status;
    this.loadPackages();
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  check2FA(status) {
    //console.log('2FA', status);
    this.twofaActive = status;
  }

  save2FA() {
    this.authService.save2fa(this.twofaActive, this.id).subscribe(data => {
      //console.log(data);
      this.openDialog(this.translate.translate('Configuración guardada'));
      this.user.twofaactive = this.twofaActive;
    });

  }

  activate2FA() {
    if (this.twofaActive) {
      this.authService.save2fa(this.twofaActive, this.id, this.secretKey).subscribe(data => {
        //console.log(data);
        this.openDialog(this.translate.translate('Configuración guardada'));
        this.user.twofaactive = this.twofaActive;
      });
    } else {
      this.openDialog(this.translate.translate('Debe activar Autenticación de 2 factores para guardar'));
    }
  }

  get2FA() {
    this.authService.generateSecretkey(this.user.id).subscribe((res) => {
      //console.log('Key Generated', res);
      this.qrInfo = res.uri;
      this.secretKey = res.key;
    });
  }

  getQRInfo() {
    this.authService.get_URI(this.user.id).subscribe((res) => {
      this.qrInfo = res.data;
    });
  }

  checkStatus2FA(value) {
    //console.log(value);
    if (value === true) {
      this.openDialog(this.translate.translate('Autenticación de dos factores Configurada'));
    }
  }

  remove2FA() {
    this.authService.remove2fa(this.user.id).subscribe(res => {
      //console.log(res.body);

      if (res.body.status === true) {
        this.openDialog(this.translate.translate('Autenticación de dos factores Eliminada para esta cuenta'));
      }

    });
  }

  getEmpresas() {
    this.tarifasService.getEmpresas().subscribe((data: any) => {
      //console.log(data);
      this.empresasTransporte = data;
    });
  }

  getVendedores() {
    this.usuariosService.get_seller_users().subscribe((data: any) => {
      //console.log(data);
      this.vendedores = data;
    });
  }

  verificarEmail() {
    this.usuariosService.activarCuenta(this.user.email).subscribe((data: any) => {

      if (data.status) {
        //console.log('Cuenta Verificada');
        this.openDialog('Cuenta Verificada');
        this.formAdministrativo.controls.enable.setValue(true);
      }

    });
  }

  setPassword() {

    const id = this.user.id;
    const password = this.formUser.controls['password'].value;

    if (password.length > 5) {
      const data = {
        password
      };

      this.authService.setPassword(id, data).subscribe((result: any) => {
        //console.log(result.body);

        this.openDialog(this.translate.translate('Contraseña enviada al correo electronico asociado a la cuenta.'));
      });
    } else {
      this.openDialog(this.translate.translate('Longitud de contraseña invalida. Debe ser igual o superior a 6 caracteres.'));
    }
  }

  deleteCuenta() {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: { message: '¿Desea eliminar esta cuenta?' },
      height: '200px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.usuariosService.delete_user_by_id(this.user.id).subscribe(data => {
          this.openDialog('Se ha eliminado esta Cuenta.');
          this.router.navigateByUrl('/admin/cuentas');
        },
          err => {
            //console.log('HTTP Error', err);
            this.openDialog('No se puede eliminar esta cuenta');
          }
        );
      }
    });
  }

  removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
  }

  asignarSucursal(event) {
    //console.log(event);

    if (event.value === 'Guadalupe') {
      this.latitude = 9.951436;
      this.longitude = -84.055484;
      this.address = '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica';
      this.getAddress(this.latitude, this.longitude);

      this.formEntrega.patchValue({
        provincia: 1,
        canton: 8,
        distrito: 54,
        direccion: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        direccion_entrega: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        detalles_adicionales: '350 este de los Tribunales de Justicia, Goicochea, Barrio Santa Cecilia, San José, Guadalupe, Costa Rica',
        direccion_entrega_apps: JSON.stringify({ lat: this.latitude, lng: this.longitude, address: this.address }),
        sucursal: 'Guadalupe',
        ruta: 72
      });
    }

    if (event.value === 'Limón') {
      this.latitude = 9.992673;
      this.longitude = -83.026042;
      this.address = 'XXVF+2HW, C. 1, Limón, Costa Rica';
      this.getAddress(this.latitude, this.longitude);

      this.formEntrega.patchValue({
        provincia: 7,
        canton: 76,
        distrito: 448,
        direccion: 'XXVF+2HW, C. 1, Limón, Costa Rica',
        direccion_entrega: 'XXVF+2HW, C. 1, Limón, Costa Rica',
        detalles_adicionales: 'Frente al Parque Vargas continuo a la entrada de Camiones Trali. Planta baja, Edificio Cobre y Acero.',
        direccion_entrega_apps: JSON.stringify({ lat: this.latitude, lng: this.longitude, address: this.address }),
        sucursal: 'Limón',
        ruta: 48
      });
    }

    //console.log(this.formEntrega.value);
  }

  getDistritoTipo(value) {
    try {
      const item = this.distritos.filter(function (e) {
        return e.id === value;
      });

      if (item[0] !== undefined && item[0] !== null) {
        return item[0]['tipo'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getAlianza(value) {
    try {
      const item = this.alianzas.filter(function (e) {
        return e.id === value;
      });

      if (item[0] !== undefined && item[0] !== null) {
        return item[0]['nombre'];
      }
      return '-';
    } catch (error) {
      return '-';
    }

  }

  getAlianzas() {
    this.usuariosService.getAlianzasAll().subscribe((res: any) => {
      this.alianzas = res;
    });
  }

  getTipoCliente() {
    return new Promise<void>((resolve, reject) => {

      this.usuariosService.getTipoCliente().subscribe((res: any) => {
        this.allTipoClientes = res;

        this.filteredTipos = this.tipoCtrl.valueChanges.pipe(
          startWith(''),
          map(tipo => this._filter(tipo, this.allTipoClientes)),
        );

        resolve();
        //console.log('filtered', this.filteredTipos);
      });
    });
  }

  displayFn(value) {
    if (!value) {
      return '';
    }
    return value.nombre;
  }

  checkAnotacion() {
    if (this.authService.isCliente === false) {
      if (this.user.anotacion) {
        this.openDialog(this.user.anotacion);
      }
    }
  }

  remove(tipo): void {
    const index = this.tipoClientes.indexOf(tipo);

    if (index >= 0) {
      this.tipoClientes.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.tipoClientes.indexOf(event.option.value);
    //console.log(index);

    //console.log(event.option);

    if (index === -1) {
      this.tipoClientes.push(event.option.value);
      this.tipoClienteInput.nativeElement.value = '';
      this.tipoCtrl.setValue('');
    }

    //console.log(this.tipoClientes);
  }

  connectAs() {

    const correo = this.user.email;
    //console.log(correo);

    this.authService.getUserTokens(correo).subscribe((res: any) => {
      //console.log(res);

      if (res.ok) {

        const admin = {
          token: localStorage.getItem('token'),
          user: localStorage.getItem('user')
        };

        localStorage.setItem('adminTokens', JSON.stringify(admin));

        const token = {
          access: res.body.access,
          refresh: res.body.refresh,
        };

        const user = res.body.user;
        this.authService.activeRol = res.body.user.rol;

        localStorage.setItem('currentUrl', this.router.url);

        this.setTokens(token, user);

      } else {
        this.openDialog('Cuenta no encontrada o permitida');

      }
    });
  }


  openFacturaD(file) {
    window.open(this.mediaUrl + file, '_blank');
  }

  setTokens(token, user) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    this.authService.goToPage();
  }

  generateEstadoCuenta() {

    this.paquetesService.getEstadoCuenta(this.user.id).subscribe((e: any) => {

      e.fecha = new Date(e.fecha);
      e.fecha.setMinutes(e.fecha.getMinutes() + e.fecha.getTimezoneOffset());

      setTimeout(() => {
        const pdf = new jsPDF('portrait', 'mm'); // A4, portrait, mm size page of PDF

        var head = [['Guía', 'Fecha Factura', 'Descripción', 'Días Credito', 'Saldo Pendiente']];
        var head_payment = [['Guía', 'Fecha Factura', 'Descripción', 'Total Pagado']];
        this.createTable(pdf, head, e.paquetes, e.total, 75, 'PAQUETES PENDIENTES DE PAGO');
        pdf.addPage();
        this.createTable(pdf, head_payment, e.paquetes_pagados, e.total_pagados, 75, 'PAQUETES CANCELADOS');
        pdf.addPage();
        pdf.deletePage(pdf.getNumberOfPages());

        // Generated PDF
        pdf.autoPrint();
        pdf.save('Estado de Cuenta ' + this.user.cuenta + '.pdf', { returnPromise: true });

      }, 1000);

    });

  }

  createHeader(pdf) {
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    pdf.addImage('assets/logo_1.png', 'PNG', 10, 5, 60, 20);

    pdf.setFontSize(24);
    pdf.text('Estado de Cuenta', width - 80, 20);

    pdf.setFontSize(12);
    pdf.text('SICSA', 10, 35);
    pdf.text('Servicios Internacionales de Carga S.A', 10, 40);
    pdf.text('Ced. 3-101-03087908', 10, 45);


    pdf.text('CLIENTE: ' + this.usuariosService.get_fullname(this.user), 10, 55);

  }

  createTable(pdf, head, body, total, startY, title): void {
    this.createHeader(pdf);
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.text(title, pdf.internal.pageSize.width / 2, startY, { align: 'center' });
    pdf.setFont("helvetica", "normal");

    autoTable(pdf, {
      head,
      body,
      margin: { top: 10, left: 10, right: 10 },
      startY: startY + 15,
      theme: 'grid',
      styles: { fontSize: 12, cellPadding: 0.5, valign: 'top', halign: 'left' },
      rowPageBreak: 'auto',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 14,
        lineWidth: 1
      },
    });

    pdf.setFontSize(15);
    pdf.text('Total: ' + total, pdf.internal.pageSize.width - 10, pdf.previousAutoTable.finalY + 8, { align: 'right' });

    var str = 'Fecha del Estado de Cuenta: ' + new Date().toLocaleDateString('es-CR', {
      weekday: 'short', year: 'numeric', month:
        'long', day: 'numeric'
    });
    pdf.setFontSize(12);
    pdf.text(str, 10, pdf.internal.pageSize.height - 10);
  }

  generarEncomienda() {
    this.spinnerService.show();
    const guias = [];
    let peso = 0;
    let observaciones = '';

    console.log(this.paquetesEntregas);
    console.log(this.user);

    this.paquetesEntregas.forEach(e => {
      guias.push(e.id);
      peso += e.peso_total;
      observaciones += e.descripcion + ' ';
    });

    const reqTarifa = {
      Provincia: this.user.provincia.codigo_correo,
      Canton: this.user.canton.codigo_correo,
      Peso: this.calcGramos(peso),
    };

    this.entregasService.getTarifaEncomienda(reqTarifa).subscribe((tarifa: any) => {
      console.log(tarifa);
      this.spinnerService.hide();

      const dialogRef = this.dialog.open(ConfirmarComponent, {
        data: { message: '¿Desea registrar el envío en Correos de Costa Rica?. Cantidad de Paquetes: ' + this.paquetesEntregas.length + ' Peso en gramos: ' + this.calcGramos(peso) + ' Monto: ₡' + tarifa.Total },
      });

      dialogRef.afterClosed().subscribe(confirm => {

        if (confirm) {
          const dialogRef1 = this.dialog.open(GeneralInputComponent, {
            data: { title: 'Favor detallar el contenido de la guía: ', value: observaciones, type: 'general' },
            height: '250px',
            width: '500px',
          });

          dialogRef1.afterClosed().subscribe(result => {
            console.log(result);

            if (result != null) {
              observaciones = result.value;
              const data = {
                Nombre: this.usuariosService.get_fullname(this.user),
                Direccion: this.user.direccion_entrega,
                Telefono: this.user.telefono_movil,

                Provincia: this.user.provincia.codigo_correo,
                Canton: this.user.canton.codigo_correo,
                Distrito: this.user.distrito.codigo_correo,

                Peso: this.calcGramos(peso),
                Observaciones: observaciones,

                Paquetes: guias
              };

              console.log(data);

              this.entregasService.setEncomiendaCorreos(data).subscribe((res: any) => {
                if (res.status === false) {
                  this.openDialog('Error al registrar la encomienda');
                } else {
                  this.openDialog('Encomienda Registrada');
                }

              });

            }
          });
        }
      });


    });

  }

  calcGramos(value: number) {
    const g = ((value * 0.453592) * 1000).toFixed(2);
    return g;
  }

  disabledChangeRoute() {
    const lastChangeDate = new Date(this.user.last_route_change);
    const currentDate = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const timeDifference = currentDate.getTime() - lastChangeDate.getTime();
    const timeRemaining = oneDayInMilliseconds - timeDifference;
    if (timeDifference < oneDayInMilliseconds) {
      const hoursRemaining = Math.floor(timeRemaining / (1000 * 60 * 60));
      const minutesRemaining = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      this.disabledTime = `Tiempo restante para poder realizar el cambio de dirección: ${hoursRemaining}h ${minutesRemaining}m`;
      return true;
    }
    return false;
  }

  sortDataPaquetes(sort: Sort) {
    const data = this.dataSourcePackages.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourcePackages = data;
      return;
    }

    this.dataSourcePackages = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'peso_real':
          return compare(a.peso_total, b.peso_total, isAsc);
        case 'zona':
          return compare(a.entrega.zona_centro_distribucion, b.entrega.zona_centro_distribucion, isAsc);
        case 'tipo_paquete':
          return compare(a.tipo_paquete, b.tipo_paquete, isAsc);
        case 'modificado':
          return compare(a.ultima_actualizacion, b.ultima_actualizacion, isAsc);
        case 'cweight':
          return compare(a.charge_weight, b.charge_weight, isAsc);
        case 'estado':
          return compare(a.estado_actual.id, b.estado_actual.id, isAsc);
        case 'user':
          return compare(a.user, b.user, isAsc);
        case 'zona':
          return compare(a.entrega.zona_centro_distribucion, b.entrega.zona_centro_distribucion, isAsc);
        case 'packages':
          return compare(a.packages, b.packages, isAsc);
        case 'monto_por_cancelar':
          return compare(a.factura.monto_pendiente, b.factura.monto_pendiente, isAsc);
        case 'monto_por_cancelar_crc':
          return compare(a.factura.factura_crc.monto_pendiente, b.factura.factura_crc.monto_pendiente, isAsc);
        case 'tracking':
          return compare(a.tracking, b.tracking, isAsc);
        case 'refdate':
          return compare(a.refdate, b.refdate, isAsc);
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'creado':
          return compare(new Date(a.created_at).getTime(), new Date(b.created_at).getTime(), isAsc);
        default:
          return 0;
      }
    });
  }

  private _filter(value: any, options: any) {
    //console.log(value, options);
    let filterValue: string;
    value.nombre ? filterValue = value.nombre.toLowerCase() : filterValue = value.toString().toLowerCase();
    return options.filter(tipo => tipo.nombre.toLowerCase().includes(filterValue));
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  isPaginatorAvailable() {
    return this.statusPaymentFilter !== '0';
  }

  toggleSubTable(row: any) {
    row.showSubTable = !row.showSubTable;
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
