<h2 class="h2-title p-4" *ngIf="authService.isCliente">{{'Mi Perfil' | translate}}</h2>

<div class="notification" *ngIf="!this.authService.isCliente && !dismis && user?.anotacion != null">
  <div class="dismis" (click)="dismis=true;">x</div>
  <p style="margin-left:20px;" class="p-notification">{{user?.anotacion}} </p>
</div>

<div *ngIf="!this.authService.isCliente" class="row ml-2 mr-2">
  <div class="col-6 text-left p-0">
    <button style="background-color: #0094a3" mat-flat-button color="primary" (click)="back()">{{'Atrás' |
      translate}}</button>
  </div>

  <div class="col-6 text-right p-0"
    *ngIf="user?.rol == 'CLIENT' && (authService.validateRolePermission('CONNECT_AS_CLIENT'))">
    <button style="background-color: #0094a3" mat-flat-button color="primary" (click)="connectAs()">{{'Conectar' |
      translate}}</button>
  </div>

  <div class="col-6 text-right p-0" *ngIf="user?.rol != 'CLIENT' && authService.getUser().id == '1'">
    <button style="background-color: #0094a3" mat-flat-button color="primary" (click)="connectAs()">{{'Conectar' |
      translate}}</button>
  </div>
</div>

<div class="mt-0 pt-0 container-example" fxLayout="column" fxLayoutGap="10px"
  style="margin-left: auto; margin-right: auto; width: 100%; max-height: 95%; border-radius: 5px; box-shadow: 0px 8px 25px rgba(0, 148, 163, 0.12) ;">

  <div class="mt-2 px-3" style="display: flex; align-items: center; justify-content: space-between;">
    <div style="text-align: left;">

      <h2 class="top-title">
        {{'Cuenta' | translate}}: &nbsp; {{usuariosService.get_fullaccount(user)}}
      </h2>

      <h2 class="top-title"
        *ngIf="(authService.isAdmin || authService.isOperacionesCostaRica || authService.isVendedor) && user?.rol == 'CLIENT'">
        {{'Tipo de Cliente' | translate}}: <span *ngFor="let tipo of tipoClientes" class="m-2 px-4"
          style="border-radius: 15px; font-size:24px !important; color: white !important"
          [style.background-color]="tipo.color"> {{ tipo.nombre | translate}} </span>
      </h2>

    </div>

    <div style="text-align: center;">

    </div>

    <div style="text-align: right;">
      <h2 class="top-title" *ngIf="!this.authService.isCliente && user?.rol == 'CLIENT'"
        [class.t]="this.authService.isCliente">
        {{'Cliente desde' | translate}}: {{user?.created_at | date : 'dd-MM-yyyy'}}
      </h2>
      <h2 class="top-title" *ngIf="!this.authService.isCliente && user?.rol == 'CLIENT' && user?.delete == 1"
        [class.t]="this.authService.isCliente">
        {{'CUENTA ELIMINADA' | translate}} el {{user?.delete_date | date : 'dd-MM-yyyy'}}
      </h2>
    </div>

  </div>

  <mat-tab-group
    [ngClass]="{'header-less-tabs':  (user?.rol == 'CLIENT' && (authService.activeRol !=  'ADMIN' && authService.activeRol !=  'OPCR' && authService.activeRol !=  'SERVCLI' && authService.activeRol !=  'VEND' && authService.activeRol !=  'OPMIA'  )) }"
    animationDuration="0ms" [@.disabled]="true" (selectedTabChange)="onTabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>account_circle</mat-icon> {{'Perfil' | translate}}
      </ng-template>

      <div class="tab">
        <tabset #staticTabs [justified]="true" customClass="paquetesTabs">
          <tab (selectTab)="onSelect('Perfil')" id="perfilTab">
            <ng-template tabHeading>
              <mat-icon>account_circle</mat-icon> {{'Perfil' | translate}}
            </ng-template>
            <div>
              <form [formGroup]="formUser" class="form">
                <div class="container table-personal pt-5 pl-5 pr-5" *ngIf="!editarInfo; else elseBlockInfo;">
                  <table style="width:100%">
                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Nombre Completo' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.nombre.value}} {{formUser.controls.primer_apellido.value}}
                        {{formUser.controls.segundo_apellido.value}}</td>
                    </tr>
                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Nombre' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.nombre.value}} </td>
                    </tr>
                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Apellido 1' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.primer_apellido.value}} </td>
                    </tr>
                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Apellido 2' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.segundo_apellido.value}} </td>
                    </tr>
                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Número de Cédula' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.numero_de_cedula.value}} </td>
                    </tr>

                    <tr *ngIf="formUser.controls.tipo_de_cedula.value == 'JUR'">
                      <td class="p-td">{{'Razón Social' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.razon_social.value}}</td>
                    </tr>
                    <tr *ngIf="formUser.controls.tipo_de_cedula.value == 'JUR'">
                      <td class="p-td">{{'Cédula Juridica' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.cedula_juridica.value}} </td>
                    </tr>

                    <tr *ngIf="formUser.controls.tipo_de_cedula.value == 'JUR'">
                      <td class="p-td">{{'Nombre del Contacto' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.representante_legal.value}} </td>
                    </tr>

                    <tr>
                      <td class="p-td">{{'Correo Electrónico' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.email.value}} </td>
                    </tr>

                    <tr>
                      <td class="p-td">{{'Correo Electrónico' | translate}} 2:</td>
                      <td class="p-td">{{formUser.controls.email_2.value ? formUser.controls.email_2.value : '-' }}
                      </td>
                    </tr>

                    <tr *ngIf="authService.isAdmin">
                      <td class="p-td">{{'Cuenta' | translate}}</td>
                      <td class="p-td">{{formUser.controls.cuenta.value ? formUser.controls.cuenta.value : '-' }}
                      </td>
                    </tr>

                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Fecha de nacimiento' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.fecha_de_nacimiento.value | date:'dd/MM/yyyy'}} </td>
                    </tr>
                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Género' | translate}}:</td>
                      <td class="p-td">{{formUser.controls.genero.value == 'M' ? 'Masculino' :
                        formUser.controls.genero.value == 'F' ? 'Femenino' : 'Otro' | translate }} </td>
                    </tr>
                    <tr
                      *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                      <td class="p-td">{{'Teléfono Casa' | translate}}:</td>
                      <td class="p-td">+506&nbsp;{{formUser.controls.telefono_casa.value}}</td>
                    </tr>
                    <tr>
                      <td class="p-td">{{'Teléfono Oficina' | translate}}:</td>
                      <td class="p-td">+506&nbsp;{{formUser.controls.telefono_oficina.value}} </td>
                    </tr>
                    <tr>
                      <td class="p-td">{{'Celular' | translate}}:</td>
                      <td class="p-td">+506&nbsp;{{formUser.controls.telefono_movil.value}}</td>
                    </tr>
                  </table>
                </div>

                <ng-template #elseBlockInfo>
                  <div class="container">
                    <div class="col-12 text-right">
                      <!--  <button mat-flat-button color="primary" (click)="editarInfo = false" class="button-consultar"
                        *ngIf="editarInfo">Atrás</button>-->
                    </div>

                    <div class="inputs-general inputs-form pt-5 pl-5 pr-5" *ngIf="changePassword == false">
                      <div class="inputs-50"
                        *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                        <div>
                          <label>{{'Nombre' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="Nombre" formControlName="nombre">
                            <mat-error *ngIf="formUser.controls['nombre'].errors">
                              {{getErrorMessage(formUser.controls['nombre'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div>
                          <label>{{'Apellido 1' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="Apellido 1" formControlName="primer_apellido">
                            <mat-error *ngIf="formUser.controls['primer_apellido'].errors">
                              {{getErrorMessage(formUser.controls['primer_apellido'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="inputs-50"
                        *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                        <div>
                          <label>{{'Apellido 2' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="Apellido 2" formControlName="segundo_apellido">
                            <mat-error *ngIf="formUser.controls['segundo_apellido'].errors">
                              {{getErrorMessage(formUser.controls['segundo_apellido'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div>
                          <label>{{'Número de Cédula' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="Número de Cédula" formControlName="numero_de_cedula">
                            <mat-error *ngIf="formUser.controls['numero_de_cedula'].errors">
                              {{getErrorMessage(formUser.controls['numero_de_cedula'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="inputs-50" *ngIf="formUser.controls.tipo_de_cedula.value == 'JUR'">
                        <div>
                          <label>{{'Razón Social' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="Razón Social" formControlName="razon_social">
                            <mat-error *ngIf="formUser.controls['razon_social'].errors">
                              {{getErrorMessage(formUser.controls['razon_social'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div>
                          <label>{{'Cédula Juridica' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="Cédula Juridica" formControlName="cedula_juridica">
                            <mat-error *ngIf="formUser.controls['cedula_juridica'].errors">
                              {{getErrorMessage(formUser.controls['cedula_juridica'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>


                      <div class="inputs-50" *ngIf="formUser.controls.tipo_de_cedula.value == 'JUR'">
                        <div>
                          <label>{{'Nombre del Contacto' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="{{'Nombre del Contacto' | translate}}"
                              formControlName="representante_legal">
                          </mat-form-field>
                        </div>
                      </div>


                      <div class="inputs-50">
                        <div>
                          <label>{{'Correo Electrónico' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input #email (blur)="checkEmail(email.value,'email')" matInput
                              placeholder="{{'Correo Electrónico' | translate}}" formControlName="email">
                            <mat-error *ngIf="formUser.controls['email'].errors">
                              {{getErrorMessage(formUser.controls['email'])}}
                            </mat-error>
                          </mat-form-field>

                        </div>

                        <div>
                          <label>{{'Correo Electrónico' | translate}} 2</label><br>
                          <mat-form-field appearance="outline">
                            <input #email_2 (blur)="checkEmail(email_2.value,'email_2')" matInput
                              placeholder="{{'Correo Electrónico' | translate}} 2" formControlName="email_2">
                            <mat-error *ngIf="formUser.controls['email_2'].errors">
                              {{getErrorMessage(formUser.controls['email_2'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="inputs-50"
                        *ngIf="formUser.controls.tipo_de_cedula.value == 'FIS' || formUser.controls.tipo_de_cedula.value == 'ALI'">
                        <div>
                          <label>{{'Fecha de nacimiento' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput [matDatepicker]="picker" placeholder="Fecha de nacimiento" [max]="minDate"
                              formControlName="fecha_de_nacimiento">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                          <mat-error *ngIf="formUser.controls['fecha_de_nacimiento'].errors">
                            {{'Debe ser mayor a 18 años para aperturar una cuenta' | translate}}
                          </mat-error>
                        </div>

                        <div>
                          <label>{{'Sexo' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="genero">
                              <mat-option value="M">
                                {{'Masculino' | translate}}
                              </mat-option>
                              <mat-option value="F">
                                {{'Femenino' | translate}}
                              </mat-option>
                              <mat-option value="O">
                                {{'Otro' | translate}}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formUser.controls['genero'].errors">
                              {{getErrorMessage(formUser.controls['genero'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="inputs-50">
                        <div>
                          <label>{{'Celular' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <span style="padding-top: 2px;">+506&nbsp;</span>
                            <input maxlength="8" oninput="this.value=this.value.replace(/[^0-9]/g,'');" matInput
                              placeholder="Celular" formControlName="telefono_movil">
                            <mat-error *ngIf="formUser.controls['telefono_movil'].errors">
                              {{getErrorMessage(formUser.controls['telefono_movil'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div>
                          <label>{{'Teléfono Casa' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <span style="padding-top: 2px;">+506&nbsp;</span>
                            <input maxlength="8" oninput="this.value=this.value.replace(/[^0-9]/g,'');" matInput
                              placeholder="Teléfono Casa" formControlName="telefono_casa">
                            <mat-error *ngIf="formUser.controls['telefono_casa'].errors">
                              {{getErrorMessage(formUser.controls['telefono_casa'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="inputs-50">
                        <div>
                          <label>{{'Teléfono Oficina' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <span style="padding-top: 2px;">+506&nbsp;</span>
                            <input maxlength="8" oninput="this.value=this.value.replace(/[^0-9]/g,'');" matInput
                              maxlength="8" placeholder="Teléfono Oficina" formControlName="telefono_oficina">
                            <mat-error *ngIf="formUser.controls['telefono_oficina'].errors">
                              {{getErrorMessage(formUser.controls['telefono_oficina'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div *ngIf="authService.isAdmin">
                          <label>{{'Cuenta' | translate}} </label><br>
                          <mat-form-field appearance="outline">
                            <input matInput placeholder="{{'Cuenta' | translate}}" formControlName="cuenta">
                            <mat-error *ngIf="formUser.controls['cuenta'].errors">
                              {{getErrorMessage(formUser.controls['cuenta'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                      </div>


                    </div>

                    <div class="inputs-general inputs-form pt-5 pl-5 pr-5" *ngIf="changePassword == true">
                      <div class="inputs-50">
                        <div>
                          <label>{{'Contraseña' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput type="password" placeholder="Contraseña" formControlName="password" #pass>

                            <span style="cursor:pointer" matSuffix mat-flat-button
                              (click)="$event.preventDefault(); pass.type = pass.type == 'password' ? 'text' : 'password' ">
                              <mat-icon color="primary"> remove_red_eye </mat-icon>
                            </span>

                          </mat-form-field>
                        </div>

                        <div>
                          <label>{{'Confirmar contraseña' | translate}}</label><br>
                          <mat-form-field appearance="outline">
                            <input matInput type="password" placeholder="Contraseña" formControlName="confirmPassword"
                              #pass1>

                            <span style="cursor:pointer" matSuffix mat-flat-button
                              (click)="$event.preventDefault(); pass1.type = pass1.type == 'password' ? 'text' : 'password' ">
                              <mat-icon color="primary"> remove_red_eye </mat-icon>
                            </span>

                            <mat-error>
                              {{getErrorMessage(formUser.controls['confirmPassword'])}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                      </div>
                    </div>
                  </div>
                </ng-template>

                <div class="button-bottom text-right pb-5 pl-5 pr-5">
                  <button mat-flat-button color="primary" (click)="editarInfo = true" class="button-consultar"
                    *ngIf="editarInfo === false && user?.delete == 0 && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isCliente)">{{'Editar'
                    | translate}}</button>

                  <button mat-flat-button color="primary" (click)="editarInfo = true; changePassword = true"
                    class="button-consultar"
                    *ngIf="editarInfo === false && user?.delete == 0 && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isCliente)">{{'Cambiar
                    Contraseña' | translate}}'</button>

                  <button mat-flat-button color="primary" (click)="changeFormUser(formUser.value);"
                    class="button-calcular" *ngIf="editarInfo">{{'Guardar Cambios' |
                    translate}}
                  </button>
                </div>
              </form>
            </div>
          </tab>

          <tab
            *ngIf="authService.isAdmin || authService.isServicioCliente || authService.isOperacionesMiami || authService.isOperacionesCostaRica || authService.isVendedor || authService.isContabilidad"
            (selectTab)="onSelect('Administrativo')">
            <ng-template tabHeading>
              <mat-icon>manage_accounts</mat-icon> {{'Administrativo' | translate}}
            </ng-template>

            <form [formGroup]="formAdministrativo" class="form pt-5">

              <div class="container table-contacto pt-5 pl-5 pr-5"
                *ngIf="!editarInfoAdministrativo; else elseBlockAdministrativo;">
                <table style="width:100%">
                  <tr *ngIf="!authService.isVendedor">
                    <td class="p-td">{{'Rol' | translate}}:</td>
                    <td class="p-td">{{formAdministrativo.controls.rol.value != null ?
                      getRolName(formAdministrativo.controls.rol.value) : ''}}</td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Tipo de Cliente:</td>
                    <td class="p-td">
                      <span *ngFor="let tipo of tipoClientes" class="m-2 px-4"
                        style="border-radius: 15px; font-size:24px; color: white !important"
                        [style.background-color]="tipo.color">{{ tipo.nombre | translate}}</span>
                    </td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT' && !authService.isVendedor">
                    <td class="p-td">Anotación:</td>
                    <td class="p-td">{{formAdministrativo.controls.anotacion.value != null ?
                      formAdministrativo.controls.anotacion.value : 'Sin anotación'}}</td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Tarifa Aérea:</td>
                    <td class="p-td">
                      {{formAdministrativo.controls.tarifaA.value != null ?
                      getTipoTarifaA(formAdministrativo.controls.tarifaA.value) : '-'}}
                    </td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Tarifa Marítima:</td>
                    <td class="p-td">
                      {{formAdministrativo.controls.tarifaM.value != null ?
                      getTipoTarifaM(formAdministrativo.controls.tarifaM.value) : '-'}}
                    </td>
                  </tr>

                  <tr
                    *ngIf="user?.rol == 'CLIENT' && (user.tipo_de_cedula == 'ALI' || formAdministrativo.controls.alianza.value != null)">
                    <td class="p-td">Alianza:</td>
                    <td class="p-td">{{ getAlianza(formAdministrativo.controls.alianza.value) }}</td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Vendedor:</td>
                    <td class="p-td">{{ getVendedor(formAdministrativo.controls.vendedor.value) }}</td>
                  </tr>

                  <!--  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Última Exoneración:</td>
                    <td class="p-td" *ngIf="user.ultima_exoneracion != null">{{user.ultima_exoneracion[0] | date:
                      'dd-MM-yyyy' }}</td>
                    <td class="p-td" *ngIf="user.ultima_exoneracion == null">{{'-'}}</td>
                  </tr> -->

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Pago Electrónico:</td>
                    <td class="p-td">{{formAdministrativo.controls.pago_tarjeta.value != null ?
                      formAdministrativo.controls.pago_tarjeta.value ? 'Activo' : 'Bloqueado' : 'Invalido'}}</td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Correo Verificado:</td>
                    <td class="p-td">{{formAdministrativo.controls.enable.value != null ?
                      formAdministrativo.controls.enable.value ? 'Verificado' : 'No Verificado' : 'Invalido'}}</td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Preferencia GTI:</td>
                    <td class="p-td">{{formAdministrativo.controls.preferir_factura.value == true ? 'Factura
                      Electrónica' : 'Ticket Electrónico'}}</td>
                  </tr>

                  <tr *ngIf="user?.rol == 'CLIENT'">
                    <td class="p-td">Exento IVA:</td>
                    <td class="p-td">{{formAdministrativo.controls.exento_iva.value == true ? 'Sí': 'No'}}</td>
                  </tr>

                  <tr>
                    <td class="p-td">Estado:</td>
                    <td class="p-td">{{formAdministrativo.controls.is_active.value != null ?
                      formAdministrativo.controls.is_active.value ? 'Activo' : 'Inactivo' : 'Invalido'}}</td>
                  </tr>
                  <tr *ngIf="authService.isAdmin && user?.rol == 'CLIENT'">
                    <td class="p-td">{{'Contrato Aceptado' | translate}}</td>
                    <td class="p-td">{{user?.consent ? 'Sí' : 'No' }}
                    </td>
                  </tr>

                  <tr *ngIf="authService.isAdmin && user?.rol == 'CLIENT'">
                    <td class="p-td">{{'Cuenta exenta de pago' | translate}}</td>
                    <td class="p-td">{{user?.charge_exempt ? 'Sí' : 'No' }}
                    </td>
                  </tr>

                </table>
              </div>

              <ng-template #elseBlockAdministrativo>
                <div class="container">
                  <div class="inputs-general inputs-form pt-5 pl-5 pr-5">
                    <div class="i">
                      <div *ngIf="authService.isAdmin || authService.isOperacionesCostaRica">
                        <label class="label">Rol</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-select matInput placeholder="Rol" required formControlName="rol">
                            <mat-option *ngFor="let rol of roles" [value]="rol.value">
                              {{rol.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="mb-2"
                        *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isOperacionesCostaRica)">
                        <label class="label">Tipos de Cliente</label><br>

                        <mat-chip-list #chipList aria-label="Tipos de Cliente">
                          <mat-chip [style.background-color]="tipo.color" *ngFor="let tipo of tipoClientes"
                            (removed)="remove(tipo)">
                            {{tipo.nombre}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Seleccione" #tipoClienteInput [formControl]="tipoCtrl"
                            [matAutocomplete]="auto" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)"
                          [displayWith]="displayFn">
                          <mat-option [style.background-color]="tipo.color" *ngFor="let tipo of filteredTipos | async"
                            [value]="tipo">
                            {{tipo.nombre}}
                          </mat-option>
                        </mat-autocomplete>

                      </div>

                      <div class="pb-4">
                        <label class="label">Anotación</label><br>

                        <textarea class="w-100" rows="3" matInput placeholder="Anotación" formControlName="anotacion"
                          (change)="anotacionChange = true">
                        </textarea>

                      </div>

                      <div *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isOperacionesCostaRica)">
                        <label class="label">Tarifa Aérea</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-select matInput placeholder="Tarifa Aérea" required formControlName="tarifaA">
                            <mat-option *ngFor="let tarifa of tarifasAereas" [value]="tarifa.id">{{tarifa.nombre}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isOperacionesCostaRica)">
                        <label class="label">Tarifa Marítima</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-select matInput placeholder="Tarifa Marítima" required formControlName="tarifaM">
                            <mat-option *ngFor="let tarifa of tarifasMaritimas" [value]="tarifa.id">{{tarifa.nombre}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isOperacionesCostaRica)">
                        <label class="label">Alianza</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-select formControlName="alianza" placeholder="Seleccione">
                            <mat-option value="">Ninguna</mat-option>
                            <mat-option *ngFor="let alianza of alianzas"
                              [value]="alianza.id">{{alianza.nombre}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isOperacionesCostaRica)">
                        <label class="label">Vendedor</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-select matInput placeholder="Vendedor" formControlName="vendedor">
                            <mat-option value="">Ninguno</mat-option>
                            <mat-option *ngFor="let v of vendedores" [value]="v.id">{{usuariosService.get_fullname(v)}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isOperacionesCostaRica)">
                        <label class="label">Pago Electrónico</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-select matInput placeholder="Pago en Línea" required formControlName="pago_tarjeta">
                            <mat-option *ngFor="let estado of estadosCliente" [value]="estado.value">{{estado.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT'">
                        <label class="label">Correo Verificado</label><br>
                        <button mat-flat-button color="primary" (click)="verificarEmail()" class="button-calcular"
                          *ngIf="!formAdministrativo.controls.enable.value">Verificar</button>

                        <mat-form-field appearance="outline" matTooltip="Este campo no se puede modificar"
                          *ngIf="formAdministrativo.controls.enable.value">
                          <input matInput value="Verificado" readonly>
                        </mat-form-field>

                        <button mat-flat-button color="primary" (click)="verificarEmail()" class="button-calcular"
                          *ngIf="formAdministrativo.controls.enable.value">
                          Reenviar Correo de Bienvenida
                        </button>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT'">
                        <label class="label">Factura Electrónica</label><br>
                        <mat-checkbox class="example-margin" formControlName="preferir_factura">Preferir Factura
                          Electrónica</mat-checkbox>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT'">
                        <label class="label">Exento IVA</label><br>
                        <mat-checkbox class="example-margin" formControlName="exento_iva">Exento IVA</mat-checkbox>
                      </div>

                      <div *ngIf="(authService.isAdmin || authService.isOperacionesCostaRica)">
                        <label class="label">Estado</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-select matInput placeholder="Estado" required formControlName="is_active">
                            <mat-option *ngFor="let estado of estadosCliente" [value]="estado.value">{{estado.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="user?.rol == 'CLIENT' && authService.validateRolePermission('EXONERATE_CHARGE')">
                        <label class="label">Cargo exento</label><br>
                        <mat-checkbox class="example-margin" formControlName="charge_exempt">Cargo exento</mat-checkbox>
                        <p style="color: gray;">Este rubro determina si el cliente se encuentra exento del cobro de
                          paquetes</p>
                      </div>

                      <div *ngIf="authService.validateRolePermission('DELETE_ACCOUNT')">
                        <label class="label">Eliminar Cuenta</label><br>

                        <button mat-flat-button style="background-color: red !important; color: white !important"
                          (click)="deleteCuenta()">
                          <mat-icon>delete</mat-icon> Eliminar Cuenta
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </ng-template>

              <div class="button-bottom text-right pb-5 pl-5 pr-5">
                <button mat-flat-button color="primary" (click)="editarInfoAdministrativo = true"
                  class="button-consultar"
                  *ngIf="(!editarInfoAdministrativo && user?.delete == 0) && (authService.isAdmin || authService.isServicioCliente || authService.isContabilidad)">Editar</button>
                <button mat-flat-button color="primary"
                  (click)="changeFormAdministrativo(formAdministrativo.value); editarInfoAdministrativo = false"
                  class="button-calcular" *ngIf="editarInfoAdministrativo">Guardar Cambios</button>
              </div>
            </form>
          </tab>

          <tab
            *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesMiami || authService.isOperacionesCostaRica || authService.isVendedor)"
            (selectTab)="onSelect('Prealertas')">
            <ng-template tabHeading>
              <mat-icon>notifications</mat-icon> Prealertas ({{ prealertados}})
            </ng-template>

            <div class="content-table m-4">
              <div class="text-center" *ngIf="dataSourcePrealerts.length == 0">
                <p>No tiene paquetes prealertados</p>
              </div>
              <table mat-table
                [dataSource]="dataSourcePrealerts   | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
                matSort *ngIf="dataSourcePrealerts.length > 0" style="width: 100%;">

                <!-- guia Column -->
                <ng-container matColumnDef="guia">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    <a [routerLink]="['/admin/paquetes/paquete']"
                      [queryParams]="{pack: element.id, origin: 'prealertas'}">
                      {{element.numero_de_guia? element.numero_de_guia : '-'}}
                    </a>
                  </td>
                </ng-container>

                <!-- estado Column -->
                <ng-container matColumnDef="estado">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado_actual ?
                    element.estado_actual.estado:
                    '-'}}
                  </td>
                </ng-container>

                <!-- envio Column -->
                <ng-container matColumnDef="nombre">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre de Usuario </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.nombre? element.nombre
                    :
                    '-'}}
                  </td>
                </ng-container>

                <!-- cuenta Column -->
                <ng-container matColumnDef="cuenta">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">{{element.cuenta? element.cuenta :
                    '-'}}
                  </td>
                </ng-container>

                <!-- tracking Column -->
                <ng-container matColumnDef="tracking">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking?
                    element.tracking :
                    '-'}} </td>
                </ng-container>

                <!-- descripcion Column -->
                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.descripcion? element.descripcion : '-'}}
                  </td>
                </ng-container>

                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Status </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.status? element.status
                    :
                    '-'}}
                  </td>
                </ng-container>

                <!-- envio Column -->
                <ng-container matColumnDef="envio">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Método de Envío </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.envio? element.envio :
                    '-'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="creacion">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creacion</th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.creacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short',
                    day: 'numeric' })}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="modificado">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Última actualización</th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.updated_at | date: 'dd/MM/yyyy'}}&nbsp;-&nbsp;{{element.estado_actual.estado}}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsPrealerts; sticky: true"
                  [ngClass]="{'highlight-top-header': true }"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsPrealerts; let odd = odd; "
                  [ngClass]="{'highlight': odd }"></tr>
              </table>
            </div>

            <div class="col-lg-12 pb-4">
              <div class="col-lg-12 text-center">
                <div class="paginator" *ngIf="dataSourcePrealerts.length > 0">
                  <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
                    (pageChange)="pageIndex = $event; paginate(pageIndex,'prealertas')"></pagination-controls>
                </div>
              </div>
            </div>

          </tab>

          <tab
            *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesMiami || authService.isOperacionesCostaRica || authService.isVendedor  || authService.isContabilidad)"
            (selectTab)="onSelect('Paquetes')">
            <ng-template tabHeading>
              <mat-icon>shopping_cart</mat-icon>Paquetes ({{paquetesClientes}})
            </ng-template>

            <div class="content-table m-4">
              <div class="text-center" *ngIf="dataSourcePackages.length == 0">
                <p>No hay paquetes disponibles para mostrar</p>
              </div>
              <div class="col-3" style="display: flex; justify-content: center; min-width: 90px;">
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-select style="font-size: 16px;" [ngModel]="statusPaymentFilter"
                    (ngModelChange)="filterByPaymentStatus($event)">
                    <mat-option value="0">
                      No pagados
                    </mat-option>
                    <mat-option value="1">
                      Pagados
                    </mat-option>
                    <mat-option value="">
                      Todos
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-6 col-md-3"
                  *ngIf="dataSourcePackages.length > 0 && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isContabilidad) "
                  style="text-align: center;">
                  <div>
                    <h3>PENDIENTE DE PAGO:</h3>
                  </div>

                  <div>
                    <h3> ¢{{por_pagar_colones | number : '1.2-2' }}&nbsp;&nbsp; ${{por_pagar | number : '1.2-2' }} </h3>
                  </div>
                </div>

                <div class="col-6 col-md-3"
                  *ngIf="dataSourcePackages.length > 0 && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isContabilidad)"
                  style="text-align: center;">
                  <div>
                    <h3>TOTAL SELECCIONADO:</h3>
                  </div>

                  <div>
                    <h3> ¢{{totalSeleccionadoCRC | number : '1.2-2' }}&nbsp;&nbsp; ${{totalSeleccionadoUSD | number :
                      '1.2-2' }} </h3>
                  </div>
                </div>

                <div *ngIf="dataSourcePackages.length > 0 && authService.validateRolePermission('ACCOUNT_OPTIONS')"
                  class="col-6 col-md-6" style="float: right;">

                  <!-- <button id="generar-guia" mat-flat-button color="primary" class="button-calcular mr-2" matTooltip="Registrar Envío/Guía Correo de Costa Rica"
                  (click)="generarEncomienda()" [disabled]="paquetesEntregas.length == 0" style="float: right;">
                    <img style="height: 35px;" src="assets/logo_correos.png" alt="Generar Guia"> ({{paquetesEntregas.length}})
                  </button> -->

                  <button id="adjuntar-pago" mat-flat-button color="primary" class="button-calcular mr-2"
                    (click)="adjuntarPago()" [disabled]="packagesToPay.length == 0" style="float: right;">
                    Adjuntar Pago ({{packagesToPay.length}})
                  </button>

                  <button id="entregado" mat-flat-button color="primary" class="button-calcular mr-2"
                    (click)="marcarEntregado()" [disabled]="paquetesEntregas.length == 0" style="float: right;"
                    *ngIf="!authService.isContabilidad">
                    Entregado ({{paquetesEntregas.length}})
                  </button>

                  <button id="estado-cuenta" mat-flat-button color="primary" class="button-calcular mr-2"
                    (click)="generateEstadoCuenta()" style="float: right;"
                    *ngIf="authService.validateRolePermission('CHECK_ACCOUNT_STATE')">
                    Estado de Cuenta
                  </button>


                </div>
              </div>
              <table mat-table
                [dataSource]="dataSourcePackages | paginate: {itemsPerPage: packagePageSize, currentPage: pageIndex, totalItems:length}"
                matSort *ngIf="dataSourcePackages.length > 0" style="width: 100%;"
                (matSortChange)="sortDataPaquetes($event)">

                <!--seleccionar-->
                <ng-container matColumnDef="seleccionar">
                  <th mat-header-cell class="pr-pl-20 text-center" *matHeaderCellDef mat-sort-header [disabled]="true"
                    style="width: 50px;">
                    <mat-checkbox class="d-flex justify-content-center" (change)="masterToggle($event)"
                      aria-label="Seleccionar/Deseleccionar Todo" title="Seleccionar/Deseleccionar Todo"></mat-checkbox>
                  </th>

                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let row">
                    <mat-checkbox class="d-flex justify-content-center" (click)="$event.stopPropagation()"
                      [disabled]="row.factura == null || row.estado_actual.id < 12" (change)="changeCheck($event,row)"
                      [checked]="selectedIds.isSelected(row.id)" [aria-label]="checkboxLabel(row)"></mat-checkbox>
                  </td>
                </ng-container>

                <!-- guia Column -->
                <ng-container matColumnDef="guia">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    <a [routerLink]="['/admin/paquetes/paquete']"
                      [queryParams]="{pack: element.id, origin: 'paquetes'}">
                      {{element.numero_de_guia ? element.numero_de_guia : '-'}}
                    </a>
                  </td>
                </ng-container>

                <!-- Order Column -->
                <ng-container matColumnDef="order">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Orden </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    <span
                      *ngIf="element.paquete_contenedor && element.paquete_contenedor.startsWith('O-'); else noLink">
                      <a [routerLink]="['/admin/paquetes/paquete']"
                        [queryParams]="{ pack: element.paquete_contenedor.split('-')[1], origin: 'paquetes' }">
                        {{ element.paquete_contenedor }}
                      </a>
                    </span>
                    <ng-template #noLink>
                      <span>-</span>
                    </ng-template>
                  </td>
                </ng-container>

                <!-- estado Column -->
                <ng-container matColumnDef="estado">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado_actual ?
                    element.estado_actual.estado:
                    '-'}}
                  </td>
                </ng-container>

                <!-- envio Column -->
                <ng-container matColumnDef="nombre">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Nombre de Usuario </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.nombre? element.nombre
                    :
                    '-'}}
                  </td>
                </ng-container>

                <!-- cuenta Column -->
                <ng-container matColumnDef="cuenta">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Cuenta </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">{{element.cuenta? element.cuenta :
                    '-'}}
                  </td>
                </ng-container>

                <!-- tracking Column -->
                <ng-container matColumnDef="tracking">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tracking </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.tracking?
                    element.tracking :
                    '-'}} </td>
                </ng-container>

                <!-- descripcion Column -->
                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.descripcion? element.descripcion : '-'}}
                  </td>
                </ng-container>

                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Status </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.status? element.status
                    :
                    '-'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="tipo_de_paquete">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Tipo Paquete </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">

                    <span *ngIf="element.tipo_de_paquete === 'Caja'"><i class="fa fa-archive" aria-hidden="true"
                        style="font-size:x-large; color: #119ea8"></i></span>
                    <span *ngIf="element.tipo_de_paquete === 'SobreBolsa'"><i class="fa fa-shopping-bag"
                        aria-hidden="true" style="font-size:x-large; color: #119ea8"></i></span>
                    <span *ngIf="element.tipo_de_paquete === 'Correspondencia'"><i class="fa fa-envelope"
                        aria-hidden="true" style="font-size:x-large; color: #119ea8"></i></span>

                  </td>
                </ng-container>

                <ng-container matColumnDef="peso_real">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Peso Real </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.peso? element.peso :
                    '-'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="monto_por_cancelar">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Pendiente USD</th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    <span *ngIf="!element.factura; else con_factura">-</span>

                    <ng-template #con_factura>
                      <span
                        *ngIf="element.estado_actual.id >= 12  && ( (!authService.isServicioCliente) || (authService.isServicioCliente && element.clase != 'D')); else sin_factura ">
                        ${{element.factura?.monto_pendiente | number : '1.2-2'}}
                      </span>
                    </ng-template>

                    <ng-template #sin_factura>
                      <span>-</span>
                    </ng-template>

                  </td>
                </ng-container>

                <ng-container matColumnDef="monto_por_cancelar_crc">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header>Monto Pendiente CRC</th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    <span *ngIf="!element.factura; else con_factura">-</span>

                    <ng-template #con_factura>
                      <span
                        *ngIf="element.estado_actual.id >= 12 && ( (!authService.isServicioCliente) || (authService.isServicioCliente && element.clase != 'D')); else sin_factura ">
                        ₡{{element.factura?.factura_crc.monto_pendiente | number : '1.2-2'}}
                      </span>
                    </ng-template>

                    <ng-template #sin_factura>
                      <span>-</span>
                    </ng-template>

                  </td>
                </ng-container>

                <!--Created_at column-->
                <ng-container matColumnDef="creado">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.created_at ? (element.created_at | dateFormat) : '-'}}
                  </td>
                </ng-container>

                <!-- envio Column -->
                <ng-container matColumnDef="envio">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Método de Envío </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{ element.envio || '-'}}
                  </td>
                </ng-container>

                <!-- zona Column -->
                <ng-container matColumnDef="zona">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Zona </th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.entrega?.zona_centro_distribucion || '-'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="creacion">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de Creacion</th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.creacion.toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short',
                    day: 'numeric' })}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="modificado">
                  <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Última actualización</th>
                  <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                    {{element.updated_at | date: 'dd/MM/yyyy'}}&nbsp;-&nbsp;{{element.estado_actual.estado}}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsPackages; sticky: true"
                  [ngClass]="{'highlight-top-header': true }"></tr>

                <tr mat-row [class.valid]="row.estado_pago == 1" [class.novalid]="row.estado_pago == 0"
                  *matRowDef="let row; columns: displayedColumnsPackages; let odd = odd;">
                </tr>
              </table>

            </div>
            <div class="col-lg-12 pb-4" *ngIf="isPaginatorAvailable()">
              <div class="col-lg-12 text-center">
                <div class="paginator">
                  <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
                    (pageChange)="pageIndex = $event; paginate(pageIndex,'paquetes')"></pagination-controls>
                </div>
              </div>
            </div>

            <div *ngIf="dataSourcePackages.length > 0" style="text-align: center; margin-bottom: 10px;">
              <h5> Los paquetes marcados en rojo están pendiente por pagar </h5>
            </div>
          </tab>

          <tab
            *ngIf="user?.rol == 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isVendedor)"
            (selectTab)="onSelect('Entregas')">
            <ng-template tabHeading>
              <mat-icon>local_shipping</mat-icon>Entregas Programadas ({{entregasProgramadas}})
            </ng-template>
            <div class="tab">

              <div style="overflow: auto;" class="content-table m-4">
                <div class="text-center" *ngIf="dataSourceDelivers.length == 0">
                  <p>No hay entregas disponibles para mostrar</p>
                </div>
                <table mat-table
                  [dataSource]="dataSourceDelivers  | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length} "
                  matSort *ngIf="dataSourceDelivers.length > 0" style="width: 100%;">

                  <!-- guia Column -->
                  <ng-container matColumnDef="guia">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Guía </th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                      <a [routerLink]="['/admin/paquetes/paquete']"
                        [queryParams]="{pack: element.id, origin: 'entregas'}">
                        {{element.numero_de_guia? element.numero_de_guia : '-'}}
                      </a>
                    </td>
                  </ng-container>

                  <!-- descripcion Column -->
                  <ng-container matColumnDef="descripcion">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Descripción
                    <th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.descripcion ?
                      element.descripcion : '-'}}
                    </td>
                  </ng-container>

                  <!-- programada Column -->
                  <ng-container matColumnDef="programada">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha Programada </th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.fecha_entrega ?
                      element.fecha_entrega:
                      '-'}}
                    </td>
                  </ng-container>

                  <!-- metodo pago Column -->
                  <ng-container matColumnDef="metodo_pago">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Método de Pago </th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.metodo_pago ?
                      element.metodo_pago.nombre:
                      '-'}}
                    </td>
                  </ng-container>

                  <!-- asignado Column -->
                  <ng-container matColumnDef="asignado">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Asignada
                    <th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                      <span *ngIf="!element.entrega_asignado"> {{element.entrega_asignado ? 'Asignada':
                        'Pendiente'}}</span>
                      <a *ngIf="element.entrega_asignado" [routerLink]="['/admin/almacen/entregas/agregar']"
                        [queryParams]="{pack: element.entrega_detalle[0]?.id}">
                        {{'Asignada'}}
                      </a>
                    </td>
                  </ng-container>

                  <!-- Observación Column -->
                  <ng-container matColumnDef="observacion">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Observación
                    <th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.entrega_observacion ?
                      element.entrega_observacion:
                      '-'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_confirmada">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha Asignada
                    <th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                      <span *ngIf="element.entrega_detalle"> {{element.entrega_detalle[0]?.fecha | date: 'dd-MM-yyyy'}}
                      </span>
                      <span *ngIf="!element.entrega_detalle"> {{'Pendiente por asignar'}}</span>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="hora_entrega">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Fecha de entrega
                    <th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.hora_entrega ?
                      element.hora_entrega:
                      'Pendiente por entregar'}}
                    </td>
                  </ng-container>

                  <!-- estado de entrega Column -->
                  <ng-container matColumnDef="estado">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.estado_actual ?
                      element.estado_actual.estado:
                      '-'}}
                    </td>
                  </ng-container>

                  <!-- direccion_entrega Column -->
                  <ng-container matColumnDef="direccion_entrega">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Dirección de Entrega </th>
                    <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element"> {{element.direccion_entrega ?
                      element.direccion_entrega:
                      '-'}}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsDelivers; sticky: false"
                    [ngClass]="{'highlight-top-header': true }"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsDelivers;"></tr>
                </table>
              </div>

              <div class="col-lg-12 pb-4">
                <div class="col-lg-12 text-center">
                  <div class="paginator">
                    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior"
                      nextLabel="Siguiente" (pageChange)="pageIndex = $event; paginate(pageIndex,'delivers')">
                    </pagination-controls>

                  </div>
                </div>
              </div>

            </div>
          </tab>

          <tab
            *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica)"
            (selectTab)="onSelect('NotasCredito')">
            <ng-template tabHeading>
              <mat-icon>short_text</mat-icon>Notas de Crédito ({{this.notasCredito}})
            </ng-template>

            <div class="tab">

              <div style="overflow: auto;" class="content-table m-4">
                <div class="text-center" *ngIf="dataSourceNotasCredito.length == 0">
                  <p>No hay notas de crédito disponibles para mostrar</p>
                </div>

                <table mat-table
                  [dataSource]="dataSourceNotasCredito | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
                  matSort *ngIf="dataSourceNotasCredito.length > 0">

                  <ng-container matColumnDef="id">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Nro. </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.id}} </td>
                  </ng-container>

                  <ng-container matColumnDef="factura">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Factura </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{element.factura.numero}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="guia">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Guía </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      <a [routerLink]="['/admin/paquetes/paquete']"
                        [queryParams]="{pack: element.paquete[0]?.id, origin: 'notacredito'}">
                        {{ element.paquete[0]?.numero_de_guia || '-'}}
                      </a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="cuenta">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{usuariosService.get_fullaccount(element.user)}} </td>
                  </ng-container>

                  <ng-container matColumnDef="descripcion">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Descripción </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
                  </ng-container>

                  <ng-container matColumnDef="monto">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Monto (TOTAL) </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto | currency}} </td>
                  </ng-container>

                  <ng-container matColumnDef="user">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.user}} </td>
                  </ng-container>

                  <ng-container matColumnDef="creador">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Creador </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{usuariosService.get_fullaccount(element.created_by)}}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsNotasCredito"
                    [ngClass]="{'highlight-top-header': true }"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsNotasCredito; let odd = odd; "
                    [ngClass]="{'highlight': odd }"></tr>
                </table>
              </div>

              <div class="col-lg-12 pb-4">
                <div class="col-lg-12 text-center">
                  <div class="paginator">
                    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior"
                      nextLabel="Siguiente" (pageChange)="pageIndex = $event; paginate(pageIndex,'notasCredito')">
                    </pagination-controls>
                  </div>
                </div>
              </div>

            </div>
          </tab>

          <tab
            *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica)"
            (selectTab)="onSelect('NotasDebito')">
            <ng-template tabHeading>
              <mat-icon>short_text</mat-icon>Notas de Débito ({{notasDebito}})
            </ng-template>

            <div class="tab">

              <div style="overflow: auto;" class="content-table m-4">
                <div class="text-center" *ngIf="dataSourceNotasDebito.length == 0">
                  <p>No hay notas de débito disponibles para mostrar</p>
                </div>

                <table mat-table
                  [dataSource]="dataSourceNotasDebito | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}"
                  matSort *ngIf="dataSourceNotasDebito.length > 0">

                  <ng-container matColumnDef="id">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Nro. </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.id}} </td>
                  </ng-container>

                  <ng-container matColumnDef="factura">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Factura </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{element.factura.numero}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="guia">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Guía </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      <a [routerLink]="['/admin/paquetes/paquete']"
                        [queryParams]="{pack: element.paquete[0].id, origin: 'notadebito'}">
                        {{element.paquete[0].numero_de_guia ? element.paquete[0].numero_de_guia : '-'}}
                      </a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Fecha </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="cuenta">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{usuariosService.get_fullaccount(element.user)}} </td>
                  </ng-container>

                  <ng-container matColumnDef="descripcion">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Descripción </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.descripcion}} </td>
                  </ng-container>

                  <ng-container matColumnDef="monto">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Monto (TOTAL) </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.monto | currency}} </td>
                  </ng-container>

                  <ng-container matColumnDef="pendiente">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Monto (Pendiente) </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{ element.pago ? element.monto -
                      element.pago.monto : element.monto | currency}} </td>
                  </ng-container>

                  <ng-container matColumnDef="user">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.user}} </td>
                  </ng-container>

                  <ng-container matColumnDef="creador">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Creador </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{usuariosService.get_fullaccount(element.created_by)}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="opciones">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Estado </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      <button id="adjuntar-pago-nota" mat-flat-button color="primary" class="button-calcular mr-5"
                        (click)="adjuntarPagoNota(element)" style="float: right;" *ngIf="!element.pago">
                        Adjuntar Pago
                      </button>

                      <strong *ngIf="element.pago">Pagada</strong>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsNotasDebito"
                    [ngClass]="{'highlight-top-header': true }"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsNotasDebito; let odd = odd; "
                    [ngClass]="{'highlight': odd }"></tr>
                </table>
              </div>

              <div class="col-lg-12 pb-4">
                <div class="col-lg-12 text-center">
                  <div class="paginator">
                    <pagination-controls autoHide="false" responsive="true" previousLabel="Anterior"
                      nextLabel="Siguiente" (pageChange)="pageIndex = $event; paginate(pageIndex,'notasDebito')">
                    </pagination-controls>
                  </div>
                </div>
              </div>

            </div>
          </tab>

          <tab
            *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isVendedor)"
            (selectTab)="onSelect('Referidos')">
            <ng-template tabHeading>
              <mat-icon>people</mat-icon>Referidos
            </ng-template>

            <div class="tab">
              <div class="content-table m-4">

                <div class="text-center" *ngIf="dataSourceReferidos.length == 0">
                  <p>No hay referidos para mostrar</p>
                </div>

                <table mat-table [dataSource]="dataSourceReferidos" *ngIf="dataSourceReferidos.length > 0"
                  style="width: 100%;">

                  <!-- guia Column -->
                  <ng-container matColumnDef="cuenta">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Cuenta </th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      <a [routerLink]="['/admin/perfil']" [queryParams]="{cuenta: element.id}"
                        style="cursor: pointer;">{{usuariosService.get_fullaccount(element)}}</a>
                    </td>
                  </ng-container>

                  <!-- descripcion Column -->
                  <ng-container matColumnDef="reclamado">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Aplicado
                    <th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{element.descuento_aplicado ? 'Aplicado' : 'No Aplicado'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Usuario desde:
                    <th>
                    <td mat-cell class="pr-pl-20" *matCellDef="let element">
                      {{element.created_at | date : 'dd-MM-yyyy'}}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsReferidos; sticky: true"
                    [ngClass]="{'highlight-top-header': true }"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsReferidos;"></tr>
                </table>
              </div>
            </div>

          </tab>

          <!-- Tabs Horizontales Usuarios -->
          <tab *ngIf="user?.rol== 'CLIENT' && authService.isCliente" (selectTab)="onSelect('Entrega')"
            id="perfilEntrega">
            <ng-template tabHeading>
              <mat-icon>local_shipping</mat-icon> {{'Entrega' | translate}}
            </ng-template>

            <ng-container *ngTemplateOutlet="entrega"></ng-container>
          </tab>

          <tab *ngIf="user?.rol== 'CLIENT' && authService.isCliente" (selectTab)="onSelect('Autorizaciones')">
            <ng-template tabHeading>
              <mat-icon>security</mat-icon> {{'Autorizaciones' | translate}}
            </ng-template>

            <ng-container *ngTemplateOutlet="autorizaciones"></ng-container>
          </tab>


          <tab *ngIf="user?.rol== 'CLIENT' && authService.isCliente" (selectTab)="onSelect('Factura')">
            <ng-template tabHeading>
              <mat-icon>text_snippet</mat-icon> {{'Factura Electrónica' | translate}}
            </ng-template>

            <ng-container *ngTemplateOutlet="facturaElectronica"></ng-container>
          </tab>

          <tab *ngIf="user?.rol== 'CLIENT' && authService.isCliente" (selectTab)="onSelect('Tarjetas')">
            <ng-template tabHeading>
              <mat-icon>credit_card</mat-icon> {{'Tarjetas' | translate}}
            </ng-template>

            <ng-container *ngTemplateOutlet="tarjetas"></ng-container>
          </tab>

          <tab *ngIf="user?.rol== 'CLIENT' && authService.isCliente" (selectTab)="onSelect('Notificaciones')">
            <ng-template tabHeading>
              <mat-icon>notifications</mat-icon> {{'Notificaciones' | translate}}
            </ng-template>

            <ng-container *ngTemplateOutlet="notificaciones"></ng-container>
          </tab>

          <tab *ngIf="authService.isCliente" (selectTab)="onSelect('Seguridad')">
            <ng-template tabHeading>
              <mat-icon>security</mat-icon> {{'Seguridad' | translate}}
            </ng-template>

            <ng-container *ngTemplateOutlet="twofa"></ng-container>
          </tab>

        </tabset>
      </div>
    </mat-tab>

    <mat-tab
      *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isVendedor || authService.isOperacionesMiami)">
      <ng-template mat-tab-label>
        <mat-icon>local_shipping</mat-icon>{{'Entrega' | translate}}
      </ng-template>

      <ng-container *ngTemplateOutlet="entrega"></ng-container>

    </mat-tab>

    <mat-tab
      *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isVendedor)">
      <ng-template mat-tab-label>
        <mat-icon>security</mat-icon>{{'Autorizaciones' | translate}}
      </ng-template>

      <ng-container *ngTemplateOutlet="autorizaciones"></ng-container>

    </mat-tab>

    <mat-tab
      *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isVendedor)">
      <ng-template mat-tab-label>
        <mat-icon>notifications</mat-icon> {{'Notificaciones' | translate}}
      </ng-template>

      <ng-container *ngTemplateOutlet="notificaciones"></ng-container>
    </mat-tab>

    <mat-tab
      *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica)">
      <ng-template mat-tab-label>
        <mat-icon>credit_card</mat-icon> {{'Tarjetas' | translate}}
      </ng-template>

      <ng-container *ngTemplateOutlet="tarjetas"></ng-container>
    </mat-tab>

    <mat-tab
      *ngIf="user?.rol== 'CLIENT' && (authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica || authService.isVendedor)">
      <ng-template mat-tab-label>
        <mat-icon>text_snippet</mat-icon> {{'Factura Electrónica' | translate}}
      </ng-template>

      <ng-container *ngTemplateOutlet="facturaElectronica"></ng-container>
    </mat-tab>

    <mat-tab *ngIf="authService.isAdmin || authService.isServicioCliente || authService.isOperacionesCostaRica">
      <ng-template mat-tab-label>
        <mat-icon>security</mat-icon> {{'Seguridad' | translate}}
      </ng-template>

      <ng-container *ngTemplateOutlet="twofa"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Template Factura Electrónica -->
<ng-template #facturaElectronica>
  <form [formGroup]="formFactura" class="form">
    <div class="container table-contacto pt-5 pl-5 pr-5" *ngIf="!editarInfoFactura; else elseBlockFactura;">
      <table style="width:100%">
        <tr>
          <td class="p-td">{{'Tipo de Persona' | translate}}:</td>
          <td class="p-td"
            *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI'">
            {{'Física' | translate}}</td>
          <td class="p-td" *ngIf="formFactura.controls.tipo_factura.value == 'JUR'">{{'Jurídica' | translate}}</td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI'">
          <td class="p-td">{{'Nombre' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.nombre.value}}</td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI'">
          <td class="p-td">{{'Primer Apellido' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.primer_apellido.value}}</td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI'">
          <td class="p-td">{{'Segundo Apellido' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.segundo_apellido.value}}</td>
        </tr>
        <tr *ngIf="formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Razón Social' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.razon_social.value}}</td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Cédula' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.numero_identificacion.value}}</td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Correo Electrónico' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.correo.value}}</td>
        </tr>

        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Teléfono' | translate}}:</td>
          <td class="p-td">+506&nbsp;{{formFactura.controls.telefono.value}}</td>
        </tr>

        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Dirección de Facturación' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.direccion.value}}</td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Provincia' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.provincia.value != null ?
            getProvincia(formFactura.controls.provincia.value) :
            ''}}</td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Cantón' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.canton.value != null ? getCanton(formFactura.controls.canton.value) :
            ''}} </td>
        </tr>
        <tr
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <td class="p-td">{{'Distrito' | translate}}:</td>
          <td class="p-td">{{formFactura.controls.distrito.value != null ?
            getDistrito(formFactura.controls.distrito.value) :
            ''}} </td>
        </tr>
      </table>
    </div>

    <ng-template #elseBlockFactura>
      <div class="inputs-general inputs-form pt-5 pl-5 pr-5">

        <div class="inputs-50">
          <label>Tipo de Persona</label><br>

          <mat-radio-group aria-label="Seleccione" formControlName="tipo_factura">
            <mat-radio-button value="FIS">Física</mat-radio-button>
            <mat-radio-button value="JUR">Jurídica</mat-radio-button>
          </mat-radio-group>

        </div>

        <div class="inputs-50" *ngIf="formFactura.controls.tipo_factura.value  == 'JUR'">
          <div>
            <label>{{'Razón Social' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <input matInput placeholder="{{'Razón Social' | translate}}" formControlName="razon_social">
            </mat-form-field>
          </div>
        </div>

        <div class="inputs-50"
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI'">
          <div>
            <label>{{'Nombre' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <input matInput placeholder="{{'Nombre' | translate}}" formControlName="nombre">
            </mat-form-field>
          </div>

          <div>
            <label>{{'Apellido 1' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <input matInput placeholder="{{'Apellido 1' | translate}}" formControlName="primer_apellido">
            </mat-form-field>
          </div>
        </div>

        <div class="inputs-50">
          <div
            *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI'">
            <label>{{'Apellido 2' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Apellido 2" formControlName="segundo_apellido">
            </mat-form-field>
          </div>

          <div
            *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
            <label>{{'Número de Cédula' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Número de Cédula" formControlName="numero_identificacion" numeric
                decimals="0" maxlength="13">
              <mat-error *ngIf="formFactura.controls['numero_identificacion'].errors">{{'El número de identificación
                debe ser minimo 9 dígitos sin espacios ni guiones.' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="inputs-50"
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <div>
            <label>{{'Teléfono' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <span style="padding-top: 2px;">+506&nbsp;</span>
              <input maxlength="8" oninput="this.value=this.value.replace(/[^0-9]/g,'');" matInput
                placeholder="Teléfono" formControlName="telefono">
            </mat-form-field>
          </div>

          <div>
            <label>{{'Correo Electrónico' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Correo Electrónico" formControlName="correo">
            </mat-form-field>
          </div>

        </div>

        <div class="inputs"
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <div>
            <label>{{'Dirección de Facturación' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Dirección" formControlName="direccion">
            </mat-form-field>
          </div>
        </div>

        <div class="inputs-50"
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <div>
            <label>{{'Provincia' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <mat-select matInput placeholder="Seleccione Provincia" required formControlName="provincia"
                (valueChange)="getCantones($event)" #provinciaInput>
                <mat-option *ngFor="let provincia of provincias" [value]="provincia.id">
                  {{provincia.provincia}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <label>{{'Cantón' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <mat-select matInput placeholder="Seleccione Cantón" required formControlName="canton"
                (valueChange)="getDistritos(formEntrega.controls['provincia'].value, $event)">
                <mat-option *ngFor="let canton of cantones" [value]="canton.id">{{canton.canton}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="inputs-50"
          *ngIf="formFactura.controls.tipo_factura.value == 'FIS' || formFactura.controls.tipo_factura.value == 'ALI' || formFactura.controls.tipo_factura.value == 'JUR'">
          <div>
            <label>{{'Distrito' | translate}}</label><br>
            <mat-form-field appearance="outline">
              <mat-select matInput placeholder="Seleccione Distrito" required formControlName="distrito">
                <mat-option *ngFor="let distrito of distritos" [value]="distrito.id">{{distrito.distrito}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </div>

    </ng-template>

    <div class="button-bottom text-right pb-5 pl-5 pr-5">
      <button mat-flat-button color="primary" (click)="editarInfoFactura = true" class="button-consultar"
        *ngIf="(!editarInfoFactura && user?.delete == 0) && !authService.isVendedor">{{'Editar' | translate}}</button>
      <button mat-flat-button color="primary" (click)="changeFormFactura(formFactura.value); editarInfoFactura = false"
        class="button-calcular" *ngIf="editarInfoFactura" [disabled]="formFactura.invalid">{{'Guardar Cambios' |
        translate}}</button>
    </div>
  </form>
</ng-template>

<!-- Template Notificaciones  -->
<ng-template #notificaciones>
  <div class="tab">
    <form [formGroup]="formNotificaciones" class="pt-4">
      <div class="row">
        <div style="padding-right: 30px;" class="col">
          <div style="margin-bottom: 15px;margin-left: 10px;">
            <h3>{{'Estatus de Notificación' | translate}}</h3>
          </div>

          <div class="inputs-100">
            <div class="select-check">
              <label for="1">{{'Notificar Recibido en Miami' | translate}}</label>
              <input id="1" type="checkbox" formControlName="arribo_M">
            </div>
          </div>
          <div class="inputs-100">
            <div class="select-check">
              <label for="2">{{'Notificar En Tránsito Hacia Costa Rica' | translate}}</label>
              <input id="2" type="checkbox" formControlName="n_transito">
            </div>
          </div>
          <div class="inputs-100">
            <div class="select-check">
              <label for="3">{{'Notificar Recibido en Centro de Distribución' | translate}} </label>
              <input id="3" type="checkbox" formControlName="arribo_CR">
            </div>
          </div>
          <div class="inputs-100">
            <div class="select-check">
              <label for="4">{{'Notificar Entregado al Cliente' | translate}}</label>
              <input id="4" type="checkbox" formControlName="n_entrega">
            </div>
          </div>
        </div>
        <div style="padding-right: 30px" class="col">
          <div style="margin-bottom: 15px;">
            <h3> {{'Medios de notificación' | translate}}</h3>
          </div>
          <div class="inputs-100">
            <div class="select-check">
              <label for="5">{{'Notificación por Email' | translate}}</label>
              <input id="5" type="checkbox" formControlName="n_email">
            </div>

          </div>
          <div class="inputs-100">
            <div class="select-check">
              <label for="7">{{'Notificación por SMS' | translate}}</label>
              <input id="7" type="checkbox" formControlName="n_whatsapp">
            </div>
          </div>

          <div class="inputs-100">
            <div class="select-check">
              <label for="8">{{'Notificaciones por Sistema' | translate}}</label>
              <input id="8" type="checkbox" formControlName="n_push">
            </div>
          </div>
        </div>

      </div>
    </form>
    <div class="button-bottom text-right pb-5 pl-5 pr-5">
      <button mat-flat-button color="primary" (click)="changeFormNotificaciones(formNotificaciones.value)"
        *ngIf="!authService.isVendedor" class="button-consultar">{{'Guardar' | translate}}</button>
    </div>
  </div>
</ng-template>

<!-- Template Entrega -->
<ng-template #entrega>
  <div class="tab">
    <form [formGroup]="formEntrega" class="form">
      <div class="container table-contacto pt-5 pl-5 pr-5" *ngIf="!editarInfoEntrega; else elseBlockEntrega;">
        <table style="width:100%">
          <tr>
            <td class="p-td">{{'Modo de Entrega' | translate}}:</td>
            <td class="p-td">{{formEntrega.controls.modo_de_entrega.value != null ?
              getModoEntrega(formEntrega.controls.modo_de_entrega.value) : ''}}</td>
          </tr>

          <tr
            *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'AM' || (formEntrega.controls['modo_de_entrega'].value == 'ED' && user.distrito?.tipo != 'v') )">
            <td class="p-td">{{'Empresa de Transporte' | translate}}:</td>
            <td class="p-td">
              {{formEntrega.controls.encomienda.value != null ?
              getEmpresa(formEntrega.controls.encomienda.value) :
              ''}}

            </td>
          </tr>

          <tr *ngIf="formEntrega.controls['modo_de_entrega'].value == 'ES'">
            <td class="p-td">{{'Sucursal' | translate}}:</td>
            <td class="p-td">{{formEntrega.controls.sucursal.value}}</td>
          </tr>

          <tr
            *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM')">
            <td class="p-td">{{'Dirección de entrega' | translate}}</td>
            <td class="p-td">{{formEntrega.controls.direccion_entrega.value}}</td>
          </tr>
          <tr
            *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM')">
            <td class="p-td">{{'Detalles Adicionales' | translate}}:</td>
            <td class="p-td">{{formEntrega.controls.detalles_adicionales.value}}</td>
          </tr>

          <tr
            *ngIf="((formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM') && (authService.isAdmin || authService.isOperacionesCostaRica))">
            <td class="p-td">{{'Ruta' | translate}}:</td>
            <td class="p-td">{{formEntrega.controls.ruta.value != null ?
              getRuta(formEntrega.controls.ruta.value) :
              ''}}</td>
          </tr>

          <tr
            *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM')">
            <td class="p-td">{{'Provincia' | translate}}:</td>
            <td class="p-td">{{formEntrega.controls.provincia.value != null ?
              getProvincia(formEntrega.controls.provincia.value) :
              ''}}</td>
          </tr>
          <tr
            *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM')">
            <td class="p-td">{{'Cantón' | translate}}:</td>
            <td class="p-td">{{formEntrega.controls.canton.value != null ?
              getCanton(formEntrega.controls.canton.value) :
              ''}} </td>
          </tr>
          <tr
            *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM')">
            <td class="p-td">{{'Distrito' | translate}}:</td>
            <td
              [ngStyle]="{'color': (getDistritoTipo(formEntrega.controls['distrito'].value) == 'r' && !authService.isCliente) ? 'red' : null }"
              class="p-td">
              {{formEntrega.controls.distrito.value != null ? getDistrito(formEntrega.controls.distrito.value) : ''}}
            </td>
          </tr>

          <tr
            *ngIf="formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM'">
            <td class="p-td">{{'Método de Pago Predeterminado' | translate}}:</td>
            <td class="p-td">{{formEntrega.controls.metodo_pago.value != null ?
              getMetodoPago(formEntrega.controls.metodo_pago.value) :
              ''}}</td>
          </tr>

        </table>
      </div>

      <ng-template #elseBlockEntrega>
        <div class="container">
          <div class="inputs-general inputs-form pt-5 pl-5 pr-5">

            <div class="col-12 col-md-6 delivery">
              <label class="label w-100">{{'Modo de Entrega' | translate}}</label><br>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select matInput placeholder="{{'Modo de Entrega' | translate}}" formControlName="modo_de_entrega"
                  (selectionChange)="formEntregaChanged = true; changeMode($event.value)">
                  <mat-option *ngFor="let deliveryMode of deliveryModes" [value]="deliveryMode.value">
                    {{deliveryMode.name}}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="formEntrega.controls['modo_de_entrega'].errors">
                  {{getErrorMessage(formEntrega.controls['modo_de_entrega'])}}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12"
              *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'AM' || (formEntrega.controls['modo_de_entrega'].value == 'ED' && user.distrito?.tipo != 'v')) && authService.isAdmin">
              <label class="label w-100">{{'Empresa de Transporte' | translate}}</label><br>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select matInput placeholder="{{'Empresa de Transporte' | translate}}" required
                  formControlName="encomienda">
                  <mat-option *ngFor="let empresa of empresasTransporte" [value]="empresa.id">
                    {{empresa.nombre}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formEntrega.controls['encomienda'].errors">
                  {{getErrorMessage(formEntrega.controls['encomienda'])}}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 mb-2"
              *ngIf="formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM'">
              <label class="label w-100">{{'Dirección de entrega' | translate}}</label><br>
              <mat-form-field appearance="outline" class="w-100">
                <input style="margin-bottom: 0px;" matInput id="direccion_entrega" name="direccion_entrega"
                  #direccion_entrega placeholder="{{'Dirección de entrega' | translate}}"
                  formControlName="direccion_entrega">

                <mat-error *ngIf="formEntrega.controls['direccion_entrega'].errors">
                  {{getErrorMessage(formEntrega.controls['direccion_entrega'])}}
                </mat-error>
              </mat-form-field>

              <ng-container
                *ngIf="formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM'">
                <label class="label w-100"
                  [ngClass]="{'label_form_field_required': formEntrega.controls['modo_de_entrega'].value == 'ED'}">
                  {{'Detalles Adicionales' | translate}}
                </label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput
                    placeholder="Ejemplo 250 metros Sur del Hospital Santa María y 25 Este, casa de dos pisos, color beige con portón negro, A22"
                    formControlName="detalles_adicionales">
                  <mat-error *ngIf="formEntrega.controls['detalles_adicionales'].errors">
                    {{getErrorMessage(formEntrega.controls['detalles_adicionales'])}}
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapClick)="markerClick($event)">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                  (dragEnd)="markerDragEnd($event); onAddressChange()"></agm-marker>
              </agm-map>

            </div>

            <div class="col-12 mb-2" *ngIf="formEntrega.controls['modo_de_entrega'].value == 'ES'">
              <label class="label w-100">{{'Sucursal' | translate}}</label><br>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select matInput placeholder="{{'Sucursal' | translate}}" required formControlName="sucursal">
                  <mat-option value="Limón">
                    Limón
                  </mat-option>
                  <mat-option value="Guadalupe">
                    Guadalupe
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formEntrega.controls['sucursal'].errors">
                  {{getErrorMessage(formEntrega.controls['sucursal'])}}
                </mat-error>
              </mat-form-field>

            </div>

            <div style="margin: 0 25px;" class="inputs-50"
              *ngIf="formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM'">
              <div>
                <label>{{'Provincia' | translate}}</label><br>
                <mat-form-field appearance="outline">
                  <mat-select matInput placeholder="Seleccione Provincia" formControlName="provincia"
                    (valueChange)="getCantones($event); onProvinceChange()" #provinciaInput>
                    <mat-option *ngFor="let provincia of provincias" [value]="provincia.id">
                      {{provincia.provincia}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formEntrega.controls['provincia'].errors">
                    {{getErrorMessage(formEntrega.controls['provincia'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div>
                <label>{{'Cantón' | translate}}</label><br>
                <mat-form-field appearance="outline">
                  <mat-select matInput placeholder="Seleccione Cantón" formControlName="canton"
                    (valueChange)="getDistritos(formEntrega.controls['provincia'].value, $event); onCantonChange()">
                    <mat-option *ngFor="let canton of cantones" [value]="canton.id">
                      {{canton.canton}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formEntrega.controls['canton'].errors">
                    {{getErrorMessage(formEntrega.controls['canton'])}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div style="margin: 0 25px;" class="inputs-50"
              *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM') && (formEntrega.controls['canton'].value)">
              <div>
                <label>{{'Distrito' | translate}}</label><br>
                <ng-select [items]="distritos" bindLabel="distrito" bindValue="id" placeholder="Seleccione Distrito"
                  formControlName="distrito" (change)="onDistrictChange()"
                  [ngStyle]="{'background-color': (getDistritoTipo(formEntrega.controls['distrito'].value) == 'r' && !authService.isCliente) ? 'red' : null }">
                </ng-select>
                <mat-error *ngIf="formEntrega.controls['distrito'].errors">
                  {{getErrorMessage(formEntrega.controls['distrito'])}}
                </mat-error>
              </div>

              <div class="pt-4">
                <span *ngIf="getDistritoTipo(formEntrega.controls['distrito'].value) == 'v'" class="green">
                  {{'Entrega Gratuita Disponible' | translate}}
                </span>
                <span *ngIf="getDistritoTipo(formEntrega.controls['distrito'].value) == 'a'" class="yellow">
                  {{'Comuníquese con atención al cliente para entregas a esta zona' | translate}}
                </span>
                <span *ngIf="getDistritoTipo(formEntrega.controls['distrito'].value) == 'r'" class="red">{{'Entrega con
                  Costo Adicional' | translate}}</span>
              </div>
            </div>

            <div class="col-12 mb-2"
              *ngIf="(formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM') && (authService.isAdmin || authService.isOperacionesCostaRica) && formEntrega.controls['distrito'].value != null">
              <label class="label w-100">{{'Ruta' | translate}}</label><br>
              <ng-select [items]="rutas" bindLabel="nombre" bindValue="id"
                placeholder="{{'Ruta de Entrega' | translate}}" formControlName="ruta" class="w-100">
              </ng-select>
              <mat-error *ngIf="formEntrega.controls['ruta'].errors">
                {{getErrorMessage(formEntrega.controls['ruta'])}}
              </mat-error>
            </div>

            <div style="margin: 0 25px;" class="inputs-50"
              *ngIf="getDistritoTipo(formEntrega.controls['distrito'].value) == 'a' && (authService.isAdmin || authService.isOperacionesCostaRica)">
              <mat-checkbox formControlName="autoriza_entrega">Autorizar Entrega</mat-checkbox>
            </div>
            <!---->

            <div class="col-12 mb-2"
              *ngIf="formEntrega.controls['modo_de_entrega'].value == 'ED' || formEntrega.controls['modo_de_entrega'].value == 'AM'">
              <label class="label w-100">{{'Método de Pago Predeterminado' | translate}}</label><br>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select matInput placeholder="Método de Pago Predeterminado" formControlName="metodo_pago">
                  <mat-option *ngFor="let metodo of metodosPagoPreviaEntrega" [value]="metodo.id">
                    {{metodo.nombre}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formEntrega.controls['metodo_pago'].errors">
                  {{getErrorMessage(formEntrega.controls['metodo_pago'])}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

        </div>
      </ng-template>

      <div class="button-bottom text-right pb-5 pl-5 pr-5">
        <label class="mr-2" *ngIf="disabledChangeRoute">{{disabledTime}}</label>
        <button mat-flat-button color="primary" (click)="editarInfoEntrega = true" class="button-consultar"
          *ngIf="(!editarInfoEntrega && user?.delete == 0) && authService.validateRolePermission('CHANGE_CLIENT_DIRECTION')"
          [disabled]="disabledChangeRoute() && !(authService.isAdmin || authService.isOperacionesCostaRica)">{{'Editar'
          | translate}}</button>

        <button mat-flat-button color="primary" (click)="changeFormEntrega()" class="button-calcular"
          *ngIf="editarInfoEntrega">{{'Guardar Cambios' | translate}}</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- Template Autorizaciones -->
<ng-template #autorizaciones>
  <div class="tab">
    <div class="container-example pt-3 pl-5 pr-5" fxLayout="column" fxLayoutGap="10px" style="margin: 0;">
      <div class="row">
        <div class="col-lg-12">
          <div class="content-table" *ngIf="authorizations[0]">
            <table mat-table
              [dataSource]="authorizations | paginate: {itemsPerPage: pageSize, currentPage: pageIndex, totalItems:length}">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> {{'Nombre' | translate}} </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.nombre}} </td>
              </ng-container>

              <ng-container matColumnDef="cedula">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> {{'Cédula Identidad' | translate}} </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element">
                  {{element.cedula}}
                </td>
              </ng-container>

              <ng-container matColumnDef="telefono">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> {{'Teléfono' | translate}} </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.telefono}} </td>
              </ng-container>

              <!-- <ng-container matColumnDef="parentesco">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Parentesco </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.relacion}} </td>
              </ng-container>-->

              <ng-container matColumnDef="tipo_autorizacion">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> {{'Tipo de Autorización' | translate}} </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.tipo_autorizacion}} </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> {{'Email' | translate}} </th>
                <td mat-cell class="pr-pl-20" *matCellDef="let element">
                  {{element.email}}
                </td>
              </ng-container>

              <ng-container matColumnDef="opciones">
                <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> {{'Opciones' | translate}} </th>
                <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                  <button mat-icon-button aria-label="Editar" class="button-opcione mr-2"
                    (click)="editAutorizacion(element)">
                    <mat-icon style="color: blue !important">edit</mat-icon>
                  </button>
                  <button mat-icon-button aria-label="Eliminar" class="button-opcione-borrar"
                    (click)="deleteAutorizacion(element.id)">
                    <mat-icon style="color: red !important">delete</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns2" [ngClass]="{'highlight-top-header': true }">
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2; let odd = odd; "
                [ngClass]="{'highlight': odd }"></tr>
            </table>
          </div>
          <div *ngIf="!authorizations[0]" class="mensaje-no-creado">
            {{'Aún no hay autorizaciones creadas.' | translate}}
          </div>
          <div class="text-right">
            <button *ngIf="!authService.isVendedor" mat-flat-button color="primary" class="button-consultar"
              (click)="crearAutorizacion()">{{'Agregar' |
              translate}}</button>
          </div>
          <div class="paginator mb-2 text-center" *ngIf="authorizations[0]">
            <pagination-controls autoHide="false" responsive="true" previousLabel="{{'Anterior' | translate}}"
              nextLabel="{{'Siguiente' | translate}}" (pageChange)="pageIndex = $event; paginate(pageIndex, '')">
            </pagination-controls>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Template Tarjetas -->
<ng-template #tarjetas>
  <div class="container-example pt-3 pl-5 pr-5" fxLayout="column" fxLayoutGap="10px" style="margin: 0;">
    <div class="row">
      <div class="col-lg-12">
        <div class="content-table" *ngIf="dataSourceCards?.length > 0">

          <table mat-table [dataSource]="dataSourceCards">

            <ng-container matColumnDef="nombre_tarjeta">
              <th mat-header-cell *matHeaderCellDef class="pr-pl-20 text-center"> {{'Nombre de la Tarjeta' | translate}}
              </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.CustomerReference}} </td>
            </ng-container>

            <ng-container matColumnDef="tipo_tarjeta">
              <th mat-header-cell *matHeaderCellDef class="pr-pl-20 text-center"> {{'Tipo de Tarjeta' | translate}}
              </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.CardType}}
              </td>
            </ng-container>

            <ng-container matColumnDef="numero_tarjeta">
              <th mat-header-cell *matHeaderCellDef class="pr-pl-20 text-center"> {{'Número de la Tarjeta' | translate}}
              </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{element.PaddedCardNo}}
              </td>
            </ng-container>

            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef class="pr-pl-20 text-center"> {{'Estado' | translate}}</th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                {{(element.Active ? 'Activa' : 'Inactiva') | translate}}
              </td>
            </ng-container>

            <ng-container matColumnDef="opciones">
              <th mat-header-cell *matHeaderCellDef class="pr-pl-20 text-center"> {{'Opciones' | translate}} </th>
              <td mat-cell class="pr-pl-20 text-center" *matCellDef="let element">
                <button mat-button aria-label="Eliminar Tarjeta" class="button-opcione-borrar"
                  *ngIf="authService.validateRolePermission('EDIT_CREDIT_CARD')" (click)="editCardAlternative(element)">
                  <mat-icon style="color: #0094a3 !important">edit</mat-icon>
                </button>

                <button mat-button aria-label="Eliminar Tarjeta" class="button-opcione-borrar"
                  (click)="deleteCard(element.id)"
                  *ngIf="authService.validateRolePermission('REMOVE_CREDIT_CARD') && element.Active">
                  <mat-icon style="color: #0094a3 !important">delete</mat-icon>
                </button>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsCards" [ngClass]="{'highlight-top-header': true }">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCards; let odd = odd; "
              [ngClass]="{'highlight': odd }"></tr>
          </table>
        </div>
        <div *ngIf="dataSourceCards?.length == 0" class="pt-5 pb-5 mensaje-no-creado">
          <p>{{'Aún no hay tarjetas Guardadas.' | translate}}</p>

          <p>{{'Podrá administrar sus tarjetas luego de realizar su primer pago con las mismas.' | translate}}</p>
        </div>

      </div>
    </div>
  </div>
</ng-template>

<!-- Template 2FA -->
<ng-template #twofa>
  <div class="container-example pt-3 pl-5 pr-5" fxLayout="column" fxLayoutGap="10px" style="margin: 0;">
    <div class="row">
      <div class="col-lg-12">
        <div style="padding-right: 30px;" class="col">
          <div>
            <h3>{{'Autenticación de 2 factores' | translate}}</h3>
          </div>

          <div class="mt-4">
            <p>
              {{'Refuerce la seguridad de su cuenta con los siguientes pasos.' | translate}}
            </p>

            <p>
              {{'Su cuenta tiene la primera capa de seguridad por medio de su usuario y contraseña. Este es un segundo
              paso, opcional, para una doble protección cada vez que desee acceder a su perfil.' | translate}}
            </p>
          </div>

          <div *ngIf="user?.secret_key">

            <div class="inputs-100 col-4">

              <div class="select-check checkboxes">
                <label><input type="checkbox" type="checkbox" #twofaCheck [checked]="user?.twofaactive"
                    [value]="user?.twofaactive" (click)="check2FA(twofaCheck.checked)"> <span> {{'Habilitar
                    Autenticación de dos factores' | translate}}</span></label>
              </div>

            </div>
            <br>
            <p>
              {{'Estado Actual' | translate}}: {{(this.user.twofaactive ? 'Activo' : 'Inactivo') | translate}}
            </p>
            <br>
            <div *ngIf="authService.isAdmin">
              <button mat-flat-button color="primary" (click)="remove2FA()" class="button-calcular">
                {{'Eliminar Autenticación de dos factores' | translate}}
              </button>
            </div>

            <div class="button-bottom text-right pb-5 pl-5 pr-5">
              <button mat-flat-button color="primary" (click)="save2FA()" class="button-calcular">{{'Guardar Cambios' |
                translate}}</button>
            </div>

          </div>

          <div *ngIf="!user?.secret_key">
            <div class="row mt-4">
              <div class="col">
                <span class="color-superbox-bold">1. {{'Active la autentificación' | translate}}</span>
                <div class="select-check checkboxes">
                  <label><input type="checkbox" type="checkbox" #twofaCheck [checked]="user?.twofaactive"
                      [value]="user?.twofaactive" (click)="check2FA(twofaCheck.checked)"> <span> {{'Habilitar
                      Autenticación de dos factores' | translate}}</span></label>
                </div>
              </div>

              <div class="col">
                <span class="color-superbox-bold">2. {{'Descargue el App' | translate}}</span>
                <div class="row">
                  <div class="col">
                    <img src="assets/auth_android.png" alt="googleplay" class="img-fluid">
                    <p style="text-align: center;">Android</p>
                  </div>
                  <div class="col">
                    <img src="assets/auth_ios.png" alt="ios" class="img-fluid">
                    <p style="text-align: center;">iOS</p>
                  </div>

                </div>

              </div>

              <div class="col">
                <span class="color-superbox-bold">3. {{'Escanee el código QR desde el App' | translate}}</span>

                <div *ngIf="qrInfo" class="mt-4" style="text-align: center;">

                  <qr-code [value]="qrInfo" size="150"></qr-code>

                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <p class="color-superbox">
                  {{'Estado Actual' | translate }}: {{(user?.secret_key ? 'Configurada' : 'No configurada') |
                  translate}}
                </p>
              </div>

              <div class="col">
                <div class="text-right">
                  <button mat-flat-button color="primary" (click)="activate2FA()" class="button-calcular">{{'Guardar
                    Cambios' | translate}}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 mb-4" *ngIf="authService.isCliente">
            {{'Si necesita ayuda comuníquese con Servicio al Cliente: hola@superboxcr.com, Tel. 2253-5400, WhatsApp
            6037-6729' | translate}}
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>
