<div class="general">
    <h3 mat-dialog-title class="modal-title text-center">{{data.title}}</h3>

    <div class="p-4" style="width: 100%; height: 750px; overflow-y: auto;">
        <div>
            <div class="mb-2">
                <ng-select [items]="idTypes" bindLabel="name" bindValue="id" placeholder="Seleccione el tipo de cédula"
                    [searchable]="false" [multiple]="false" [(ngModel)]="selectedId.id" (change)="onIdTypeChange($event)" [disabled]="foundPayment"></ng-select>
            </div>
            <div style="display: flex; align-items: center; width: 100%; gap: 10px;">
                <mat-form-field appearance="outline" style="flex: 1;">
                    <mat-label>Número de cédula</mat-label>
                    <input [disabled]="foundPayment" matInput placeholder="Número de cédula" [(ngModel)]="selectedId.value">
                </mat-form-field>
            </div>
            <div style="display: flex; align-items: center; width: 100%; gap: 10px;">
                <mat-form-field appearance="outline" style="flex: 1;">
                    <mat-label>Número de referencia</mat-label>
                    <input [disabled]="foundPayment" matInput placeholder="Número de referencia"
                        [(ngModel)]="codReference">
                </mat-form-field>
            </div>
            <div style="display: flex; align-items: center; width: 100%; gap: 10px;">
                <mat-form-field appearance="outline" style="flex: 1;">
                    <mat-label>Monto del pago</mat-label>
                    <input [disabled]="foundPayment" type="number" matInput placeholder="Monto del pago"
                        [(ngModel)]="paymentAmount">
                </mat-form-field>
                <button [disabled]="foundPayment || codReference === '' " button mat-flat-button color="primary" class="button1"
                    style="flex-shrink: 0;" (click)="verifyPayments()">Buscar Pago</button>
            </div>
            <div>
                <div *ngIf="dvResult && dvResult.pagos.length > 0 && foundPayment"
                    style="color: white; background-color: green; padding: 10px 20px; border-radius: 50px; display: inline-block; font-size: 14px; font-weight: bold; margin-bottom: 6px;">
                    Pago encontrado
                </div>
                <div *ngIf="dvResult && foundPayment == false"
                    style="color: white; background-color: red; padding: 10px 20px; border-radius: 50px; display: inline-block; font-size: 14px; font-weight: bold; margin-bottom: 6px;">
                    Pago no encontrado
                </div>
            </div>
        </div>

        <div mat-dialog-actions style="display: flex; justify-content: space-between; margin-bottom: 2px;">
            <button mat-flat-button color="primary" class="button1" (click)="cancel()">Cancelar</button>
            <button [disabled]="foundPayment == false" mat-flat-button color="primary" class="button1"
                (click)="attachPayment()">Vincular Pago</button>
        </div>

    </div>

</div>