import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { serialize } from 'object-to-formdata';

import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PaquetesService {

  public selectedPackages = {
    packages: [],
    totalCrc: 0,
    totalUsd: 0
  };
  public paquetes;

  private urlPaquetes = `${environment.apiUrl}/paquetes/`;
  private urlEstadosPaquetes = `${environment.apiUrl}/estados-paquetes/`;
  private urlTarifas = `${environment.apiUrl}/tarifas/`;

  private urlDescuentos = `${environment.apiUrl}/descuentos/`;

  constructor(
    private http: HttpClient
  ) { }

  get(page, params = '') {
    const filter = "?or_params='{paquete_contenedor='False',paquete_contenedor__startswith='O-'}'" + params;
    return this.http.get(this.urlPaquetes + filter + '&page_no=' + page + '&order_by=created_at');
  }

  getItems(id) {
    return this.http.get(this.urlPaquetes + 'items/' + id);
  }

  getItemsSearch(search) {
    return this.http.get(this.urlPaquetes + 'items/buscar/' + search);
  }

  getForBilling(page) {
    return this.http.get(this.urlPaquetes + 'facturas' + '?page=' + page);
  }

  post(data) {
    return this.http.post(this.urlPaquetes, this.getFormData(data));
  }

  changePackagesState(id, data, newStateId) {
    return this.http.put(this.urlPaquetes + 'shipment-packages-state/' + id, { packages_ids: data, state: newStateId });
  }
  changePackagesSteteMassive(data, newStateId) {
    return this.http.put(this.urlPaquetes + 'updateState', { packages_ids: data, new_state: newStateId });
  }
  addLineDetails(packagesIds, data) {
    return this.http.put(this.urlPaquetes + 'line-details', { packages_ids: packagesIds, params: data});
  }
  put(id, data) {
    return this.http.put(this.urlPaquetes + id, this.getFormData(data));
  }
  updateObservation(id, data) {
    return this.http.put(this.urlPaquetes + 'entrega_observacion/' + id, this.getFormData(data));
  }
  manifiesto(id, data) {
    return this.http.put(this.urlPaquetes + 'manifiesto/' + id, data);
  }
  driver(id, data) {
    return this.http.put(this.urlPaquetes + 'driver/' + id, data);
  }
  updateawb(id, data) {
    return this.http.put(this.urlPaquetes + 'awb/' + id, data);
  }


  putItems(data, id) {
    return this.http.put(this.urlPaquetes + 'items/' + id, data);
  }

  capturar(id, data) {
    return this.http.put(this.urlPaquetes + 'capturar/' + id, data);
  }

  capturarZona(id, data) {
    return this.http.put(this.urlPaquetes + 'capturar-zona/' + id, data);
  }

  postConsolidado(data) {
    return this.http.post(this.urlPaquetes + 'consolidar/', this.getFormData(data));
  }

  marcarConsolidado(id) {
    return this.http.put(this.urlPaquetes + 'consolidar/' + id, { check: true });
  }

  getPaquetesPrealertados(id) {
    return this.http.get(this.urlPaquetes + 'prealertados' + id);
  }

  getEstadosPaquetes() {
    return this.http.get(this.urlEstadosPaquetes);
  }
  deleteEstadoPaquete(id) {
    return this.http.delete(this.urlEstadosPaquetes + id);
  }
  getEstadosPaquetesClientes() {
    return this.http.get(this.urlEstadosPaquetes + 'clientes');
  }

  getEstadosPaquetesMiami() {
    return this.http.get(this.urlEstadosPaquetes + 'miami');
  }

  postEstadoPaquete(data) {
    return this.http.post(this.urlEstadosPaquetes, data);
  }

  getEstadoPaquete(id) {
    return this.http.get(this.urlEstadosPaquetes + id);
  }

  putEstadoPaquete(id, data) {
    return this.http.put(this.urlEstadosPaquetes + id, data);
  }

  getPendingsByUser(id) {
    return this.http.get(this.urlPaquetes + 'pendientes/usuario/' + id);
  }

  getPendingsDeliver() {
    return this.http.get(this.urlPaquetes + 'porentregar');
  }

  getDistributionCenter() {
    return this.http.get(this.urlPaquetes + 'distribucion');
  }

  getDistributionCenterSimple() {
    return this.http.get(this.urlPaquetes + 'distribucion/simple');
  }

  getScheduledPackages(fecha) {
    return this.http.get(this.urlPaquetes + 'programados/' + fecha);
  }

  getScheduledPackagesUser(id, page) {
    return this.http.get(this.urlPaquetes + 'programados/usuario/' + id + '?page=' + page);
  }

  getByUser(id, page = null, payment_status = null) {
    let fullUrl = this.urlPaquetes + 'usuario/' + id;
    if (page) {
      fullUrl += '?page=' + page;
    }
    if (payment_status) {
      const sign = page ? '&' : '?';
      fullUrl += `${sign}payment_status=${payment_status}`;
    }
    return this.http.get(fullUrl);
  }

  getTotalToPayByUser(id) {
    return this.http.get(this.urlPaquetes + 'usuario/totalToPay/' + id);
  }
  getByUserDashboard(id) {
    return this.http.get(this.urlPaquetes + 'usuario/dashboard/' + id);
  }

  getByUserStatus(id, page, status) {
    //console.log('status api', status)
    return this.http.get(this.urlPaquetes + 'usuario/status/' + id + '/' + status + '/10' + '?page=' + page);
  }

  deleteByUserStatus(id, page, status, paquete_id) {
    return this.http.delete(this.urlPaquetes + 'usuario/status/' + id + '/' + status + '/10' + '?page=' + page + '/' + paquete_id);
  }

  deletePaquete(id) {
    return this.http.delete(this.urlPaquetes + id);
  }

  getByUserFacturas(id, page) {
    return this.http.get(this.urlPaquetes + 'usuario/facturas/' + id + '?page=' + page);
  }

  getById(id) {
    return this.http.get(this.urlPaquetes + id);
  }

  getByStatus(status, page) {
    return this.http.get(this.urlPaquetes + 'bystatus/' + status + '?page=' + page);
  }

  getByStatusSimple(status, page) {
    return this.http.get(this.urlPaquetes + 'bystatus/simple/' + status + '?page=' + page);
  }

  getByStatusUsuario(id, status, page) {
    return this.http.get(this.urlPaquetes + 'bystatus/' + status + '/usuario/' + id + '/20' + '?page=' + page);
  }

  getByAny(data) {
    return this.http.post(this.urlPaquetes + 'buscar/', data);
  }

  findGuia(qr) {
    return this.http.get(this.urlPaquetes + 'guia/' + qr);
  }

  findTracking(tracking) {
    return this.http.get(this.urlPaquetes + 'tracking/' + tracking);
  }

  findTrackingOrGuia(tracking) {
    return this.http.get(this.urlPaquetes + 'tracking-guia/' + tracking);
  }

  findTrackingOrGuiaClientes(tracking) {
    return this.http.get(this.urlPaquetes + 'tracking-guia-clientes/' + tracking);
  }

  getByFactura(factura_id) {
    return this.http.get(`${this.urlPaquetes}facturas/${factura_id}`);
  }

  checkCodigoPromocional(usuario, promocode, plan, paquete) {
    const data = {
      usuario,
      promocode,
      plan,
      paquete
    };

    return this.http.post(`${this.urlDescuentos}check-code/`, data);
  }
  getTarifas() {
    return this.http.get(this.urlTarifas);
  }

  deleteTarifas(id) {
    return this.http.delete(this.urlTarifas + id);
  }


  getPlanesById(id) {
    return this.http.get(this.urlTarifas + id);
  }

  getForConsolidate(id) {
    return this.http.get(this.urlPaquetes + 'inmiami/' + id);
  }

  getPendingConsolidate() {
    return this.http.get(this.urlPaquetes + 'sin-consolidar/');
  }

  getForShip() {
    return this.http.get(this.urlPaquetes + 'paquetes_miami');
  }

  getAdicionales(id) {
    return this.http.get(this.urlPaquetes + 'adicionales/' + id);
  }


  getForShipment(id) {
    return this.http.get(this.urlPaquetes + 'paquetes_embarque/' + id);
  }

  addToShipment(data) {
    return this.http.post(this.urlPaquetes + 'embarque/', data);
  }

  getHistorialEstados(page) {
    return this.http.get(this.urlPaquetes + 'historial_estados' + '?per_page=10&page_no=' + page).pipe(
      catchError(this.handleError<any>())
    );
  }

  getHistorialImagenes(page) {
    return this.http.get(this.urlPaquetes + 'historial_imagenes' + '?per_page=10&page_no=' + page).pipe(
      catchError(this.handleError<any>())
    );
  }

  getHistorialEstadosById(id) {
    return this.http.get(this.urlPaquetes + 'historial_estados' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getHistorialImagenesById(id) {
    return this.http.get(this.urlPaquetes + 'historial_imagenes' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getPaqueteHistorialZona(id) {
    return this.http.get(this.urlPaquetes + 'historial_zona' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getPaqueteHistorialDirecciones(id) {
    return this.http.get(this.urlPaquetes + 'historial_direcciones' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getPaqueteHistorialEntregas(id) {
    return this.http.get(this.urlPaquetes + 'historial_entregas' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getPaqueteHistorialPlanes(id) {
    return this.http.get(this.urlPaquetes + 'historial_plan' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getPaqueteHistorialTipo(id) {
    return this.http.get(this.urlPaquetes + 'historial_tipo' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getPaqueteHistorialGuia(id) {
    return this.http.get(this.urlPaquetes + 'historial_guia' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  getPaqueteHistorialPeso(id) {
    return this.http.get(this.urlPaquetes + 'historial_peso' + '/' + id).pipe(
      catchError(this.handleError<any>())
    );
  }

  search(value) {
    //console.log('llamada api search');
    return this.http.get(this.urlPaquetes + 'buscar/?search=' + value);
  }

  searchUsuarios(id, value) {
    return this.http.get(this.urlPaquetes + 'buscar/usuario?search=' + value);
  }

  searchPaquetesCustom(data) {
    return this.http.get(this.urlPaquetes + 'filter/?search=' + data);
  }

  getPaqueteHistorial(id) {
    return this.http.get(this.urlPaquetes + 'historial_paquete/' + id);
  }

  postPaqueteHistorial(id, data) {
    return this.http.post(this.urlPaquetes + 'historial_paquete/' + id, data);
  }

  putPaqueteHistorial(id, data) {
    return this.http.put(this.urlPaquetes + 'historial_paquete/' + id, data);
  }

  deletePaqueteHistorial(id) {
    return this.http.delete(this.urlPaquetes + 'historial_paquete/' + id);
  }

  deletePaqueteFactura(id, key) {
    return this.http.get(this.urlPaquetes + 'eliminar_factura/' + id + '/' + key);
  }

  getCargosEncomienda(paquete) {
    return this.http.get(this.urlPaquetes + 'cargo_encomienda/' + paquete);
  }

  checkProgramados(paquete) {
    return this.http.get(this.urlPaquetes + 'check_programados/' + paquete);
  }

  facturasPorGenerar(data) {
    return this.http.post(this.urlPaquetes + 'facturas_por_generar', data);
  }

  facturasEmbarque(id) {
    return this.http.get(this.urlPaquetes + 'facturas_embarques/' + id);
  }

  putEntrega(id, data) {
    return this.http.put(this.urlPaquetes + 'entrega/' + id, this.getFormData(data));
  }

  deleteEntrega(id) {
    return this.http.delete(this.urlPaquetes + 'entrega/' + id);
  }

  guiasUsuarios(paquete) {
    return this.http.get(this.urlPaquetes + 'guias_usuario/' + paquete);
  }

  getEstadoCuenta(id) {
    return this.http.get(this.urlPaquetes + 'estado_cuenta/' + id);
  }

  netPackages(packages) {
    return this.http.post(this.urlPaquetes + 'net_packages/', packages);
  }

  unnetPackages(packages) {
    return this.http.post(this.urlPaquetes + 'unnet_packages/', packages);
  }

  togglePaymentStatus(id) {
    return this.http.post(this.urlPaquetes + 'toggle_payment_status/', id);
  }

  getPackagesList(queryParams) {
    return this.http.get(this.urlPaquetes + 'packages_list/', { params: queryParams });
  }

  calulateDelivery(userId: number) {
    return this.http.get(this.urlPaquetes + 'calculate_delivery/');
  }

  private handleError<T>() {
    // eslint-disable-next-line arrow-body-style
    return (error: any): Observable<T> => {
      return of(error as T);
    };
  }

  private getFormData(object) {
    if (object.factura_1 == null) {
      delete object.factura_1;
    }
    if (object.factura_2 == null) {
      delete object.factura_2;
    }
    if (object.factura_3 == null) {
      delete object.factura_3;
    }
    if (object.imagen == null) {
      delete object.imagen;
    }
    /* let formData = new FormData()
    Object.keys(object).forEach(key => {
      if (object[key] != null)
        formData.append(key, object[key])
    })
    return formData;*/
    return serialize(object);
  }

  exemptCharges(packageIds) {
    return this.http.put(this.urlPaquetes + 'exempt_charges/', { package_ids: packageIds });
  }

}
