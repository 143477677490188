const isValidRegex = (input, regex) => regex.test(input);


export const formatId = (idType: any): any => {
    let id = idType.value;
    if (!isValidRegex(id, idType.validateRegex)) {
        if (id.length === idType.length) {
            if (idType.id === 0) {
                id = '0' + id;
            }
            id =  id.replace(idType.regex, idType.format);
            if(!isValidRegex(id, idType.validateRegex)) {
                return {error: true, data: 'Error validando formato del tipo de la cédula'};
            }
            return {error: false, data: id};
        } else {
            return {error: true, data: 'Cédula no tiene la longitud correcta'};
        }
    }
    return {error: false, data: id};
};
