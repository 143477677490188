import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { formatId } from 'src/app/core/utils/validate-id';
import { PagosService } from './../../../../services/pagos.service';
import { DataService} from 'src/app/services/data.service';
import { GeneralDialogComponent } from '../general-dialog/general-dialog.component';

export interface DialogData {
  title: string;
  paymentId: number;
  monto_crc: number;
  packageGuide: string;
}

@Component({
  selector: 'app-attach-payment',
  templateUrl: './attach-payment.component.html',
  styleUrls: ['./attach-payment.component.scss']
})
export class AttachPaymentComponent implements OnInit {
  idTypes = this.dataService.getIdTypes();
  id = '';
  selectedId: any;

  codReference = '';
  foundPayment = false;
  dvResult: any;
  paymentAmount = 0;
  payment: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AttachPaymentComponent>,
    private pagosService: PagosService,
    private spinnerService: NgxSpinnerService,
    private dataService: DataService) {

  }
  ngOnInit(): void {
    this.paymentAmount = this.data.monto_crc;
    this.selectedId = {...this.idTypes[0], value: ''};
  }


  onIdTypeChange(event) {
    this.selectedId = event;
  }


  verifyPayments(): void {
    const formattedResponse = formatId(this.selectedId);
    if (formattedResponse.error) {
      this.selectedId.value = '';
      this.openDialog(formattedResponse.data);
      return;
    }
    this.selectedId.value = formattedResponse.data;
    this.spinnerService.show();
    this.pagosService.getDVPayments(this.selectedId.value, this.paymentAmount).subscribe((data: any) => {
      this.dvResult = data;
      this.spinnerService.hide();
      if (this.dvResult.pagos.length > 0) {
        this.payment = this.dvResult.pagos.find(pago => pago.codReferencia === this.codReference);
        if (this.payment) {
          this.foundPayment = true;
        }
        else {
          this.foundPayment = false;
        }
      }
      else {
        this.foundPayment = false;
      }
    });

  }

  attachPayment(): void {
    this.pagosService.attachPayment(this.payment.codReferencia, this.data.paymentId, this.data.packageGuide).subscribe((data: any) => {
      this.dialogRef.close(data);
    });

  }

  cancel() {
    this.dialogRef.close();
  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });
  }

}
