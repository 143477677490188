/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { serialize } from 'object-to-formdata';

@Injectable({
  providedIn: 'root'
})
export class PagosService {
  public datosFacturas;

  private urlPagos = `${environment.apiUrl}/pagos/`;

  constructor(
    private http: HttpClient
  ) { }

  autorizarPago(orderNumber, amount, CardNumber, CardExpiryDate, CVV2, Reference, User) {
    const httpOptions: Object = {
      responseType: 'text'
    };

    const data = {
      TotalAmount: Number.parseFloat(amount).toFixed(2),
      CardPan: CardNumber,
      CardCvv: CVV2,
      CardExpiration: CardExpiryDate,
      CardholderName: Reference,
      OrderIdentifier: orderNumber,
      Type: 0,
      User
    };

    return this.http.post(this.urlPagos + 'capture', data, httpOptions);
  }

  refundPago(orderNumber, amount) {

    const httpOptions: Object = {
      responseType: 'text'
    };

    const data = {
      orderNumber,
      amount: Number.parseFloat(amount).toFixed(2),
    };

    return this.http.post(this.urlPagos + 'refund', data, httpOptions);
  }

  procesarPago(data) {
    return this.http.post(this.urlPagos, data);
  }

  getPagos(page, params = '') {
    return this.http.get(this.urlPagos + '?per_page=10&page_no=' + page + params + '&order_by=created_at');
  }

  getPagoById(id) {
    return this.http.get(`${this.urlPagos}${id}`);
  }

  getComprobante(id) {
    return this.http.get(this.urlPagos + 'comprobante/' + id);
  }

  getComprobantePendientes() {
    return this.http.get(this.urlPagos + 'comprobantes/pendientes');
  }

  postComprobante(data) {
    return this.http.post(this.urlPagos + 'comprobante', this.getFormData(data));
  }

  putComprobante(data, id) {
    return this.http.put(this.urlPagos + 'comprobante/' + id, data);
  }

  deleteComprobante(id) {
    return this.http.delete(this.urlPagos + 'comprobante/' + id);
  }

  getFacturasD() {
    return this.http.get(this.urlPagos + 'facturad');
  }

  getFacturaD(id) {
    return this.http.get(this.urlPagos + 'facturad/' + id);
  }

  postFacturaD(data) {
    return this.http.post(this.urlPagos + 'facturad', this.getFormData(data));
  }

  putFacturaD(data, id) {
    return this.http.put(this.urlPagos + 'facturad' + id, data);
  }

  deletePago(id) {
    return this.http.delete(this.urlPagos + 'delete/' + id);
  }

  getTarjeta(id) {
    return this.http.get(this.urlPagos + 'tarjeta/' + id);
  }

  postTarjeta(data) {
    return this.http.post(this.urlPagos + 'tarjeta', this.getFormData(data));
  }

  putTarjeta(data, id) {
    return this.http.put(this.urlPagos + 'tarjeta/' + id, data);
  }

  getDVPayments(govId, amount) {
    return this.http.get(`${this.urlPagos}dv/payment?gov_id=${govId}&amount=${amount}`);
  }

  attachPayment(ref_code, paymentId, packageGuide) {
    return this.http.put(`${this.urlPagos}dv/payment`, { ref_code, paymentId, packageGuide });
  }

  sinpePayment(data) {
    return this.http.post(`${this.urlPagos}sinpePayment`, data);
  }

  transferPayment(data) {
    return this.http.post(`${this.urlPagos}transferPayment`, data);
  }


  private getFormData(object) {
    return serialize(object);
  }
}
