import { Injectable } from '@angular/core';
import {idTypes} from '../core/data/id-types';
@Injectable({
    providedIn: 'root',
  })
  export class DataService {

    getIdTypes() {
        return idTypes;
    }

    validateDeliveryDate(fechaEntrega: any) {
      if (fechaEntrega.getDay() === 5 && fechaEntrega.getHours() < 18) {
        // Si es viernes antes de las 6 pm, programa para el lunes
        fechaEntrega = this.getNextMonday(fechaEntrega);
      } else if (
        fechaEntrega.getDay() === 5 && fechaEntrega.getHours() >= 18 || fechaEntrega.getDay() === 6 || fechaEntrega.getDay() === 0) {
         // Si es viernes después de las 6 pm, sábado o domingo, programa para el siguiente martes
          fechaEntrega = this.getNextTuesday(fechaEntrega);
      } else {
        // Para otros días de la semana, programa para el día siguiente
        fechaEntrega.setDate(fechaEntrega.getDate() + 1);
      }
      return fechaEntrega;
    }

  // Función para obtener el próximo lunes
    getNextMonday(date: Date): Date {
      const resultDate = new Date(date);
      resultDate.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7));
      return resultDate;
    }


    getNextTuesday(date: Date): Date {
      const resultDate = new Date(date);
      resultDate.setDate(date.getDate() + ((2 + 7 - date.getDay()) % 7));
      return resultDate;
    }

}
