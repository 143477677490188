/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { UsuariosService } from '../../../../../services/usuarios.service';

import { PaquetesService } from '../../../../../services/paquetes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export interface Paquetes {
  id: number;
  guia: number;
  nombre: string;
  email: string;
  estado: string;
  cuenta: string;
  tracking: string;
  descripcion: string;
  envio: string;
  creacion: Date;
  creacion2: Date;
  ultima_actualizacion: Date;
  usuarioid: number;
  tipo_paquete: string;
  ultimo_usuario: string;
}

@Component({
  selector: 'app-admin-paquetes',
  templateUrl: './paquetes.component.html',
  styleUrls: ['./paquetes.component.scss']
})

export class PaquetesComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  faArchive = faArchive;
  faShoppingBag = faShoppingBag;
  faEnvelope = faEnvelope;

  length = 0;
  pageSize = 50;
  pageIndex = 1;

  displayedColumns: string[] = ['cuenta', 'guia','order','tipo_cliente','tipo_paquete', 'tracking', 'descripcion', 'envio', 'creacion', 'modificado', 'usuario'];
  public dataSource = new MatTableDataSource<Paquetes>();
  public copyData = [];
  selection = new SelectionModel<Paquetes>(true, []);

  estados_paquetes: any;
  client_types: any;

  form = this.fb.group({
    guia: [''],
    cuenta: [''],
    ultima_actualizacion: [''],
    creacion: [''],
    creacion2: [''],
    clase: null,
    estado: null,
    tipo_paquete: null,
    sin_identificar: null
  });

  searchByDate = false;
  searchByState = false;
  searchByDefault = false;
  field = '';

  filter;

  exporter: any;
  print = false;

  facturados = false;

  constructor(
    public paquetesService: PaquetesService,
    private fb: FormBuilder,
    public usuariosService: UsuariosService,
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getEstadosPaquetes();
    this.getClientType();
    this.getPaquetes();
  }

  getEstadosPaquetes() {
    this.paquetesService.getEstadosPaquetes().subscribe((data: any) => {
      this.estados_paquetes = data;
    });
  }

  getClientType() {
    this.usuariosService.getTipoCliente().subscribe((data: any) => {
      this.client_types = data;
    });
  }

  getPaquetes(filter = '', all = false) {

    if (all === false) {
      this.print = false;
      this.pageSize = 50;
      filter = filter + '&per_page=' + this.pageSize;

    } else {
      this.print = true;
      this.pageIndex = 1;
      this.pageSize = this.length;
      filter = filter + '&per_page=' + this.pageSize;

    }

    this.paquetesService.get(this.pageIndex, filter).subscribe(
      (res: any) => {
        const paquetesLista = [];
        if (res.results.length == 0) {
          this.dataSource.data = [];
          this.copyData = this.dataSource.data;
          this.length = 0;
          return;
        }
        res.results.forEach((element, index, array) => {
          paquetesLista.push({
            id: element.id,
            guia: element.numero_de_guia,
            order: element.paquete_contenedor === 'False' ? '-' : element.paquete_contenedor,
            nombre: this.usuariosService.get_fullname(element.usuario),
            estado: element.estado_actual.estado,
            cuenta: this.usuariosService.get_account_number(element.usuario),
            tracking: element.tracking,
            descripcion: element.descripcion,
            envio: element.plan,
            creacion: new Date(element.created_at),
            creacion2: new Date(element.created_at),
            ultima_actualizacion: new Date(element.updated_at),
            tipo_cliente: element.usuario?.tipo_cliente,
            //ultima_actualizacion: new Date(element.estado_historial.estado_fecha + ' 06:00'),
            usuarioid: element.usuario?.id,
            email: element.usuario?.email,
            tipo_paquete: element.tipo_de_paquete,
            ultimo_usuario: this.usuariosService.get_fullname(element.estado_historial.usermodify)
          });

          if (index == array.length - 1) {
            this.dataSource.data = paquetesLista;
            this.copyData = this.dataSource.data;
            this.length = res.count;
            if (all) {
              setTimeout(() => {
                this.exporter.exportTable('xlsx');
                this.print = false;
              }, 2000);

            }
          }

        });

      }
    );
  }

  public async filterTable() {
    this.spinnerService.show();

    this.form.value.cuenta == null ? this.form.value.cuenta = '' : '';
    this.form.value.guia == null ? this.form.value.guia = '' : '';
    this.form.value.creacion == null ? this.form.value.creacion = '' : '';
    this.form.value.creacion2 == null ? this.form.value.creacion2 = '' : '';
    this.form.value.ultima_actualizacion == null ? this.form.value.ultima_actualizacion = '' : '';
    this.form.value.clase == null ? this.form.value.clase = '' : '';
    this.form.value.estado == null ? this.form.value.estado = '' : '';
    this.form.value.tipo_paquete == null ? this.form.value.tipo_paquete = '' : '';

    let filter = '';

    if (this.form.value.cuenta !== '') {
      filter = filter + await this.searchUser(this.form.value.cuenta);
    }

    Object.keys(this.form.value).forEach((key, index, array) => {

      if (this.form.value[key] !== '') {

        if (key === 'guia') {
          filter = filter + '&numero_de_guia=' + this.form.value[key];
        }

        if (this.facturados === false) {
          if (key === 'creacion') {
            filter = filter + '&created_at__from=' + this.form.value[key];
          }

          if (key === 'creacion2') {
            filter = filter + '&created_at__to=' + this.form.value[key];
          }
        }

        if (this.facturados === true) {
          if (key === 'creacion') {
            filter = filter + '&fecha_factura__from=' + this.form.value[key];
          }

          if (key === 'creacion2') {
            filter = filter + '&fecha_factura__to=' + this.form.value[key];
          }
        }

        if (key === 'estado') {
          filter = filter + '&estado_actual=' + this.form.value[key];
        }

        if (key === 'clase') {
          filter = filter + '&clase=' + this.form.value[key];
        }

        if (key === 'tipo_paquete') {
          filter = filter + '&tipo_de_paquete=' + this.form.value[key];
        }
        if (key === 'sin_identificar') {
          if (this.form.value[key] != null) {
            filter = filter + '&usuario__tipo_cliente='+this.form.value[key];
          }
        }

        this.filter = filter;
      }

      if (index === array.length - 1) {
        if (this.facturados === true) {
          if (this.form.value.creacion === '') {
            filter = filter + '&fecha_factura__from=2023-01-01&fecha_factura__to=2050-12-31';
          }
        }

        this.getPaquetes(filter);
        this.spinnerService.hide();
      }
    });

  }

  searchUser(value): Promise<string> {
    return new Promise((resolve) => {
      let filter = '';
      this.usuariosService.search(value).subscribe((res: any) => {
        res.forEach(element => {
          filter = filter + '&usuario=' + element.id;
        });
        resolve(filter);
      });
    });
  }

  public cleanFilters() {
    this.pageSize = 50;
    this.pageIndex = 1;
    this.getPaquetes();
    this.form.reset();
  }


  paginate(event: any) {
    this.pageIndex = event;
    this.getPaquetes(this.filter);
  }

  sortData(sort: Sort) {
    console.log(sort);
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'nombre':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'cuenta':
          return compare(a.cuenta, b.cuenta, isAsc);
        case 'guia':
          return compare(a.guia, b.guia, isAsc);
        case 'tipo_paquete':
          return compare(a.tipo_paquete, b.tipo_paquete, isAsc);
        case 'usuario':
          return compare(a.nombre, b.nombre, isAsc);
        case 'descripcion':
          return compare(a.descripcion, b.descripcion, isAsc);
        case 'tracking':
          return compare(a.tracking, b.tracking, isAsc);
        default:
          return 0;
      }
    });
  }

  exportTable(exporter) {
    this.exporter = exporter;
    this.getPaquetes(this.filter, true);
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  //console.log(a,b);
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
