/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaquetesService } from '../../../../../services/paquetes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { GeneralDialogComponent } from '../../../../common/dialogs/general-dialog/general-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../services/auth.service';
import { DashboardComponent } from '../../../dashboard/dashboard.component';

export interface Actions {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-capturar-paquetes-zona',
  templateUrl: './capturar-paquetes-zona.component.html',
  styleUrls: ['./capturar-paquetes-zona.component.scss']
})
export class CapturarPaquetesZonaComponent implements OnInit {
  @ViewChild('listapaquetes') selectionList: MatSelectionList;

  length: number;
  pageSize = 10;
  pageIndex = 1;

  selectedZona = 'A';
  qrNoExiste = false;

  zona_nueva = 'A';
  zonasPaquetes = [
    { id: 'A', zona: 'A' },
    { id: 'B', zona: 'B' },
    { id: 'C', zona: 'C' },
    { id: 'D', zona: 'D' },
    { id: 'E', zona: 'E' },
    { id: 'F', zona: 'F' },
    { id: 'G', zona: 'G' },
    { id: 'H', zona: 'H' },
    { id: 'I', zona: 'I' },
    { id: 'J', zona: 'J' },
    { id: 'K', zona: 'K' },
    { id: 'L', zona: 'L' },
    { id: 'M', zona: 'M' },
    { id: 'N', zona: 'N' },
    { id: 'O', zona: 'O' },
    { id: 'P', zona: 'P' },
    { id: 'Q', zona: 'Q' },
    { id: 'R', zona: 'R' },
    { id: 'S', zona: 'S' },
    { id: 'T', zona: 'T' },
    { id: 'U', zona: 'U' },
    { id: 'V', zona: 'V' },
    { id: 'W', zona: 'W' },
    { id: 'X', zona: 'X' },
    { id: 'Y', zona: 'Y' },
    { id: 'Z', zona: 'Z' },
    { id: 'AA', zona: 'AA' },
    { id: 'AB', zona: 'AB' },
    { id: 'AC', zona: 'AC' },
    { id: 'AD', zona: 'AD' },
    { id: 'AE', zona: 'AE' },
    { id: 'AF', zona: 'AF' },
    { id: 'AG', zona: 'AG' },
    { id: 'AH', zona: 'AH' },
    { id: 'AI', zona: 'AI' },
    { id: 'AJ', zona: 'AJ' },
    { id: 'AK', zona: 'AK' },
    { id: 'AL', zona: 'AL' },
    { id: 'AM', zona: 'AM' },
    { id: 'AN', zona: 'AN' },
    { id: 'AO', zona: 'AO' },
    { id: 'AP', zona: 'AP' },
    { id: 'AQ', zona: 'AQ' },
    { id: 'AR', zona: 'AR' },
    { id: 'AS', zona: 'AS' },
    { id: 'AT', zona: 'AT' },
    { id: 'AU', zona: 'AU' },
    { id: 'AV', zona: 'AV' },
    { id: 'AW', zona: 'AW' },
    { id: 'AX', zona: 'AX' },
    { id: 'AY', zona: 'AY' },
    { id: 'AZ', zona: 'AZ' },
    { id: 'BA', zona: 'BA' },
    { id: 'BB', zona: 'BB' },
    { id: 'BC', zona: 'BC' },
    { id: 'BD', zona: 'BD' },
    { id: 'BE', zona: 'BE' },
    { id: 'BF', zona: 'BF' },
    { id: 'BG', zona: 'BG' },
    { id: 'BH', zona: 'BH' },
    { id: 'BI', zona: 'BI' },
    { id: 'BJ', zona: 'BJ' },
    { id: 'CC', zona: 'CC' },
    { id: 'DD', zona: 'DD' },
    { id: 'EE', zona: 'EE' },
    { id: 'FF', zona: 'FF' },
    { id: 'GG', zona: 'GG' },
    { id: 'HH', zona: 'HH' },
    { id: 'II', zona: 'II' },
    { id: 'JJ', zona: 'JJ' },
    { id: 'KK', zona: 'KK' },
    { id: 'LL', zona: 'LL' },
    { id: 'MM', zona: 'MM' },
    { id: 'NN', zona: 'NN' },
    { id: 'OO', zona: 'OO' },
    { id: 'PP', zona: 'PP' },
    { id: 'QQ', zona: 'QQ' },
    { id: 'RR', zona: 'RR' },
    { id: 'SS', zona: 'SS' },
    { id: 'TT', zona: 'TT' },
    { id: 'UU', zona: 'UU' },
    { id: 'VV', zona: 'VV' },
    { id: 'WW', zona: 'WW' },
    { id: 'XX', zona: 'XX' },
    { id: 'YY', zona: 'YY' },
    { id: 'ZZ', zona: 'ZZ' },
  ];

  paquetes: any = [];
  tracking = '';
  descripcion = '';
  guia = '';

  constructor(
    private paquetesService: PaquetesService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public authService: AuthService,
    public dashboardComponent: DashboardComponent,
  ) { }

  ngOnInit() { 
    this.generateColumnNames(104);
  }

  playAudioWrong() {
    const audio = new Audio();
    audio.src = '../../../assets/wrong.wav';
    audio.load();
    audio.play();
  }
  playAudioCorrect() {
    const audio = new Audio();
    audio.src = '../../../assets/correct.wav';
    audio.load();
    audio.play();
  }

  // Busqueda Manual
  findByManualInput(value) {

    this.tracking = value;
    this.guia = value;

    this.paquetesService.findGuia(this.guia).subscribe((data: any) => {
      const own = this;
      const filter = this.paquetes.filter(function(el) {
        return el.numero_de_guia.toLocaleLowerCase() === own.guia.toLocaleLowerCase();
      });

      if (data.length > 0 && filter.length === 0) {
        data[0].valid = true;
        this.paquetes.push(data[0]);
        this.cambiarZona();
        this.playAudioCorrect();
      } else {
        if (data.length === 0) {
          this.openDialog('Este paquete no se encuentra registrado');
          this.playAudioWrong();
        }
      }

      this.tracking = '';
      this.guia = '';
    },
      err => {
        //console.log('Error Find QR ', err);
        this.qrNoExiste = true;
        this.playAudioWrong();
      });
  }

  cambiarZona() {
    const data = [];
    this.paquetes.forEach(paquete => {

      if (paquete.valid) {
        const item = {
          id: paquete.id,
          zona_centro_distribucion: this.zona_nueva,
          logged_user: this.authService.getUser().id,
        };
        data.push(item);
      }
    });

    this.paquetesService.capturarZona(this.zona_nueva, data).subscribe(data => {
      this.spinner.hide();
      //console.log(data);
    });

  }

  openDialog(message, duration = 3000) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
      panelClass: 'general'
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, duration);
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  generateColumnNames(n){
    const columns = [];
    columns.push({zona: '--', id: null});
    for (let i = 0; i < n; i++) {
      let columnName = '';
      let j = i;
      while (j >= 0) {
        columnName = String.fromCharCode((j % 26) + 65) + columnName;
        j = Math.floor(j / 26) - 1;
      }
      columns.push({zona: columnName, id: columnName});
    }
    this.zonasPaquetes = columns;
    return columns;
  };

}
