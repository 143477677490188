export const idTypes = [
  {
    id: 0,
    name: 'Cédula Física',
    regex:  /(\d{2})(\d{4})(\d{4})/,
    validateRegex: /^\d{2}-\d{4}-\d{4}$/,
    format: '$1-$2-$3',
    length: 9
  },
  {
    id: 1,
    name: 'Cédula Jurídica',
    regex: /(\d{1})(\d{3})(\d{6})/,
    validateRegex: /^\d{1}-\d{3}-\d{6}$/,
    format: '$1-$2-$3',
    length: 10
  },
  {
    id: 2,
    name: 'Extranjero no Residente',
    regex: /(9)(\d{1,19})/,
    validateRegex: /^9.{1,19}$/,
    format: '$1',
    length: 20
  },
  {
    id: 3,
    name: 'Documento Único de Identicación (DIMEX)',
    regex: /(1)([0-9]{11})/,
    validateRegex: /^1[0-9]{11}$/,
    format: '$1$2',
    length: 12
  },
  {
    id: 4,
    name: 'Documento de Identicación para Diplomáticos (DIDI)',
    regex: /(5)([0-9]{11})/,
    validateRegex: /^5[0-9]{11}$/,
    format: '$1$2',
    length: 12
  }
];