<div class="content-general">
  <div>
    <div class="pt-2"
      style="width: 100%; text-align: center; font-family: Varela Round, sans-serif; font-size: 18px !important;">
      <button mat-button [routerLink]="['/admin/administracion/tarifas']"
        style="padding: 0; padding-right: 10px; color: #0094a3; float: left;">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>

      Editar Tarifas

      <button *ngIf="authService.validateRolePermission('EDIT_FEE')" mat-flat-button color="primary"
        class="button-calcular" style="float: right;" (click)="saveTarifa()">
        <mat-icon color="light" style="font-size: 1.5em !important; color: white !important">save</mat-icon>
        Guardar</button>
    </div>
  </div>

  <mat-divider></mat-divider>


  <div class="row">
    <div [formGroup]="formTarifa" class="col-12 col-md-6">

      <div class="content-general">
        <div class="content" style="padding: 10px">

          <div class="content-title">
            <p class="title">Descripción de la tarifa</p>
          </div>

          <div class="mt-5 pt-4">
            <div class="row">
              <!-- <div class="col-12 col-md-6 col-lg-8">-->
              <div class="col-12">
                <label class="label">Nombre</label><br>
                <mat-form-field appearance="outline" class="form-field">
                  <input #nombre (blur)="checkEmail(nombre.value,'nombre')" matInput formControlName="nombre"
                    class="input-field" [readonly]="authService.isOperacionesCostaRica2">
                  <mat-error *ngIf="formTarifa.controls['nombre'].errors && !authService.isOperacionesCostaRica2">
                    {{getErrorMessage(formTarifa.controls['nombre'])}}
                  </mat-error>
                </mat-form-field>
              </div>

              <!--<div class="col-12 col-md-6 col-lg-4">
                <label class="label">Código</label><br>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput formControlName="codigo" class="input-field" readonly disabled>
                </mat-form-field>
              </div>-->

            </div>

            <div class="row">
              <div class="col-12">
                <label class="label">Descripción</label><br>
                <mat-form-field appearance="outline" class="form-field">
                  <input matInput formControlName="descripcion" class="input-field"
                  [readonly]="authService.isOperacionesCostaRica2">
                </mat-form-field>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="content-general">
        <div class="content" style="margin-top: 10px; padding: 10px">
          <div class="content-title">
            <p class="title">Detalles de Tarifa</p>
          </div>

          <div class="mt-5 pt-4">
            <ng-container *ngTemplateOutlet="RangoTarifas"></ng-container>
          </div>

        </div>
      </div>

      <div class="content" style="margin-top: 10px; padding: 10px">
        <div class="content-table" style="margin-top: 10px;">

          <table mat-table [dataSource]="dataSourceTarifas">

            <ng-container matColumnDef="flete">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Exonerar Flete </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox [disabled]="authService.isOpsCR2()"
                  formControlName="exonerar_flete"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="warehouse">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Exonerar Warehouse </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox [disabled]="authService.isOpsCR2()"
                  formControlName="exonerar_warehouse"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="manejos">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Exonerar Manejos </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox [disabled]="authService.isOpsCR2()"
                  formControlName="exonerar_manejos"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="seguro">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Exonerar Seguro </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox [disabled]="authService.isOpsCR2()"
                  formControlName="exonerar_seguro"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="combustible">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Exonerar Combustible </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox [disabled]="authService.isOpsCR2()"
                  formControlName="exonerar_combustible"></mat-checkbox>
              </td>
            </ng-container>

            <!--
            <ng-container matColumnDef="sucursal">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef> Disponible Para Sucursal </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox formControlName="disponible"></mat-checkbox>
              </td>
            </ng-container>
            -->

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTarifas;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTarifas;"></tr>
          </table>
        </div>
      </div>

    </div>

    <div class="col-12 col-md-6">
      <div class="content-general">
        <div class="content p-5">
          <div class="content-title">
            <p class="title">Variables Generales</p>
          </div>

          <div class="terminos mt-4 pt-4">
            <tabset #staticTabs [justified]="true">

              <tab heading="Generales">
                <div *ngFor="let variable of variablesGenerales">
                  <label class="label">{{variable.description}}</label><br>
                  <mat-form-field appearance="outline" class="form-field">
                    <span style="position: absolute;">{{variable.unit}}</span>
                    <input style="margin-left: 16px;" matInput placeholder="{{variable.descripcion}}"
                      [(ngModel)]="variable.value" matTooltip="{{variable.info}}" class="input-field"
                      [readOnly]="!authService.validateRolePermission('EDIT_FEE')">
                  </mat-form-field>
                </div>
              </tab>

              <tab heading="Permisos y Exoneraciones">
                <div *ngFor="let variable of variablesPermisos">
                  <label class="label">{{variable.description}}</label><br>
                  <mat-form-field appearance="outline" class="form-field">
                    <span style="position:absolute">{{variable.unit}}</span>
                    <input style="margin-left: 16px;" matInput placeholder="{{variable.descripcion}}"
                      [(ngModel)]="variable.value" matTooltip="{{variable.info}}" class="input-field"
                      readonly="authService.isOperacionesCostaRica2">

                  </mat-form-field>
                </div>
              </tab>

            </tabset>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #RangoTarifas>

  <tabset #staticTabs [justified]="true">

    <tab heading="Flete Internacional" (selectTab)="onSelect($event)">
      <div class="content" fxLayout="column">
        <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">

          <div class="botones-opciones" *ngIf="authService.validateRolePermission('EDIT_FEE')">
            <button mat-button (click)="openTarifaDialog('', 'flete', dataSourceFlete)" *ngIf="isNew == false">AGREGAR
              TARIFA DE FLETE
              INTERNACIONAL</button>
          </div>

          <div class="content-encontrados">
            <span class="texto-encontrados">{{dataSourceFlete.data.length}} Tarifas de Flete Encontrada(s)</span>
            <!-- <button mat-flat-button color="primary" class="button-calcular">Editar</button> -->
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="content-table mr-4 ml-4 mb-4">
          <table mat-table [dataSource]="dataSourceFlete" matSort>

            <ng-container matColumnDef="opciones">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true"> Opciones </th>
              <td mat-cell class="text-center" mat-cell *matCellDef="let element">

                <button class="del-item" mat-button (click)="openTarifaDialog(element, 'flete',dataSourceFlete)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button class="del-item" mat-button (click)="deleteTarifa(element.id, 'flete')">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="min">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Min </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.min_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="max">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Max </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.max_amount }} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto $</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>

            <ng-container matColumnDef="fijo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Fijo </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox disabled [checked]="element.is_amount_fixed"></mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsFlete; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsFlete;"></tr>
          </table>
        </div>
      </div>

    </tab>

    <tab heading="Tarifas de Manejo" (selectTab)="onSelect($event)">

      <div class="content" fxLayout="column">
        <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">

          <div class="botones-opciones" *ngIf="authService.validateRolePermission('EDIT_FEE')">
            <button mat-button (click)="openTarifaDialog('', 'manejo',dataSourceManejo)" *ngIf="isNew == false">AGREGAR
              TARIFA DE
              MANEJO</button>
          </div>

          <div class="content-encontrados">
            <span class="texto-encontrados">{{dataSourceManejo.data.length}} Tarifas de Manejo Encontrada(s)</span>
            <!-- <button mat-flat-button color="primary" class="button-calcular">Editar</button> -->
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="content-table mr-4 ml-4 mb-4">
          <table mat-table [dataSource]="dataSourceManejo" matSort>

            <ng-container matColumnDef="opciones">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true"> Opciones </th>
              <td mat-cell class="text-center" mat-cell *matCellDef="let element">
                <button class="del-item" mat-button (click)="openTarifaDialog(element, 'manejo',dataSourceManejo)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button class="del-item" mat-button (click)="deleteTarifa(element.id, 'manejo')">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="min">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Min </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.min_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="max">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Max </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.max_amount }} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto %</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>

            <ng-container matColumnDef="fijo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Fijo </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox disabled [checked]="element.is_amount_fixed"></mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsManejo; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsManejo;"></tr>
          </table>
        </div>
      </div>
    </tab>

    <tab heading="Bodegaje" (selectTab)="onSelect($event)">


      <div class="content" fxLayout="column">
        <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">

          <div class="botones-opciones" *ngIf="authService.validateRolePermission('EDIT_FEE')">
            <button mat-button (click)="openTarifaDialog('', 'bodegaje', dataSourceBodegaje)"
              *ngIf="isNew == false">AGREGAR TARIFA DE
              BODEGAJE</button>
          </div>

          <div class="content-encontrados">
            <span class="texto-encontrados">{{dataSourceBodegaje.data.length}} Tarifas de Bodegaje Encontrada(s)</span>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="content-table mr-4 ml-4 mb-4">
          <table mat-table [dataSource]="dataSourceBodegaje" matSort>

            <ng-container matColumnDef="opciones">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true"> Opciones </th>
              <td mat-cell class="text-center" mat-cell *matCellDef="let element">
                <button class="del-item" mat-button (click)="openTarifaDialog(element, 'bodegaje', dataSourceBodegaje)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button class="del-item" mat-button (click)="deleteTarifa(element.id, 'bodegaje')">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="min">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Min </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.min_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="max">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Max </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.max_amount }} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto $</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>

            <ng-container matColumnDef="fijo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Fijo </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox disabled [checked]="element.is_amount_fixed"></mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsBodegaje; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsBodegaje;"></tr>
          </table>
        </div>
      </div>

    </tab>

    <tab heading="Transporte Local" (selectTab)="onSelect($event)">

      <div class="content" fxLayout="column">
        <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">

          <div class="botones-opciones" *ngIf="authService.validateRolePermission('EDIT_FEE')">
            <button mat-button (click)="openTarifaDialog('', 'transporte',dataSourceTransporteLocal)"
              *ngIf="isNew == false">AGREGAR TARIFA</button>
          </div>

          <div class="content-encontrados pt-4 w-50" *ngIf="authService.validateRolePermission('EDIT_FEE')">
            <mat-form-field appearance="outline">
              <mat-label>Empresa</mat-label>
              <mat-select #emp placeholder="Seleccione" [value]="selectedEmpresa"
                (valueChange)="cambiarEmpresa(emp.value)">
                <mat-option value="-1">
                  AGREGAR EMPRESA
                </mat-option>
                <mat-option *ngFor="let empresa of empresas" [value]="empresa.id">
                  {{empresa.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="content-encontrados">
            <span class="texto-encontrados">{{dataSourceTransporteLocal.data.length}} Tarifas Encontrada(s)</span>
            <!-- <button mat-flat-button color="primary" class="button-calcular">Editar</button> -->
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="content-table mr-4 ml-4 mb-4">
          <table mat-table [dataSource]="dataSourceTransporteLocal" matSort>

            <ng-container matColumnDef="opciones">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true"> Opciones </th>
              <td mat-cell class="text-center" mat-cell *matCellDef="let element">
                <button class="del-item" mat-button
                  (click)="openTarifaDialog(element, 'transporte',dataSourceTransporteLocal)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button class="del-item" mat-button (click)="deleteTarifa(element.id, 'transporte')">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="min">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Min </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.min_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="max">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Max </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.max_amount !== -1 ? element.max_amount
                : element.min_amount + '+' }} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto ₡</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.amount !== -1 ? element.amount :
                'Cotizar' }} </td>
            </ng-container>

            <ng-container matColumnDef="fijo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Fijo </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox disabled disabled [checked]="element.is_amount_fixed"></mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTransporteLocal; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTransporteLocal;"></tr>
          </table>
        </div>
      </div>
    </tab>

    <tab heading="Embalaje" (selectTab)="onSelect($event)" *ngIf="formTarifa.value.codigo == 'A'">


      <div class="content" fxLayout="column">
        <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">

          <div class="botones-opciones" *ngIf="authService.validateRolePermission('EDIT_FEE')">
            <button mat-button (click)="openTarifaDialog('', 'embalaje',dataSourceEmbalaje)"
              *ngIf="isNew == false">AGREGAR TARIFA DE EMBALAJE</button>
          </div>

          <div class="content-encontrados">
            <span class="texto-encontrados">{{dataSourceEmbalaje.data.length}} Tarifas de Embalaje Encontrada(s)</span>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="content-table mr-4 ml-4 mb-4">
          <table mat-table [dataSource]="dataSourceEmbalaje" matSort>

            <ng-container matColumnDef="opciones">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true"> Opciones </th>
              <td mat-cell class="text-center" mat-cell *matCellDef="let element">
                <button class="del-item" mat-button (click)="openTarifaDialog(element, 'embalaje',dataSourceEmbalaje)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button class="del-item" mat-button (click)="deleteTarifa(element.id, 'embalaje')">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="min">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Min </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.min_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="max">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Max </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.max_amount }} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto $</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>

            <ng-container matColumnDef="fijo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Fijo </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox disabled [checked]="element.is_amount_fixed"></mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsEmbalaje; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsEmbalaje;"></tr>
          </table>
        </div>
      </div>
    </tab>

    <tab heading="Correspondencia" (selectTab)="onSelect($event)" *ngIf="formTarifa.value.codigo == 'A'">

      <div class="content" fxLayout="column">
        <div fxLayout="row wrap" class="pr-4 pl-4 pt-2 header-botones-opciones">

          <div class="botones-opciones" *ngIf="authService.validateRolePermission('EDIT_FEE')">
            <button mat-button (click)="openTarifaDialog('', 'correspondencia',dataSourceCorrespondencia)"
              *ngIf="isNew == false">AGREGAR TARIFA DE CORRESPONDENCIA</button>
          </div>

          <div class="content-encontrados">
            <span class="texto-encontrados">{{dataSourceCorrespondencia.data.length}} Tarifas de Correspondencia
              Encontrada(s)</span>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="content-table mr-4 ml-4 mb-4">
          <table mat-table [dataSource]="dataSourceCorrespondencia" matSort>

            <ng-container matColumnDef="opciones">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header [disabled]="true"> Opciones </th>
              <td mat-cell class="text-center" mat-cell *matCellDef="let element">
                <button class="del-item" mat-button
                  (click)="openTarifaDialog(element, 'correspondencia',dataSourceCorrespondencia)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button class="del-item" mat-button (click)="deleteTarifa(element.id, 'correspondencia')">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="min">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Min </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.min_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="max">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Max </th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.max_amount }} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto $</th>
              <td mat-cell class="pr-pl-20" *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>

            <ng-container matColumnDef="fijo">
              <th mat-header-cell class="pr-pl-20" *matHeaderCellDef mat-sort-header> Monto Fijo </th>
              <td mat-cell class="pr-pl-20 check-center" *matCellDef="let element">
                <mat-checkbox disabled [checked]="element.is_amount_fixed"></mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsCorrespondencia; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCorrespondencia;"></tr>
          </table>
        </div>
      </div>

    </tab>
  </tabset>
</ng-template>