/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../../services/auth.service';
import { PagosService } from '../../../../services/pagos.service';
import { PaquetesService } from '../../../../services/paquetes.service';
import { UsuariosService } from '../../../../services/usuarios.service';
import { FacturasService } from '../../../../services/facturas.service';
import { RutasService } from '../../../../services/rutas.service';
import { environment } from '../../../../../environments/environment';
import { GeneralDialogComponent } from '../general-dialog/general-dialog.component';
import { TranslateService } from '../../../../services/translate.service';
import { ConfirmarComponent } from '../confirmar/confirmar.component';
import { dvPaymentResponse } from '../../../../../app/core/data/dvPaymentResponse';
import { formatId } from 'src/app/core/utils/validate-id';
import { DataService} from 'src/app/services/data.service';

export interface DialogData {
  origin: string;
  title: string;
  paquetes: any;
  monto_usd: number;
  monto_crc: number;
  paquete: any;
  tipo_cambio: number;
  aprobado: boolean;
  tipo: string;
  cargo_encomienda: number;
  detail: string;
  referencia: string;
  metodo_pago: number;
  entrega: any;
  disabledFields: boolean;
  filterGuides: [];
  filterTrackings: [];
  peso: number;
}

@Component({
  selector: 'app-programar-entrega',
  templateUrl: './programar-entrega.component.html',
  styleUrls: ['./programar-entrega.component.scss'],
})
export class ProgramaEntregaComponent implements OnInit {

  @ViewChild('imageF1') imageF1: ElementRef;
  @ViewChild('imageF2') imageF2: ElementRef;

  DIFFERENCE_AMOUNT = 500;
  validatePayment = true;
  dvPaymmentResponses = dvPaymentResponse;

  value;
  selectedPaymentTotal = 0;

  showAttachPayment = false;

  metodoEntrega;
  displayedColumns: string[] = ['select', 'monto', 'codReferencia', 'descripcion'];
  fechaEntrega = new Date();
  metodosPago;
  detail: string;
  metodosPagoEntrega;
  metodoPago;
  metodoPagoEntrega;
  direccionEntrega;
  detallesAdicionales;
  direccionEntregaApps;
  provincia: any;
  canton: any;
  distrito: any;
  sucursal;
  ruta;
  tipoZonaEntrega;

  entregaObservacion;

  latitude: number;
  longitude: number;
  zoom = 8;
  address: string;

  distritos;
  displayLegend = false;
  cantones: any = [];
  user: any;
  provincias;
  rutas;

  comprobanteForm1: any;
  comprobanteForm2: any;
  nrocomprobante1 = '';
  nrocomprobante2 = '';
  editF1 = true;
  viewFile1 = false;
  fileLoad1: any;

  editF2 = true;
  viewFile2 = false;
  fileLoad2: any;

  formEntregaChanged = false;

  totalEncomienda = 0;
  totalEncomiendaCRC = 0;

  mediaUrl = environment.mediaUrl;

  pagoTarjeta;

  totalUSD = 0;
  totalCRC = 0;

  autoriza_entrega = false;
  readonly = this.data.disabledFields || false;
  filterGuides = this.data.filterGuides ? this.data.filterGuides : [];

  dvColumns: string[] = ['select', 'monto', 'descripcion', 'fechaRecibido', 'codReferencia'];
  dvResult: any;

  selectedPackages: any = [];

  idTypes = this.dataService.getIdTypes();
  selectedId: any;
  order: any;
  private geoCoder;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ProgramaEntregaComponent>,
    private spinnerService: NgxSpinnerService,
    private pagosService: PagosService,
    private router: Router,
    private authService: AuthService,
    private datePipe: DatePipe,
    private paqueteService: PaquetesService,
    private dialog: MatDialog,
    private usuariosService: UsuariosService,
    private mapsAPILoader: MapsAPILoader,
    private fb: FormBuilder,
    private facturasService: FacturasService,
    private rutasService: RutasService,
    private dataService: DataService
  ) {

    this.dialogRef.backdropClick().subscribe(_ => {
      this.dialogRef.close({ metodo: this.metodoPago, status: false, change: this.formEntregaChanged });
    });
  }

  disabledDates = (d: Date): boolean => {
    d.setHours(1, 0, 0, 0);
    const time = d.getTime();
    const day = d.getDay();
    const date = d.getDate();
    const month = d.getMonth();
    const currentHour = new Date().getHours();

    if (this.isHoliday(d)) {
      return false;
    }

    const today = new Date();
    const isFridayAfter18 = (today.getDay() === 5 && today.getHours() >= 17) || today.getDay() === 6 || today.getDay() === 0;
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + ((7 - today.getDay() + 1) % 7 || 7));

    if (isFridayAfter18 && nextMonday.getDate() === date) {
      return false;
    }

    if (this.tipoZonaEntrega === 'v') {
      if ((day === 5 && currentHour >= 17) || day > 5) {
        return new Date().getTime() < time && day !== 0 && day !== 6;
      } else {
        return new Date().getTime() < time && day !== 0;
      }
    } else {
      return new Date().getTime() < time && day !== 0 && day !== 6;
    }
  };

  validateDate(event: any) {
    const selectedDate: Date = event.value;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    if (!selectedDate) {
      return;
    }

    if (this.isWeekend(selectedDate) || selectedDate < tomorrow || this.isHoliday(selectedDate)) {
      this.fechaEntrega = null;
      return;
    }
  }

  isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

  isHoliday(date: Date): boolean {
    const day = date.getDate();
    const month = date.getMonth();

    return (month === 11 && day === 25) || // Christmas
      (month === 0 && day === 1) || // New Year's Day
      (month === 4 && day === 1) || // May 1st
      (month === 8 && day === 15) || // September 15th
      (month === 11 && day === 1); // December 1st
  }

  isChecked(row): boolean {
    return this.selectedPackages.some(pkg => pkg === row);
  }

  changeCheck(event, row) {
    if (event.checked) {
      this.selectedPackages.push(row);
      this.selectedPaymentTotal += row.monto;
    } else {
      this.selectedPackages = this.selectedPackages.filter((element: any) => element.codReferencia !== row.codReferencia);
      this.selectedPaymentTotal -= row.monto;
    }
    this.validatePayment = this.validatePaymentAmount();
  }

  getProvincias() {
    this.usuariosService.getProvincias().subscribe(data => {
      this.provincias = data;
    });
  }

  getCantones(idProvincia) {
    this.usuariosService.getCantones(idProvincia).subscribe(data => {
      this.cantones = data;
    });
  }

  getDistritos(idProvincia, idCanton) {
    return new Promise((resolve, reject) => {
      if (idProvincia === undefined) {
        idProvincia = this.provincia;
      }

      this.usuariosService.getDistritos(idProvincia, idCanton).subscribe(data => {
        this.distritos = data;
        resolve('ok');
      });
      this.canton = idCanton;
    });
  }

  getRutas() {
    this.rutasService.get().subscribe(value => {
      this.rutas = value;
    });
  }

  getProvincia(value) {

    try {
      const item = this.provincias.filter(function (e) {
        return e.id === value;
      });
      if (item[0] !== undefined && item[0] != null) {
        return item[0]['provincia'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getCanton(value) {

    try {
      const item = this.cantones.filter(function (e) {
        return e.id === value;
      });

      if (item[0] !== undefined && item[0] !== null) {

        return item[0]['canton'];
      }
      return '';
    } catch (error) {
      return '';
    }

  }

  getDistrito(value) {
    try {
      const item = this.distritos.filter(function (e) {
        return e.id === value;
      });
      if (item[0] !== undefined && item[0] !== null) {
        return item[0]['distrito'];
      }
      return '';
    } catch (error) {
      return '';
    }
  }

  validatePaymentAmount() {
    if (Math.abs(this.selectedPaymentTotal - this.data.monto_crc) >= this.DIFFERENCE_AMOUNT) {
      return true;
    }
    return false;
  }

  getDistritoTipo(value) {
    try {
      const item = this.distritos.filter(function (e) {
        return e.id === value;
      });

      if (item[0] !== undefined && item[0] !== null) {
        return item[0]['tipo'];
      }
    } catch (error) {
      return '';
    }
  }

  ngOnInit() {
    // this.getProvincias();
    // this.getRutas();
    this.selectedId = {...this.idTypes[0], value: ''};
    this.user = JSON.parse(localStorage.getItem('user'));

    this.comprobanteForm1 = this.fb.group({
      paquete: [''],
      factura: [''],
      referencia: [''],
      monto: [''],
      monto_crc: [''],
      comprobante: [''],
      aprobado: [false]
    });

    this.comprobanteForm2 = this.fb.group({
      paquete: [''],
      factura: [''],
      referencia: [''],
      monto: [''],
      monto_crc: [''],
      comprobante: [''],
      aprobado: [false]
    });

    // Check if the delivery date is Friday, Saturday, or Sunday
    this.fechaEntrega = this.dataService.validateDeliveryDate(this.fechaEntrega);

    this.getMetodosPago().then(() => {

      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
      });

      this.authService.getUserById(this.data.paquetes[0].usuario.id).subscribe(data => {

        this.metodoEntrega = data.body.modo_de_entrega;
        // this.metodoPago = data.body.metodo_pago ? data.body.metodo_pago.id != 5 ? data.body.metodo_pago.id : 2 : 2;
        // TODO remove this line above and uncomment the line below once the payment gateway is fixed
        this.metodoPago = data.body.metodo_pago ? data.body.metodo_pago.id != 5 ? data.body.metodo_pago.id : 1 : 1;
        this.direccionEntrega = this.data.paquetes[0].entrega.direccion_entrega;
        this.detallesAdicionales = this.data.paquetes[0].entrega.detalles_adicionales;

        this.provincia = this.data.paquetes[0].entrega.provincia.id;
        this.getCantones(this.provincia);
        this.canton = this.data.paquetes[0].entrega.canton.id;
        this.distrito = this.data.paquetes[0].entrega.distrito.id;

        this.getDistritos(this.provincia, this.canton).then(() => {
          this.getMetodosPago().then(() => { });
          this.getCargoEncomienda();
        });

        this.sucursal = data.body.sucursal;
        this.ruta = this.data.paquetes[0].entrega.ruta ? this.data.paquetes[0].entrega.ruta.id : null;

        this.pagoTarjeta = data.body.pago_tarjeta;

        this.autoriza_entrega = data.body.autoriza_entrega;

        this.direccionEntregaApps = data.body.direccion_entrega_apps;
        if (this.direccionEntregaApps !== null && this.direccionEntregaApps.length !== 0) {
          const mapsApps = JSON.parse(this.direccionEntregaApps);
          this.latitude = mapsApps.lat;
          this.longitude = mapsApps.lng;
          this.address = mapsApps.address;
          this.direccionEntregaApps = mapsApps;
        } else {
          this.setCurrentLocation();
        }


      });
    });
  }

  validAddress() {
    if (this.metodoEntrega === 'ES') {
      return true;
    }
    if (this.distrito === null) {
      alert('Distrito is missing');
      return false;
    }
    if (this.provincia === null) {
      alert('Provincia is missing');
      return false;
    }
    if (this.canton === null) {
      alert('Canton is missing');
      return false;
    }
    if (this.direccionEntrega === null) {
      alert('Direccion Entrega is missing');
      return false;
    }
    if (this.detallesAdicionales === null) {
      alert('Detalles Adicionales is missing');
      return false;
    }
    return true;
  }

  programarEntrega() {
    // this.spinnerService.show();
    //this.createDeliveryPackage();
    this.payPackages();
  }

  payPackages() {
    if (this.metodoPago === 1 && this.pagoTarjeta === false) {
      // this.openDialog(this.translate.translate('El banco está presentando inconvenientes con el procesamiento de pagos en linea. Por el momento favor seleccionar el método de pago entre SINPE/transferencia o contra entrega con tarjeta.'));
      this.openDialog(this.translate.translate('Pago Electrónico Deshabilitado'));
      return;
    }

    let valid = false;
    if (this.metodoPago === 2 || this.metodoPago === 8) {
      if (this.fileLoad1) {
        valid = true;
      } else {
        valid = true;
      }
    } else {
      valid = true;
    }

    if (this.validAddress() === false) {
      this.openDialog(this.translate.translate('Debe ingresar todos los datos de la dirección'));
      return;
    }

    if (valid) {
      this.direccionEntregaApps = JSON.stringify({ lat: this.latitude, lng: this.longitude, address: this.address });

      const d = new Date(this.fechaEntrega);

      let message = this.filterGuides.length > 0
        ? '¿Confirma que desea coordinar esta entrega para el día ' + this.datePipe.transform(d, 'fullDate', '-0600', 'es-CR') + '?' + '\nPaquetes: ' + this.filterGuides.join(', ')
        : '¿Confirma que desea realizar el pago de los paquetes seleccionados?';

      const dialogRef = this.dialog.open(ConfirmarComponent, {
        data: { message: this.translate.translate(message) },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinnerService.show();
          if (this.data.paquetes.length === 1 && (this.data.cargo_encomienda <= 0 || this.data.origin === 'admin')) {
            this.order = this.data.paquetes[0];
            this.data.paquetes = [this.order];
            this.paymentPackages(d);
          } else {
            if (this.metodoPago == 1) {
              this.paymentPackages(d);
            }
            else {
              this.createDeliveryPackage(d);
            }
          }
        } else {
          this.spinnerService.hide();
          this.openDialog(this.translate.translate('No hay items seleccionados'));
        }
      });

    } else {
      this.openDialog(this.translate.translate('Comprobante de Pago Requerido. Adjunte su comprobante e intente nuevamente.'));
    }
  }

  paymentPackages(d: any): void {

    /* Pago en linea */
    if (this.metodoPago === 1) {
      if (this.data.paquetes.length > 0) {

        const dataEntrega = {
          modo_entrega: this.metodoEntrega,
          fecha_entrega: this.datePipe.transform(d, 'yyyy-MM-dd'),
          direccion_entrega: this.direccionEntrega,
          detalles_adicionales: this.detallesAdicionales,
          direccion_entrega_apps: this.direccionEntregaApps,
          tipo_zona_entrega: this.tipoZonaEntrega,
          sucursal: this.sucursal,
          ruta: this.ruta,
          provincia: this.provincia,
          canton: this.canton,
          distrito: this.distrito,
          entrega_observacion: this.entregaObservacion,
          logged_user: this.authService.getUser().id,
        };
        const orderNumber = this.user.cuenta + '-' + Date.now().toString();

        const idPaquete = this.data.paquetes[0].id;
        const idFactura = this.data.paquetes[0].factura.id;

        const orderData = this.setConsolidateData();
        const orderApplies = this.data.paquetes.length > 1 || this.data.cargo_encomienda > 0;

        const dataTarjeta = {
          paquete: idPaquete,
          factura: idFactura,
          order_number: orderNumber,
          monto: this.data.monto_usd.toFixed(2),
          monto_crc: this.data.monto_crc.toFixed(2),
          datos_entrega: JSON.stringify(dataEntrega),
          created_by: this.authService.getUser().id,
          order_package: orderApplies ? JSON.stringify(orderData) : {},
        };

        this.pagosService.postTarjeta(dataTarjeta).subscribe(() => {
          const dataPago = {
            paquetes: this.data.paquetes,
            monto: this.data.monto_usd.toFixed(2),
            monto_crc: this.data.monto_crc.toFixed(2),
            cargo_encomienda: this.totalEncomiendaCRC.toFixed(2),
            nota_debito: false,
            compra_corporativa: false,
            order_number: orderNumber,
            entrega: dataEntrega,
          };

          this.pagosService.datosFacturas = dataPago;
          this.dialogRef.close({ metodo: this.metodoPago, status: true, change: this.formEntregaChanged });
          this.spinnerService.hide();
          this.router.navigate(['dashboard/pagar'], { queryParams: { pagar: true } });
        });
      }
    }

    /* Pago SINPE/Transferencia */
    if (this.metodoPago === 2) {

      const dataComprobante = {
        deliveryDate: this.datePipe.transform(this.fechaEntrega, 'yyyy-MM-dd'),
        selectedPackages: this.order.id ? [this.order.id] : this.data.paquetes.map((paquete: any) => paquete.id),
        selectedPayments: this.selectedPackages,//this.selectedPackages.map((element: any) => element.codReferencia),
        token: this.dvResult ? this.dvResult.token : 'No se consultaron pagos disponibles',
      };

      this.pagosService.sinpePayment(dataComprobante).subscribe((data) => {
        if (this.authService.isCliente) {
          this.dialogRef.close({});
          this.openDialog(this.translate.translate('¡Su entrega ha sido coordinada! Más detalles en el paquete.'));
          this.router.navigate(['/dashboard']);
        } else {
          this.dialogRef.close({});
          this.spinnerService.hide();
          this.openDialog('Pago registrado');
          window.location.reload();
        }

      });
    }

    //Transferencia /* Tarjeta de Credito Debito */
    if (this.metodoPago === 8) {
      //this.data.paquetes.forEach((paquete, index, array) => {

      const id = this.order.id;
      const factura = this.order.factura.id;

      const dataPaquete = {
        orden: this.order.orden,
        metodo_pago: this.metodoPago,
        metodo_pago2: this.metodoPagoEntrega,
        //If payment status is 8 the package is not paid
        estado_pago: this.metodoPago === 8 ? 0 : 1,
        logged_user: this.authService.getUser().id,
      };

      // const dataEntrega = {
      //   modo_entrega: this.metodoEntrega,
      //   fecha_entrega: this.datePipe.transform(d, 'yyyy-MM-dd'),
      //   direccion_entrega: this.direccionEntrega,
      //   detalles_adicionales: this.detallesAdicionales,
      //   direccion_entrega_apps: this.direccionEntregaApps,
      //   tipo_zona_entrega: this.tipoZonaEntrega,
      //   sucursal: this.sucursal,
      //   ruta: this.ruta,
      //   provincia: this.provincia,
      //   canton: this.canton,
      //   distrito: this.distrito,
      //   entrega_observacion: this.entregaObservacion,
      //   logged_user: this.authService.getUser().id,
      // };

      let montoComprobante1 = this.order.factura.factura_crc.monto_pendiente;
      let montoComprobante1USD = this.order.factura.factura_usd.monto_pendiente.toFixed(2);
      const montoComprobante2 = this.order.factura.factura_crc.cargo_encomienda;
      const montoComprobante2USD = this.order.factura.factura_usd.cargo_encomienda;
      if (this.nrocomprobante1.length > 0 && this.nrocomprobante2.length > 0) {
        montoComprobante1 = this.order.factura_crc.total
      }

      if (this.selectedPackages.length === 1) {
        this.comprobanteForm1.value.created_by = this.authService.getUser().id;
      }


      this.comprobanteForm1.controls['paquete'].setValue(id);
      this.comprobanteForm1.controls['factura'].setValue(factura);
      this.comprobanteForm1.controls['referencia'].setValue(this.nrocomprobante1);
      this.comprobanteForm1.controls['monto'].setValue(montoComprobante1USD);
      this.comprobanteForm1.controls['monto_crc'].setValue((montoComprobante1));
      this.comprobanteForm1.controls['comprobante'].setValue(this.fileLoad1);
      this.comprobanteForm1.controls['aprobado'].setValue(this.data.aprobado);
      this.comprobanteForm1.value.aprobado_by = null;
      this.comprobanteForm1.value.created_by = this.authService.getUser().id;

      this.pagosService.postComprobante(this.comprobanteForm1.value).subscribe(() => {
        if (this.data.title.startsWith('Programar')) {
          //Se debe vincular el pago con davivienda que se va a utilizar
          this.procesarPago(this.metodoPago, this.comprobanteForm1.value.referencia, this.comprobanteForm1.value.monto, this.comprobanteForm1.value.monto_crc, this.order.usuario.id, this.authService.getUser().id, this.order, factura);
        }

        this.paqueteService.put(id, dataPaquete).subscribe((paquete: any) => {
          this.paqueteService.checkProgramados(id).subscribe(() => { });

          this.spinnerService.hide();

          if (!this.data.title.startsWith('Programar')) {

            if (this.authService.isCliente) {
              this.openDialog(this.translate.translate('¡Su entrega ha sido coordinada! Más detalles en el paquete.'));
              this.router.navigate(['/dashboard']);
            }

            if (this.authService.isAdmin) {
              this.openDialog(this.translate.translate('Paquete Programado'));
            }

            this.crearFactura(paquete).then(() => { });
          }

          if (this.data.title.startsWith('Programar')) {
            if (this.authService.isAdmin) {
              this.openDialog(this.translate.translate('Paquete Programado'));
            }
          }

          this.dialogRef.close({ metodo: this.metodoPago, status: true, change: this.formEntregaChanged });
          this.spinnerService.hide();
          this.router.navigate(['/dashboard']);

        }, err => {
          this.spinnerService.hide();
          this.openDialog(this.translate.translate('Error al programar entrega'));
          console.error(err);
        });
      });
    }

    /* ContraEntrega */
    if (this.metodoPago === 4) {
      if (this.tipoZonaEntrega == 'v' || this.autoriza_entrega) {
        if (this.data.paquetes.length > 1) {
          this.data.paquetes.push(this.order);
        }
        this.data.paquetes.forEach((paquete, index) => {
          const id = paquete.id;

          const dataPaquete = {
            orden: paquete.orden,
            metodo_pago: this.metodoPago,
            metodo_pago2: this.metodoPagoEntrega,
            estado_pago: 0,
            logged_user: this.authService.getUser().id,
          };

          const dataEntrega = {
            fecha_entrega: this.datePipe.transform(d, 'yyyy-MM-dd'),

            direccion_entrega: this.direccionEntrega,
            detalles_adicionales: this.detallesAdicionales,
            direccion_entrega_apps: this.direccionEntregaApps,
            tipo_zona_entrega: this.tipoZonaEntrega,
            logged_user: this.authService.getUser().id,
            modo_entrega: this.metodoEntrega,
            sucursal: this.sucursal,
            ruta: this.ruta,
            canton: this.canton,
            entrega_observacion: this.entregaObservacion
          };

          this.paqueteService.put(id, dataPaquete).subscribe((paquete: any) => {
            this.paqueteService.putEntrega(paquete.entrega.id, dataEntrega).subscribe((entrega) => {
              this.paqueteService.checkProgramados(id).subscribe(() => { });

              this.spinnerService.hide();

              if (this.authService.isCliente) {
                this.openDialog(this.translate.translate('¡Su entrega ha sido coordinada! Más detalles en el paquete.'));
                this.router.navigate(['/dashboard']);
              }

              if (this.authService.isAdmin) {
                this.openDialog('Paquete Programado');
              }

              this.crearFactura(paquete);
              this.dialogRef.close({ metodo: this.metodoPago, status: true, change: this.formEntregaChanged });
            });

          }, err => {
            this.spinnerService.hide();
            this.openDialog(this.translate.translate('Error al programar entrega'));
            console.error(err);
          });

        });
      } else {
        this.openDialog(this.translate.translate('Este paquete no puede ser programado contraentrega'));
      }
    }
    // Metodo de pago otros - compra click y tarjeta credito/debito
    if (this.metodoPago === 7 || this.metodoPago === 9 || this.metodoPago === 3) {
      this.data.metodo_pago = this.metodoPago;
      this.data.referencia = this.nrocomprobante1;
      this.data.detail = (this.metodoPago === 7) ? this.detail : null;
      this.dialogRef.close(this.data);
      this.spinnerService.hide();
    }
  }

  onIdTypeChange(event) {
    this.selectedId = event;
  }

  verifyPayments(): void {
    const invoiceTotal = this.data.monto_crc.toFixed(2);
    const formattedResponse = formatId(this.selectedId);
    if (formattedResponse.error) {
      this.selectedId.value = '';
      this.openDialog(formattedResponse.data);
      return;
    }
    this.selectedId.value = formattedResponse.data;
    this.spinnerService.show();
    this.pagosService.getDVPayments(this.selectedId.value, invoiceTotal).subscribe((data: any) => {
      this.dvResult = data;
      if (this.dvResult.pagos.length > 0) {
        const paymentFound = this.dvResult.pagos.find(pago => Math.abs(pago.monto - +invoiceTotal) <= this.DIFFERENCE_AMOUNT);
        if (paymentFound) {
          this.selectedPackages = [paymentFound];
          this.selectedPaymentTotal = this.selectedPackages[0].monto;
          this.validatePayment = this.validatePaymentAmount();
        } else {
          this.selectedPackages = [];
        }
      }
      this.spinnerService.hide();
    });
  }

  getMetodosPago() {
    return new Promise(resolve => {
      this.usuariosService.getMetodosPago().subscribe(data => {
        this.metodosPago = data;

        if (this.user.rol === 'CLIENT') {
          //previaentrega
          this.metodosPago = this.metodosPago.filter(item => item.previaentrega === true);
          if (this.user.tipo_cliente.filter(item => item.nombre === 'Transferencia').length === 0) {
            this.metodosPago = this.metodosPago.filter(item => item.id !== 8);
          }
        }
        this.metodosPago = this.metodosPago.filter(el => {
          return el.id !== 5;
        });
        if (this.data.title.startsWith('Programar') || this.data.title.startsWith('Adjuntar')) {
          this.metodosPago = this.metodosPago.filter(item => item.contraentrega === true || item.special === true);
        }

        if (this.tipoZonaEntrega === 'r') {
          this.metodosPago = this.metodosPago.filter(el => {
            return el.id !== 4;
          });
        }
        if (this.authService.isServicioCliente) {
          this.metodosPago = this.metodosPago.filter(el => {
            return el.id !== 7;
          });
        }
      });
      resolve(true);
    });
  }

  openDialog(message) {
    this.spinnerService.hide();
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 6000);
    });
  }

  public onNoClick = (): void => {
    this.dialogRef.close({ metodo: 0, status: false, change: this.formEntregaChanged });
  };

  markerDragEnd($event) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.provincia = null;
    this.canton = null;
    this.distrito = null;
    this.getAddress(this.latitude, this.longitude);
  }

  markerClick($event) {
    if (!this.readonly) {
      this.latitude = $event.coords.lat;
      this.longitude = $event.coords.lng;
      this.getAddress(this.latitude, this.longitude);
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.direccionEntrega = this.address;
        } else {
          alert('No hay resultados');
        }
      } else {
        //console.log('Geocoder Fail: ' + status);
      }
    });
  }

  processFile1(event) {
    if (event.length > 0) {
      const file = event[0];
      this.fileLoad1 = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const size = file.size / 1024;
        if (size <= 20480) {
          if (file.name.includes('.docx') || file.name.includes('.pdf') || file.name.includes('.doc')) {
            this.viewFile1 = true;
          } else {
            if (file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png')) {
              this.viewFile1 = false;
              setTimeout(() => {
                this.imageF1.nativeElement.src = reader.result;
              }, 500);
              this.editF1 = true;
            } else {
              this.openDialog(this.translate.translate('Formato de archivo inválido'));
              this.fileLoad1 = null;
            }
          }

        } else {
          this.openDialog(this.translate.translate('Archivo supera los 20 megabytes'));
          this.fileLoad1 = null;
        }
      };
    }
  }

  processFile2(event) {
    if (event.length > 0) {
      const file = event[0];
      this.fileLoad2 = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const size = file.size / 1024;
        if (size <= 20480) {
          if (file.name.includes('.docx') || file.name.includes('.pdf') || file.name.includes('.doc')) {
            this.viewFile2 = true;
          } else {
            if (file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png')) {
              this.viewFile2 = false;
              setTimeout(() => {
                this.imageF2.nativeElement.src = reader.result;
              }, 500);
              this.editF2 = true;
            } else {
              this.openDialog(this.translate.translate('Formato de archivo inválido'));
              this.fileLoad2 = null;
            }
          }

        } else {
          this.openDialog(this.translate.translate('Archivo supera los 20 megabytes'));
          this.fileLoad1 = null;
        }
      };
    }
  }

  procesarPago(metodo_pago, referencia, monto, monto_crc, usuario, created_by, paquete, factura) {
    this.spinnerService.show();

    const data = {
      metodo_pago,
      monto,
      monto_crc,
      referencia,
      usuario,
      created_by
    };

    this.pagosService.procesarPago(data).subscribe((pago: any) => {

      if (pago) {

        const id = paquete.id;

        const paqueteData = {
          metodo_pago,
          orden: paquete.orden,
          metodo_pago2: metodo_pago,
          estado_pago: 1,
          logged_user: this.authService.getUser().id
        };

        // eslint-disable-next-line max-len
        this.facturasService.putFacturas(factura, { pago: pago.id, usuario, completada: true, bloqueada: true }).subscribe(() => {
          this.paqueteService.put(id, paqueteData).subscribe(() => {
            this.spinnerService.hide();
          }, err => {
            console.error(err);
          });
        });
      }
    });
  }

  onAddressChange(distrito = null) {

    if (this.formEntregaChanged === false) {

      this.formEntregaChanged = true;

      this.direccionEntregaApps = null;
      this.direccionEntrega = null;
      this.provincia = null;
      this.canton = null;
      this.distrito = null;
      this.tipoZonaEntrega = null;

      if (this.totalEncomienda > 0) {
        this.data.monto_usd = this.totalUSD;
        this.data.monto_crc = this.totalCRC;

        this.totalEncomienda = 0;
        this.totalEncomiendaCRC = 0;
      }

    } else {

      this.data.monto_usd = this.totalUSD;
      this.data.monto_crc = this.totalCRC;

      this.totalEncomienda = 0;
      this.totalEncomiendaCRC = 0;

      if (distrito != null) {
        this.distrito = distrito;
        this.getCargoEncomienda();
        this.openDialog('Se ha actualizado la dirección de entrega. El monto total de la factura cambia.');
      } else {
        this.data.monto_usd = this.totalUSD;
        this.data.monto_crc = this.totalCRC;
        this.totalEncomienda = 0;
        this.totalEncomiendaCRC = 0;
      }
    }
  }

  getCargoEncomienda() {
    this.spinnerService.show();

    // console.log(this.metodoEntrega, this.provincia, this.canton, this.distrito);

    if (this.metodoEntrega !== 'ES') {

      if (this.provincia && this.canton) {

        this.tipoZonaEntrega = this.getDistritoTipo(this.distrito);

        // console.log(this.tipoZonaEntrega);

        if (this.tipoZonaEntrega) {
          // this.data.monto_usd = 0;
          // this.data.monto_crc = 0;

          this.totalEncomienda = 0;
          this.totalEncomiendaCRC = 0;

          this.totalUSD = 0;
          this.totalCRC = 0;

          this.data.paquetes.forEach((paquete, index, array) => {

            const dataEntrega = {
              direccion_entrega: this.direccionEntrega,
              detalles_adicionales: this.detallesAdicionales,
              direccion_entrega_apps: this.direccionEntregaApps,
              tipo_zona_entrega: this.tipoZonaEntrega,
              logged_user: this.authService.getUser().id,
              modo_entrega: this.metodoEntrega,
              sucursal: this.sucursal,
              ruta: this.ruta,
              canton: this.canton,
              entrega_observacion: this.entregaObservacion
            };

            let entrega = 0;
            if (typeof (paquete.entrega) == 'object') {
              entrega = paquete.entrega.id;
            } else {
              entrega = paquete.entrega;
            }

            this.paqueteService.putEntrega(entrega, dataEntrega).subscribe(() => {
              this.crearFactura(paquete).then((res: any) => {
                paquete.factura.cargo_encomienda = res.factura_crc.cargo_encomienda;
                paquete.factura.total = res.factura_crc.total - res.factura_crc.cargo_encomienda;
                if (index === array.length - 1) {
                  this.spinnerService.hide();
                }
              });

            });
          });
        } else {
          this.spinnerService.hide();
        }

      }
    } else {
      this.spinnerService.hide();
    }
  }


  createDeliveryPackage(d: any): void {
    const newPackage = this.setConsolidateData();
    this.paqueteService.postConsolidado(newPackage).subscribe((data: any) => {
      this.order = data;
      this.paymentPackages(d);
    }, (error: any) => {
      this.openDialog('Error al crear el paquete');
      this.spinnerService.hide();
      this.router.navigate(['/dashboard']);
    });
  }

  setConsolidateData() {
    if (this.data.entrega) {
      this.data.entrega.fecha_entrega = this.datePipe.transform(this.fechaEntrega, 'yyyy-MM-dd');
    }
    let newObservation = '';
    newObservation = 'Consolidar Paquetes Guías: ' + this.filterGuides.join(', ');
    const filterGuides = this.filterGuides || null;
    const newTracking = filterGuides ? filterGuides.join('-') : Date.now().toString();
    const todayte = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const user = this.authService.getUser();
    const newPackage = {
      orden: true,
      codigo_promocional: null,
      descripcion: 'Paquete Consolidado de Encomienda ',
      tipo_de_paquete: null,
      clase: 'C',
      tracking: 'O-' + user.cuenta + '_' + todayte + '_' + newTracking,
      exonerado: false,
      estado_actual: 12,
      factura_1: null,
      factura_2: null,
      factura_3: null,
      plan: 'A',
      fecha_factura: new Date().toISOString().split('T')[0],
      observacion: new Date().toLocaleDateString('es-CR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', }) + ': ' + newObservation,
      usuario: user.id,
      logged_user: user.id,
      cargo_encomienda: this.data.cargo_encomienda,
      peso_total: this.data.peso,
      peso: this.data.peso,
      paquetes: this.filterGuides,
    };
    return newPackage;
  }

  b64toFile(dataURI, file): File {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { 'type': mimeString });
    blob['lastModifiedDate'] = (new Date()).toISOString();
    blob['name'] = file;

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob['name'] += '.jpg';
        break;
      case 'image/png':
        blob['name'] += '.png';
        break;
    }
    // cast to a File
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <File>blob;
  }

  crearFactura(paquete) {
    return new Promise((resolve, reject) => {
      const data = {
        completada: true,
        logged_user: this.authService.getUser().id,
      };
      this.facturasService.putFacturas(paquete.factura.id, data).subscribe((res: any) => {
        const dataFactura = {
          paquete: paquete.id,
          logged_user: this.authService.getUser().id,
          programar: true
        };
        this.facturasService.crearFactura(dataFactura).subscribe((data: any) => {
          this.facturasService.getFacturaByID(data.id).subscribe((f: any) => {
            resolve(f);
          });
        }, (error: any) => {
          console.log(error);
        });
      });

    });
  }

  attachPayment(element: any) {
    // this.spinnerService.show();
    // this.pagosService.attachPayment(element.codReferencia, this.dvResult.token).subscribe((data: any) => {
    //   this.spinnerService.hide();
    //   this.openDialog('Pago vinculado correctamente');
    // });
  }


  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

}

function getNextMonday(date = new Date()) {
  const dateCopy = new Date(date.getTime());
  const nextMonday = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
    ),
  );
  if ((dateCopy.getHours() >= 17) || (dateCopy.getDay() === 6) || (dateCopy.getDay() === 0)) {
    nextMonday.setDate(nextMonday.getDate() + 1);
  }
  return nextMonday;
}
