/* eslint-disable @typescript-eslint/naming-convention */
import { AuthService } from './../../../../services/auth.service';
/* eslint-disable max-len */
import { PagosService } from './../../../../services/pagos.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';
import { GeneralDialogComponent } from '../general-dialog/general-dialog.component';
import { Router } from '@angular/router';
import { dvPaymentResponse } from '../../../../../app/core/data/dvPaymentResponse';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatId } from 'src/app/core/utils/validate-id';
import { DataService} from 'src/app/services/data.service';

export interface DialogData {
  title: string;
  metodo_pago: number;
  referencia: string;
  origin: string;
  fileComprobante: any;
  paquete: any;
  compraCorporativa: boolean;
  monto_usd: number;
  monto_crc: number;
  tipo_cambio: number;
  detail: string;
  selectedPayments: any[];
}

@Component({
  selector: 'app-adjuntar-pago',
  templateUrl: './adjuntar-pago.component.html',
  styleUrls: ['./adjuntar-pago.component.scss']
})
export class AdjuntarPagoComponent implements OnInit {
  @ViewChild('imageF1') imageF1: ElementRef;

  idTypes = this.dataService.getIdTypes();
  selectedId: any;

  dvResult: any;
  selectedPackages: any[] = [];
  dvColumns: string[] = ['select', 'monto', 'descripcion', 'fechaRecibido', 'codReferencia'];
  total_crc = 0;
  dvPaymmentResponses = dvPaymentResponse;

  metodosPago;
  detail;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  metodo_pago: number;
  referencia: string;

  editF1 = true;
  viewFile1 = false;
  fileLoad1: any;
  mediaUrl = environment.mediaUrl;
  user: any;

  monto_usd;
  monto_crc;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private pagosService: PagosService,
    private spinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<AdjuntarPagoComponent>,
    private usuariosService: UsuariosService,
    private dataService: DataService) {

    if (this.data.origin === 'admin') {
      this.monto_usd = this.data.monto_usd.toFixed(2);
      this.monto_crc = this.data.monto_crc.toFixed(2);

      this.usuariosService.getMetodosPago().subscribe(res => {
        this.metodosPago = res;
        this.metodosPago = this.metodosPago.filter(item => item.id === 2 || item.id === 3 || item.id === 6 || item.id === 8 || item.special === true);
        if (!authService.validateRolePermission('PAYMENT_METHODS')) {
          this.metodosPago = this.metodosPago.filter(item => item.id !== 7);
        }
      });
    }

    if (this.data.origin === 'user') {
      this.usuariosService.getMetodosPagoPreviaEntrega().subscribe((res: any) => {
        this.metodosPago = res;
        this.metodosPago = this.metodosPago.filter(item => item.id === 1 || item.id === 2);
      });
    }
  }

  ngOnInit(): void {
    this.selectedId = {...this.idTypes[0], value: ''};
  }

  public onYesClick = (): void => {
    if (!this.metodo_pago) {
      this.openDialog('Seleccione Método de Pago');
      return;
    }

    if (this.data.origin === 'admin') {

      this.data.metodo_pago = this.metodo_pago;
      this.data.referencia = this.referencia;
      this.data.monto_usd = this.monto_usd;
      this.data.monto_crc = Number(this.monto_crc);
      this.data.detail = this.detail;
      this.data.selectedPayments = this.selectedPackages;
      const payments = this.data.selectedPayments || [];
      if (+this.metodo_pago === 2 && payments.length === 0) {
        this.openDialog('Debe seleccionar al menos un pago a vincular!');
        return;
      }
      this.dialogRef.close(this.data);
    }


    if (this.data.origin === 'user') {
      this.user = JSON.parse(localStorage.getItem('user'));

      if (this.data.compraCorporativa) {
        this.procesarCompraCorporativa().then((res) => {
          this.dialogRef.close(res);
        });
      } else {
        this.procesarPagoNota().then((res) => {
          this.dialogRef.close(res);
        });
      }
    }
  };

  public onNoClick = (): void => {
    this.dialogRef.close();
  };

  isChecked(row): boolean {
    return this.selectedPackages.some(pkg => pkg === row);
  }

  changeCheck(event, row) {
    if (event.checked) {
      this.selectedPackages.push(row);
      this.total_crc += row.monto;
    } else {
      this.selectedPackages = this.selectedPackages.filter((element: any) => element.codReferencia !== row.codReferencia);
      this.total_crc -= row.monto;
    }
  }

  onIdTypeChange(event) {
    this.selectedId = event;
  }

  verifyPayments(): void {
    const formattedResponse = formatId(this.selectedId);
    if (formattedResponse.error) {
      this.selectedId.value = '';
      this.openDialog(formattedResponse.data);
      return;
    }
    this.selectedId.value = formattedResponse.data;
    this.spinnerService.show();
    this.pagosService.getDVPayments(this.selectedId.value, this.data.monto_crc.toFixed(2)).subscribe((data: any) => {
      this.spinnerService.hide();
      this.dvResult = data;
      if (this.dvResult.pagos.length > 0) {
        this.selectedPackages = this.dvResult.pagos;
        this.total_crc = this.selectedPackages.reduce((total, pkg) => total + pkg.monto, 0);
      }
    });
  }

  processFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileLoad1 = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const size = file.size / 1024;
        if (size <= 20480) {
          if (file.name.includes('.docx') || file.name.includes('.pdf') || file.name.includes('.doc')) {
            this.viewFile1 = true;
          } else {
            if (file.name.includes('.jpg') || file.name.includes('.jpeg') || file.name.includes('.png')) {
              this.viewFile1 = false;
              setTimeout(() => {
                this.imageF1.nativeElement.src = reader.result;
              }, 500);
              this.editF1 = true;
              //console.log(reader.result);
            } else {
              this.openDialog('Formato de archivo invalido');
              this.fileLoad1 = null;
            }
          }

        } else {
          this.openDialog('Archivo supera los 20 megabytes');
          this.fileLoad1 = null;
        }
      };
    }
  }

  procesarPagoNota() {
    return new Promise<void>((resolve, reject) => {
      //console.log(this.metodo_pago);
      const orderNumber = this.user.cuenta + '-' + Date.now().toString();

      if (this.metodo_pago === 1) {
        const data = {
          paquetes: [this.data.paquete],
          monto: this.data.paquete.factura.nota_debito.monto + this.data.paquete.factura.nota_debito.iva_usd,
          monto_crc: this.data.paquete.factura.nota_debito.monto_crc + this.data.paquete.factura.nota_debito.iva_crc,
          nota_debito: true,
          order_number: orderNumber
        };

        const dataTarjeta = {
          paquete: this.data.paquete.id,
          factura: this.data.paquete.factura.id,
          order_number: orderNumber,
          monto: data.monto,
          monto_crc: data.monto_crc,
          nota_debito: true,
          nota: this.data.paquete.factura.nota_debito.id,
          created_by: this.authService.getUser().id
        };

        this.pagosService.postTarjeta(dataTarjeta).subscribe(() => {

          this.pagosService.datosFacturas = data;
          this.router.navigate(['dashboard/pagar'], { queryParams: { pagar: true } });
          resolve();
        });
      }

      if (this.metodo_pago === 2) {
        const comprobanteForm = this.fb.group({
          paquete: [this.data.paquete.id],
          factura: [this.data.paquete.factura.id],
          referencia: [this.referencia],
          monto: [this.data.paquete.factura.nota_debito.monto + this.data.paquete.factura.nota_debito.iva_usd],
          monto_crc: [this.data.paquete.factura.nota_debito.monto_crc + this.data.paquete.factura.nota_debito.iva_crc],
          comprobante: [this.fileLoad1],
          aprobado: [false],
          nota_debito: [true],
          compra_corporativa: [false],
          created_by: [this.authService.getUser().id]
        });

        this.pagosService.postComprobante(comprobanteForm.value).subscribe((res: any) => {
          //console.log(res);
          this.openDialog('Su Pago se vera reflejado luego de validar su pago.');
          resolve(res);
        });
      }
    });

  }

  procesarCompraCorporativa() {
    return new Promise<void>((resolve, reject) => {
      //console.log(this.metodo_pago);
      const orderNumber = this.user.cuenta + '-' + Date.now().toString();

      if (this.metodo_pago === 1) {
        const data = {
          paquetes: [this.data.paquete],
          monto: this.data.paquete.factura.cargo_compra_corporativa + this.data.paquete.factura.cargo_gestion_compra_corporativa,
          monto_crc: (this.data.paquete.factura.cargo_compra_corporativa + this.data.paquete.factura.cargo_gestion_compra_corporativa) * this.data.paquete.factura.tipo_de_cambio.value,
          nota_debito: false,
          compra_corporativa: true,
          order_number: orderNumber
        };

        const dataTarjeta = {
          paquete: this.data.paquete.id,
          factura: this.data.paquete.factura.id,
          order_number: orderNumber,
          monto: data.monto,
          monto_crc: data.monto_crc,
          compra_corporativa: true,
          created_by: this.authService.getUser().id
        };

        this.pagosService.postTarjeta(dataTarjeta).subscribe(() => {

          this.pagosService.datosFacturas = data;
          this.router.navigate(['dashboard/pagar'], { queryParams: { pagar: true } });
          resolve();

        });
      }

      if (this.metodo_pago === 2) {
        const comprobanteForm = this.fb.group({
          paquete: [this.data.paquete.id],
          factura: [this.data.paquete.factura.id],
          referencia: [this.referencia],
          monto: [this.data.paquete.factura.cargo_compra_corporativa + this.data.paquete.factura.cargo_gestion_compra_corporativa,],
          monto_crc: [(this.data.paquete.factura.cargo_compra_corporativa + this.data.paquete.factura.cargo_gestion_compra_corporativa) * this.data.paquete.factura.tipo_de_cambio.value],
          comprobante: [this.fileLoad1],
          aprobado: [false],
          nota_debito: [false],
          compra_corporativa: [true],
          created_by: [this.authService.getUser().id]
        });

        this.pagosService.postComprobante(comprobanteForm.value).subscribe((res: any) => {
          //console.log(res);
          this.openDialog('Su Pago se vera reflejado luego de validar su pago.');
          resolve(res);
        });
      }
    });

  }

  convertCurrency(from) {
    //console.log(this.form.value.tipo_descuento, this.form.controls.descuento.value);

    if (from === 'USD') {
      this.monto_crc = (this.monto_usd * this.data.tipo_cambio).toFixed(2);
    }

    if (from === 'CRC') {
      this.monto_usd = (this.monto_crc / this.data.tipo_cambio).toFixed(2);
    }

  }

  openDialog(message) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: { name: message },
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    });
  }
}
